<template>
    <div>
        <div v-if="stepData == 0">
            <!-- <div class="unitBox">{{unitTitleName}}</div> -->
            <div class="unitNameBox">
                <div class="unitBoxSty"><span style="color: #F56C6C;margin-right: 3px;">*</span>单位名称：</div>
                <div>
                    <!-- <el-autocomplete
                        class="inline-input"
                        v-model="searchUnitName"
                        onkeyup="value=value.replace(/[\d]/g,'')"
                        :fetch-suggestions="querySearch"
                        :trigger-on-focus="false"
                        :debounce="2000"
                        placeholder="请输入单位名称"
                        @select="handleSelect"
                    >
                    </el-autocomplete> -->
                    <el-input v-model="searchUnitName" @input="inputChange" @blur="outInputBlur" @focus="inInputBlur" placeholder="请输入单位名称"></el-input>
                    <div v-if="isShowSelect" style="width: 100%;margin: 10px 0;border:1px solid #DCDFE6">
                        <el-form :inline='true' style="text-align: center;margin: 10px 0;">
                            <el-form-item label="市:">
                                <el-select 
                                    @change="selectRowChange2" 
                                    v-model="filters.selecShi" 
                                    placeholder="请选择" 
                                    ref ='cityRef'
                                    v-loading='cityLoading'
                                    clearable
                                    @focus="shiFocus"
                                    style="width: 110px"
                                >
                                    <template v-for="item in shiArr">
                                        <el-option                            
                                            :key="item.ID"
                                            :label="item.Name"
                                            :value="item.ID"
                                        >
                                        </el-option>
                                    </template>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="区:">
                                <el-select 
                                    v-model="filters.selecXian" 
                                    placeholder="请选择" 
                                    ref ='regionRef'
                                    v-loading='areaLoading'
                                    clearable
                                    @focus="shiFocus"
                                    style="width: 110px"
                                >
                                    <template v-for="item in xianArr">
                                        <el-option                            
                                            :key="item.ID"
                                            :label="item.Name"
                                            :value="item.ID"
                                        >
                                        </el-option>
                                    </template>
                                </el-select>
                            </el-form-item>
                        </el-form>
                        <div>
                            <el-cascader-panel @change="handleSelect" :options="serchUnitArr" :props="defaultProps" style="margin-top: -22px;"></el-cascader-panel>
                        </div>
                    </div>
                </div>
                <div class="unitBoxSty"><span style="color: #F56C6C;margin-right: 3px;">*</span>社会信用代码/组织机构代码：</div>
                <div>
                    <el-input v-model="socialCreditCode" onkeyup="value=value.replace(/[^\x00-\xff]/g,'')" maxlength="18" placeholder="请输入社会信用代码/组织机构代码"></el-input>
                </div>
                <div class="unitBoxSty">单位类型：</div>
                <div>
                    <template>
                        <el-radio v-model="unitType" @input="radioChange" label="1">社会单位</el-radio>
                        <el-radio v-model="unitType" @input="radioChange" label="2">政府及职能部门</el-radio>
                    </template>
                </div>
                <!-- <div class="unitBoxSty">是否属于大型商业综合体：</div>
                <div>
                    <template>
                        <el-radio v-model="isLargeBusiness" label="0">否</el-radio>
                        <el-radio v-model="isLargeBusiness" label="1">是</el-radio>
                    </template>
                </div> -->
                <div class="unitBoxSty">单位登记地址：</div>
                <div>
                    <el-form label-width="100px">
                        <el-form-item label='省：'>
                            <el-input value="广东省" disabled></el-input>
                        </el-form-item>
                        <el-form-item label='市：'>
                            <el-select v-model="filters.cityID" clearable  @change='selCity' ref='cityRef' placeholder="请选择" style="width: 100%;">
                                <el-option
                                    v-for="item in cityIDArr"
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label='区/县：'>
                            <el-select v-model="filters.regionID" @change='selRegion' ref='regionRef' clearable placeholder="请选择" style="width: 100%;">
                                <el-option
                                    v-for="item in regionIDArr"
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label='乡镇/街道：'>
                            <el-select v-model="filters.townID" ref ='townRef' clearable placeholder="请选择" style="width: 100%;">
                                <el-option
                                    v-for="item in townIDArr"
                                    :key="item.ID"
                                    :label="item.Name"
                                    :value="item.ID"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label='详细地址：'>
                            <el-input v-model="filters.address" clearable placeholder="请输入详细地址"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="unitNameBox" v-if="completedData.LiablePerson || completedData.Administrator || completedData.Data.length > 0">
                <div class="completedTitle">
                    <div>本单位已填报人员名单：</div>
                </div>
                <div class="completed" id="completed">
                    <div v-for="(item,index) in completedData.Data" :key="index">
                        <div v-if="item.StationName == '其他'">
                            <div class="completedTitle">
                                待分配岗位：
                            </div>
                            <div class="wrapBox">
                                <div class="viewPerDetaislBox" v-for="(i,n) in item.StaffList" :key="n">
                                    <div>
                                        {{i.Name}} {{i.PhoneNumber}}
                                    </div>
                                    <div class="deleBtnSty" v-if="isAuthority" @click="deletdPerFun(i)">-</div>
                                </div>
                            </div>
                            <div class="boxTipsc" v-if="item.StaffList.length > 0">注：请将以上待分配岗位人员填入下方对应岗位中</div>
                        </div>
                    </div>
                    <div class="noWrapBox" v-if="completedData.LiablePerson">
                        <div class="completedTitle">
                            消防安全责任人：
                        </div>
                        <div>
                            {{completedData.LiablePerson}} {{completedData.LiablePersonPhoneNumber}}
                        </div>
                    </div>
                    <div class="noWrapBox" v-if="completedData.Administrator">
                        <div class="completedTitle">
                            消防安全管理人：
                        </div>
                        <div>
                            {{completedData.Administrator}} {{completedData.AdministratorPhoneNumber}}
                        </div>
                    </div>
                    <div v-for="(item,index) in completedData.Data" :key="index">
                        <div v-if="item.StationName != '其他'">
                            <div class="completedTitle">
                                {{item.StationName}}：
                            </div>
                            <div class="wrapBox">
                                <div v-for="(i,n) in item.StaffList" :key="n">
                                    {{i.Name}} {{i.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="viewMorePer" v-if="isShowViewMore">
                        <el-button type="text" @click="viewMoreReport" class="viewBtn">查看更多</el-button>
                    </div>
                </div>
            </div>
            <div class="unitNameBox" v-if="unitType == 1">
                <el-form :model="personMessageForm" :rules="personMessageFormRules" ref="personMessageFormRef" label-width="100px">
                    <div class="pageTitle">
                        <div>消防安全责任人</div>
                    </div>
                    <el-form-item label='姓名:' prop='name1'>
                        <el-input v-model='personMessageForm.name1' @focus="judgeInput()" onkeyup="value=value.replace(/[\d]/g,'')" placeholder="请输入姓名" clearable style="margin-bottom: 10px;"></el-input>
                    </el-form-item>
                    <el-form-item label='手机号:'>
                        <el-input v-model='personMessageForm.phone1' @focus="judgeInput()" onkeyup="value=value.replace(/\D/g,'')" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                    <div class="pageTitle">
                        <div>消防安全管理人</div>
                    </div>
                    <el-form-item label='姓名:' prop='name2'>
                        <el-input v-model='personMessageForm.name2' @focus="judgeInput()" onkeyup="value=value.replace(/[\d]/g,'')" placeholder="请输入姓名" clearable style="margin-bottom: 10px;"></el-input>
                    </el-form-item>
                    <el-form-item label='手机号:'>
                        <el-input v-model='personMessageForm.phone2' @focus="judgeInput()" onkeyup="value=value.replace(/\D/g,'')" placeholder="请输入手机号" clearable></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="exportBox" v-if="unitType == 1">
                <el-button @click="exportExces" type="text" style="margin-right: 10px;">下载模版</el-button>
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="action"
                    :headers='headers'
                    :on-error="submitFileError"
                    :on-success="submitFileSuccess"
                    :before-upload="beforeUploadFile"
                    :on-exceed="submitFileMore"
                    :show-file-list="false"
                    :multiple='false'
                    :limit="1"
                >
                    <el-button type="text">批量导入</el-button>
                </el-upload>
            </div>
            <div class="inputMessagePage" v-for="(n,i) in stationList" :key="i">
                <div class="pageTitle">
                    <div>{{n.Name}}</div>
                    <div><el-button type="text" @click="addFormData(i)" v-if="!n.HiddenBtn">添加</el-button></div>
                </div>
                <div v-for="(item,index) in n.arr" :key="index">
                    <el-form :model="item.dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="100px" enctype="multipart/form-data">
                        <el-form-item label='姓名:' style="margin-bottom: 10px;">
                            <el-input v-model='item.dialogForm.name' @focus="judgeInput()" onkeyup="value=value.replace(/[\d]/g,'')" placeholder="请输入姓名" clearable></el-input>
                        </el-form-item>
                        <el-form-item label='手机号:'>
                            <el-input v-model='item.dialogForm.phone' @focus="judgeInput()" onkeyup="value=value.replace(/\D/g,'')" placeholder="请输入手机号" clearable></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="btnBox">
                <el-button class="reportBtn" type="danger" :loading="nextStepLoding" @click.native.prevent="extStepFun">保  存</el-button>
            </div>
        </div>
        <div v-if="stepData == 1" class="commerBox">
            <div class="boxTips">
                是否大型商业综合体：
                <template>
                    <el-radio v-model="isCommercial" label="1">是</el-radio>
                    <el-radio v-model="isCommercial" label="0">否</el-radio>
                </template>
            </div>
            <div v-if="isCommercial == '1'">
                <div class="commercialPage" v-for="(item,index) in unitArr" :key="index">
                    <div class="pageTitle">
                        <div>单位{{index + 1}}</div>
                        <div><el-button type="text" v-if="index == 0" @click="addUnitData()">添加</el-button></div>
                    </div>
                    <el-form :model="item" ref="dialogFormRef" label-width="130px" enctype="multipart/form-data">
                        <el-form-item label='统一社会信用代码:'>
                            <el-input @focus="judgeInput()" v-model='item.creditCode' clearable></el-input>
                        </el-form-item>
                        <el-form-item label='单位名称:'>
                            <el-input @focus="judgeInput()" v-model='item.unitName' clearable></el-input>
                        </el-form-item>
                        <el-form-item label='行业（场所）:'>
                            <span style="color: #888;">大型商业综合体</span>
                        </el-form-item>
                        <el-form-item label='联系人:'>
                            <el-input @focus="judgeInput()" v-model='item.name' clearable></el-input>
                        </el-form-item>
                        <el-form-item label='手机号:'>
                            <el-input @focus="judgeInput()" v-model='item.phone' clearable></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="btnBox">
                <el-button class="reportBtn" type="danger" :loading="unitLoding" @click.native.prevent="unitSubmit">保存</el-button>
            </div>
        </div>
        <!-- 查看更多 -->
        <el-dialog
            title=""
            :visible.sync="showMoreReportDialog"
            v-model="showMoreReportDialog"
            :close-on-click-modal='true'
            width="100%"
        >
            <div class="unitNameBox1" v-if="completedData.LiablePerson || completedData.Administrator || completedData.Data.length > 0">
                <div class="completedTitle">
                    <div>本单位已填报人员名单：</div>
                </div>
                <div class="completed1" id="completed1">
                    <div v-for="(item,index) in completedData.Data" :key="index">
                        <div v-if="item.StationName == '其他'">
                            <div class="completedTitle">
                                待分配岗位：
                            </div>
                            <div class="wrapBox">
                                <div class="viewPerDetaislBox" v-for="(i,n) in item.StaffList" :key="n">
                                    <div>
                                        {{i.Name}} {{i.PhoneNumber}}
                                    </div>
                                    <div class="deleBtnSty" v-if="isAuthority" @click="deletdPerFun(i)">-</div>
                                </div>
                            </div>
                            <div class="boxTipsc" v-if="item.StaffList.length > 0">注：请将以上待分配岗位人员填入下方对应岗位中</div>
                        </div>
                    </div>
                    <div class="noWrapBox" v-if="completedData.LiablePerson">
                        <div class="completedTitle">
                            消防安全责任人：
                        </div>
                        <div>
                            {{completedData.LiablePerson}} {{completedData.LiablePersonPhoneNumber}}
                        </div>
                    </div>
                    <div class="noWrapBox" v-if="completedData.Administrator">
                        <div class="completedTitle">
                            消防安全管理人：
                        </div>
                        <div>
                            {{completedData.Administrator}} {{completedData.AdministratorPhoneNumber}}
                        </div>
                    </div>
                    <div v-for="(item,index) in completedData.Data" :key="index">
                        <div v-if="item.StationName != '其他'">
                            <div class="completedTitle">
                                {{item.StationName}}：
                            </div>
                            <div class="wrapBox">
                                <div v-for="(i,n) in item.StaffList" :key="n">
                                    {{i.Name}} {{i.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="noMoreReport" v-else>暂无数据</div>
            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click.native="showMoreReportDialog = false">取消</el-button>
            </div> -->
        </el-dialog>
    </div>
</template>
<script>
import util from '../../util/date'
import $ from 'jquery'
import { validPhone } from "../../util/validate";
import { getStationTradeList,addSocialUnitStaff,addChildSocialUnit,getStudentSocialUnitStaffList,getStudentChildSocialUnitList,getAreaDictionaryListNToken,getSocialUnitListByKeyWord,getSocialUnitChaosListByKeyWord,addSocialUnitAndStaff,getStudentSocialUnitById,getStudentSocialUnitChaosById,analyseSocialUnitStaffChaos,getAreaDictionaryList,deleteSocialUnitStaff } from '../api/api';
export default {
    data() {
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
            utils: '',
            stepData: 0,
            unitTitleName: "",
            codeLogining: false,
            reportLoding: false,
            // 消防安全责任人/管理人
            personMessageForm: {
                LiablePersonId: 0,
                name1: "",
                nameCopy1: "",
                phone1: "",
                phoneCopy1: "",
                AdministratorId: 0,
                name2: "",
                nameCopy2: "",
                phone2: "",
                phoneCopy2: "",
            },
            personMessageFormRules: {
                name1: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                phone1: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    { validator: isPhoneNumberNum },
                ],
                name2: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                phone2: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    { validator: isPhoneNumberNum },
                ],
            },
            stationList: [{
                Name: "企业消防安全责任人、管理人",
                arr: [{
                    dialogForm: {
                        name: "",
                        phone: "",
                    }
                }]
            }],
            dialogFormRules: {
                name: [
                    {required: true, message: '请输入姓名', trigger: 'blur'},
                ],
                phone: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    { validator: isPhoneNumberNum },
                ],
            },
            // 下一步
            nextStepLoding: false,
            unitLoding: false,
            // 包含单位信息
            isCommercial: "1",
            unitArr: [{
                creditCode: "",
                unitName: "",
                name: "",
                phone: "",
            }],
            // 个人，单位 信息
            perData: "",
            unitData: "",
            // 23-06-25改版
            radio: "",
            unitType: "1",
            // isLargeBusiness: "0",
            searchUnitName: "",
            serchUnitArr: [],
            cityLoading:false,
            areaLoading:false,
            shengArr: [],
            shiArr: [],
            xianArr: [],
            socialCreditCode: "",
            restaurants: [],
            // 省市区镇
            filters: {
                cityID: '',
                regionID: '',
                townID: '',
                address: '',
                selecSheng: 1919,
                selecShi: '',
                selecXian: '',
            },
            cityIDArr: [],
            regionIDArr: [],
            townIDArr: [],
            unitID: 0,
            action: '',
            completedData: {
                Data: [],
                LiablePerson: "",
                LiablePersonPhoneNumber: "",
                Administrator: "",
                AdministratorPhoneNumber: "",
            },
            // 查看更多
            isShowViewMore: false,
            showMoreReportDialog: false,
            defaultProps: { 
                children: "Children",
                label: "UnitName",
                value:'ID'
            },
            timeout: "",
            timeout1: "",
            isShowSelect: false,
            isAuthority: false,
        }
    },
    methods: {
        // 获取岗位行业信息列表
        getStationList(val) {
            var params = {
                dataType: 1,
                sort: 1,
                unitType: this.unitType
            }
            getStationTradeList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.ID == 18) {
                            item.Name = "消防安全管理人员"
                        }
                        if(item.ID == 15) {
                            item.Name = "单位员工"
                        }
                        var newArr = [{
                            dialogForm: {
                                name: "",
                                phone: "",
                            }
                        }]
                        item.arr = newArr
                    })
                    var newObj1 = {
                        Name: "消防安全责任人",
                        PersonType: 1,
                        HiddenBtn: true,
                        arr: [{
                            dialogForm: {
                                name: "",
                                phone: "",
                            }
                        }],
                    }
                    var newObj2 = {
                        Name: "消防安全管理人",
                        PersonType: 2,
                        HiddenBtn: true,
                        arr: [{
                            dialogForm: {
                                name: "",
                                phone: "",
                            }
                        }],
                    }
                    // result.Response.unshift(newObj2)
                    // result.Response.unshift(newObj1)
                    this.stationList = result.Response
                    // this.getPerData()
                    if(val == 2) {
                        this.stationList.forEach(o => {
                            this.perData.forEach(item => {
                                var newArr = []
                                item.StaffList.forEach(n => {
                                    if(item.PersonType == 1 || item.PersonType == 2) {
                                        if(item.PersonType == o.PersonType){
                                            var newObj = {
                                                dialogForm: {
                                                    name: this.processName(n.Name),
                                                    nameCopy: n.Name,
                                                    phone: n.PhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2"),
                                                    phoneCopy: n.PhoneNumber,
                                                    isOlder: true,
                                                    ID: n.ID,
                                                }
                                            }
                                            newArr.push(newObj)
                                            o.arr = newArr
                                        }
                                    } else {
                                        if(n.StationID == o.ID) {
                                            var newObj = {
                                                dialogForm: {
                                                    name: this.processName(n.Name),
                                                    nameCopy: n.Name,
                                                    phone: n.PhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2"),
                                                    phoneCopy: n.PhoneNumber,
                                                    isOlder: true,
                                                    ID: n.ID,
                                                }
                                            }
                                            newArr.push(newObj)
                                            o.arr = newArr
                                        }
                                    }
                                })
                            })
                        })
                    }
                }
            })
        },
        // 添加
        addFormData(val) {
            var newObj = {
                dialogForm: {
                    name: "",
                    phone: "",
                }
            }
            if(this.stationList[val].arr[this.stationList[val].arr.length -1].dialogForm.name == "" || this.stationList[val].arr[this.stationList[val].arr.length -1].dialogForm.phone == "") {
                this.$message.warning("请先输入姓名或手机号再添加！")
                return
            // } else if(!validPhone(this.stationList[val].arr[this.stationList[val].arr.length -1].dialogForm.phone)) {
            } else if(this.stationList[val].arr[this.stationList[val].arr.length -1].dialogForm.phone.length != 11) {
                this.$message.warning("请先输入正确的手机号再添加！")
                return
            } else {
                this.stationList[val].arr.push(newObj)
            }
        },
        // 下一步
        extStepFun() {
            var isNestStep = 0
            var newArr = []
            this.stationList.forEach(n => {
                n.arr.forEach(item => {
                    // if(item.dialogForm.name == "") {
                    //     isNestStep += 1
                    // }
                    // if(item.dialogForm.phone && !validPhone(item.dialogForm.phone)) {
                    if(item.dialogForm.phone && item.dialogForm.phone.length != 11) {
                        isNestStep += 1
                    }
                    if(item.dialogForm.name && item.dialogForm.phone) {
                        if(n.HiddenBtn) {
                            var newObj = {
                                PersonType: n.PersonType,
                                ID: item.dialogForm.ID
                            }
                            if(item.dialogForm.name.indexOf("*") == 1) {
                                if(item.dialogForm.nameCopy.indexOf("*") == 1) {
                                    newObj.Name = ""
                                } else {
                                    newObj.Name = item.dialogForm.nameCopy
                                }
                            } else {
                                if(item.dialogForm.name.indexOf("*") == 1) {
                                    newObj.Name = ""
                                } else {
                                    newObj.Name = item.dialogForm.name
                                }
                            }
                            if(item.dialogForm.phone != parseInt(item.dialogForm.phone)) {
                                if(item.dialogForm.phoneCopy.indexOf("*") == 3) {
                                    newObj.PhoneNumber = ""
                                } else {
                                    newObj.PhoneNumber = item.dialogForm.phoneCopy
                                }
                            } else {
                                if(item.dialogForm.phone.indexOf("*") == 3) {
                                    newObj.PhoneNumber = ""
                                } else {
                                    newObj.PhoneNumber = item.dialogForm.phone
                                }
                            }
                        } else {
                            var newObj = {
                                StationID: n.ID,
                                ID: item.dialogForm.ID
                            }
                            if(item.dialogForm.name.indexOf("*") == 1) {
                                if(item.dialogForm.nameCopy.indexOf("*") == 1) {
                                    newObj.Name = ""
                                } else {
                                    newObj.Name = item.dialogForm.nameCopy
                                }
                            } else {
                                if(item.dialogForm.name.indexOf("*") == 1) {
                                    newObj.Name = ""
                                } else {
                                    newObj.Name = item.dialogForm.name
                                }
                            }
                            if(item.dialogForm.phone != parseInt(item.dialogForm.phone)) {
                                if(item.dialogForm.phoneCopy.indexOf("*") == 3) {
                                    newObj.PhoneNumber = ""
                                } else {
                                    newObj.PhoneNumber = item.dialogForm.phoneCopy
                                }
                            } else {
                                if(item.dialogForm.phone.indexOf("*") == 3) {
                                    newObj.PhoneNumber = ""
                                } else {
                                    newObj.PhoneNumber = item.dialogForm.phone
                                }
                            }
                        }
                        if(newObj.Name && newObj.PhoneNumber) {
                            newArr.push(newObj)
                        }
                    }
                })
            })
            if(isNestStep > 0) {
                this.$message.warning("请检查输入正确的手机号！")
            } else {
                var token = localStorage.getItem("Token")
                if(!token) {
                    this.$message.warning("暂未登录，请先登录！")
                    setTimeout(() => {
                        var url = "/l"
                        this.$router.push(url);
                    },1000)
                    return
                }
                if(!this.searchUnitName) {
                    this.$message.warning("请输入单位名称！")
                    return
                }
                if(!this.socialCreditCode) {
                    this.$message.warning("请输入社会信用代码/组织机构代码！")
                    return
                }
                var params = {
                    ID: this.unitID ? this.unitID : null,
                    UnitName: this.searchUnitName,
                    SocialCreditNumber: this.socialCreditCode,
                    ProvinceID: 1919,
                    CityID: this.filters.cityID,
                    RegionID: this.filters.regionID,
                    TownID: this.filters.townID,
                    Address: this.filters.address,
                    UnitType: this.unitType,
                }
                if(this.unitType == "1") {
                    if(!this.personMessageForm.name1 || !this.personMessageForm.name2) {
                        this.$message.warning("请检查输入正确的姓名！")
                        return
                    }
                    if(this.personMessageForm.phone1.length != 11 || this.personMessageForm.phone2.length != 11) {
                        this.$message.warning("请检查输入正确的手机号！")
                        return
                    }
                    if(this.personMessageForm.name1.indexOf("*") == 1) {
                        if(this.personMessageForm.nameCopy1.indexOf("*") == 1) {
                            params.LiablePerson = ""
                        } else {
                            params.LiablePerson = this.personMessageForm.nameCopy1
                        }
                    } else {
                        if(this.personMessageForm.name1.indexOf("*") == 1) {
                            params.LiablePerson = ""
                        } else {
                            params.LiablePerson = this.personMessageForm.name1
                        }
                    }
                    if(this.personMessageForm.phone1 != parseInt(this.personMessageForm.phone1)) {
                        if(this.personMessageForm.phoneCopy1.indexOf("*") == 3) {
                            params.LiablePersonPhoneNumber = ""
                        } else {
                            params.LiablePersonPhoneNumber = this.personMessageForm.phoneCopy1
                        }
                    } else {
                        if(this.personMessageForm.phone1.indexOf("*") == 3) {
                            params.LiablePersonPhoneNumber = ""
                        } else {
                            params.LiablePersonPhoneNumber = this.personMessageForm.phone1
                        }
                    }
                    if(this.personMessageForm.name2.indexOf("*") == 1) {
                        if(this.personMessageForm.nameCopy2.indexOf("*") == 1) {
                            params.Administrator = ""
                        } else {
                            params.Administrator = this.personMessageForm.nameCopy2
                        }
                    } else {
                        if(this.personMessageForm.name2.indexOf("*") == 1) {
                            params.Administrator = ""
                        } else {
                            params.Administrator = this.personMessageForm.name2
                        }
                    }
                    if(this.personMessageForm.phone2 != parseInt(this.personMessageForm.phone2)) {
                        if(this.personMessageForm.phoneCopy2.indexOf("*") == 3) {
                            params.AdministratorPhoneNumber = ""
                        } else {
                            params.AdministratorPhoneNumber = this.personMessageForm.phoneCopy2
                        }
                    } else {
                        if(this.personMessageForm.phone2.indexOf("*") == 3) {
                            params.AdministratorPhoneNumber = ""
                        } else {
                            params.AdministratorPhoneNumber = this.personMessageForm.phone2
                        }
                    }
                    params.LiablePersonId = this.personMessageForm.LiablePersonId ? this.personMessageForm.LiablePersonId : 0
                    params.AdministratorId = this.personMessageForm.AdministratorId ? this.personMessageForm.AdministratorId : 0
                }
                params.Data = newArr
                this.nextStepLoding = true
                addSocialUnitAndStaff(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        this.$message.success(result.Message)
                        this.$alert('保存成功，确定回到系统介绍', '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                var url = "/c"
                                this.$router.push(url);
                                localStorage.removeItem("Token")
                            }
                        });
                    } else {
                        this.$message.error(result.Message)
                    }
                    this.nextStepLoding = false
                })
            }
        },
        // 单位添加
        addUnitData() {
            if(this.unitArr[this.unitArr.length - 1].creditCode && this.unitArr[this.unitArr.length - 1].unitName) {
                var newObj = {
                    creditCode: "",
                    unitName: "",
                    name: "",
                    phone: "",
                }
                this.unitArr.push(newObj)
            } else {
                this.$message.warning("请输入统一社会信用代码与单位名称")
            }
        },
        // 单位保存
        unitSubmit() {
            var params = {}
            var isSubmit = 0
            if(this.isCommercial == "0") {
                params.SocialUnitType = 0
            } else {
                params.SocialUnitType = 1
                var newArr = []
                this.unitArr.forEach(item => {
                    if(!item.isOlder) {
                        var newObj = {}
                        newObj.SocialCreditNumber = item.creditCode
                        newObj.UnitName = item.unitName
                        newObj.ContactPerson = item.name
                        newObj.ContactPersonPhoneNumber = item.phone
                        if(item.creditCode && item.unitName) {
                            newArr.push(newObj)
                        }
                    }
                    // if(item.phone && !validPhone(item.phone)) {
                    if(item.phone && item.phone.length != 11) {
                        isSubmit += 1
                    }
                })
                params.ChildSocialUnits = newArr
            }
            if(isSubmit > 0) {
                this.$message.warning("请输入正确的手机号！")
            } else {
                var token = localStorage.getItem("Token")
                if(!token) {
                    this.$message.warning("暂未登录，请先登录！")
                    setTimeout(() => {
                        var url = "/l"
                        this.$router.push(url);
                    },1000)
                    return
                }
                this.unitLoding = true
                addChildSocialUnit(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        // this.$message.success(result.Message)
                        this.$alert('保存成功，确定回到系统介绍', '提示', {
                            confirmButtonText: '确定',
                            callback: () => {
                                var url = "/c"
                                this.$router.push(url);
                                localStorage.removeItem("Token")
                            }
                        });
                    } else {
                        this.$message.error(result.Message)
                    }
                    this.unitLoding = false
                })
            }
        },
        // 获取个人数据
        getPerData(val) {
            var that = this
            var params = {
                id: val
            }
            // getStudentSocialUnitById(params).then(res => {
            getStudentSocialUnitChaosById(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.Data.reverse()
                    this.completedData = result.Response
                    this.isAuthority = result.Response.IsAuthority
                    var newArr = []
                    result.Response.Data.forEach(item => {
                        var newObj = {
                            StationName: item.StationName,
                            StaffList: [],
                        }
                        item.StaffList.forEach(n => {
                            var newObj1 = {}
                            newObj1.ID = n.ID
                            newObj1.Name = this.processName(n.Name)
                            newObj1.PhoneNumber = n.PhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2")
                            newObj.StaffList.push(newObj1)
                        })
                        newArr.push(newObj)
                    })
                    this.completedData = {
                        LiablePerson: this.processName(result.Response.LiablePerson),
                        LiablePersonPhoneNumber: result.Response.LiablePersonPhoneNumber ? result.Response.LiablePersonPhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2") : "",
                        Administrator: this.processName(result.Response.Administrator),
                        AdministratorPhoneNumber: result.Response.AdministratorPhoneNumber ? result.Response.AdministratorPhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2") : "",
                        Data: newArr
                    }
                    this.personMessageForm = {
                        LiablePersonId: result.Response.LiablePersonId,
                        name1: this.processName(result.Response.LiablePerson),
                        nameCopy1: result.Response.LiablePerson,
                        phone1: result.Response.LiablePersonPhoneNumber ? result.Response.LiablePersonPhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2") : "",
                        phoneCopy1: result.Response.LiablePersonPhoneNumber,
                        AdministratorId: result.Response.AdministratorId,
                        name2: this.processName(result.Response.Administrator),
                        nameCopy2: result.Response.Administrator,
                        phone2: result.Response.AdministratorPhoneNumber ? result.Response.AdministratorPhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2") : "",
                        phoneCopy2: result.Response.AdministratorPhoneNumber,
                    }
                    this.unitType = result.Response.UnitType.toString()
                    this.perData = result.Response.Data
                    if(result.Response.UnitType == 1) {
                        this.stationList.forEach(o => {
                            if(result.Response.Data && result.Response.Data.length > 0) {
                                result.Response.Data.forEach(item => {
                                    var newArr = []
                                    item.StaffList.forEach(n => {
                                        if(item.PersonType == 1 || item.PersonType == 2) {
                                            if(item.PersonType == o.PersonType){
                                                var newObj = {
                                                    dialogForm: {
                                                        name: this.processName(n.Name),
                                                        nameCopy: n.Name,
                                                        phone: n.PhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2"),
                                                        phoneCopy: n.PhoneNumber,
                                                        isOlder: true,
                                                        ID: n.ID,
                                                    }
                                                }
                                                newArr.push(newObj)
                                                o.arr = newArr
                                            }
                                        } else {
                                            if(n.StationID == o.ID) {
                                                var newObj = {
                                                    dialogForm: {
                                                        name: this.processName(n.Name),
                                                        nameCopy: n.Name,
                                                        phone: n.PhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2"),
                                                        phoneCopy: n.PhoneNumber,
                                                        isOlder: true,
                                                        ID: n.ID,
                                                    }
                                                }
                                                newArr.push(newObj)
                                                o.arr = newArr
                                            }
                                        }
                                    })
                                })
                            } else {
                                var newArr = []
                                var newObj = {
                                    dialogForm: {
                                        name: '',
                                        nameCopy: '',
                                        phone: '',
                                        phoneCopy: '',
                                        isOlder: '',
                                    }
                                }
                                newArr.push(newObj)
                                o.arr = newArr
                            }
                        })
                    } else {
                        this.getStationList(result.Response.UnitType)
                    }
                    setTimeout(() => {
                        var comHeight = document.getElementById("completed").offsetHeight
                        if(comHeight > 300) {
                            $("#completed").css("height","300px")
                            $("#completed").css("overflow","hidden")
                            that.isShowViewMore = true
                        } else {
                            that.isShowViewMore = false
                        }
                    });
                }
            })
            return
            // 废弃
            var params = {}
            getStudentSocialUnitStaffList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.perData = result.Response
                    this.stationList.forEach(o => {
                        result.Response.forEach(item => {
                            var newArr = []
                            item.StaffList.forEach(n => {
                                if(item.PersonType == 1 || item.PersonType == 2) {
                                    if(item.PersonType == o.PersonType){
                                        var newObj = {
                                            dialogForm: {
                                                name: n.Name,
                                                phone: n.PhoneNumber,
                                                isOlder: true,
                                            }
                                        }
                                        newArr.push(newObj)
                                        o.arr = newArr
                                    }
                                } else {
                                    if(n.StationID == o.ID) {
                                        var newObj = {
                                            dialogForm: {
                                                name: n.Name,
                                                phone: n.PhoneNumber,
                                                isOlder: true,
                                            }
                                        }
                                        newArr.push(newObj)
                                        o.arr = newArr
                                    }
                                }
                            })
                        })
                    })
                }
            })
        },
        // 查看更多填报
        viewMoreReport() {
            this.showMoreReportDialog = true
        },
        // 删除待分配岗位人员
        deletdPerFun(row) {
            var params = {
                staffId: row.ID
            }
            this.$confirm('确定删除吗？',"提示",{
            }).then(() => {
                deleteSocialUnitStaff(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        this.$message.success(result.Message)
                        this.completedData.Data.forEach(item => {
                            if(item.StationName == '其他') {
                                item.StaffList.forEach((n,i) => {
                                    if(n.ID == row.ID) {
                                        item.StaffList.splice(i,1)
                                    }
                                })
                            }
                        })
                    } else {
                        this.$message.error(result.Message)
                    }
                })
            })
            .catch(() => {})
        },
        // 获取单位数据
        getUnitData() {
            var params = {}
            getStudentChildSocialUnitList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.unitData = result.Response
                    if(result.Response && result.Response.SocialUnitType == 1) {
                        this.isCommercial = "1"
                    } else if(result.Response && result.Response.SocialUnitType == 0) {
                        this.isCommercial = "0"
                    }
                    if(result.Response && result.Response.SocialUnitList && result.Response.SocialUnitList.length > 0) {
                        var newArr = []
                        result.Response.SocialUnitList.forEach(item => {
                            var newObj = {
                                creditCode: item.SocialCreditNumber,
                                unitName: item.UnitName,
                                name: item.ContactPerson,
                                phone: item.ContactPersonPhoneNumber,
                                isOlder: true,
                            }
                            newArr.push(newObj)
                        })
                        this.unitArr = newArr
                    }
                }
            })
        },
        // radio绑定值发生变化
        radioChange() {
            this.getStationList()
        },
        // 单位名称搜索建议
        querySearch(queryString, cb) {
            if(!this.searchUnitName) {
                return
            }
            this.unitID = 0
            var params = {
                intTop: 10,
                keyWord: this.searchUnitName,
                cityID: this.filters.selecShi,
                regionID: this.filters.selecXian,
            }
            // getSocialUnitListByKeyWord(params).then(res => {
            getSocialUnitChaosListByKeyWord(params).then(res => {
                var result = res.data
                if(result.Success) {
                    var results = []
                    if(result.Response && result.Response.length > 0) {
                        result.Response.forEach(item => {
                            item.value = item.UnitName
                        })
                        results = result.Response
                        this.isShowSelect = true
                    }
                    // cb(results);
                    this.serchUnitArr = results
                }
            })
        },
        // 输入建议选中
        handleSelect(e) {
            var val = e[0]
            this.serchUnitArr.forEach(item => {
                if(item.ID == val) {
                    this.socialCreditCode = item.SocialCreditNumber
                    this.filters.cityID = item.CityID
                    this.filters.regionID = item.RegionID
                    this.filters.townID = item.TownID
                    if(item.CityID) {
                        var params = {
                            parentId: item.CityID
                        }
                        this.getArea(params)
                    }
                    if(item.RegionID) {
                        var params = {
                            parentId: item.RegionID
                        }
                        this.getTown(params)
                    }
                    this.searchUnitName = item.UnitName
                    this.filters.address = item.Address
                    this.isShowSelect = false
                    this.unitID = item.ID
                    this.getPerData(item.ID)
                }
            })
        },
        // 输入框失去焦点
        outInputBlur() {
            var that = this
            this.timeout1 = setTimeout(() => {
                that.isShowSelect = false
            },200);
        },
        // 输入框焦点
        inInputBlur() {
            if(this.searchUnitName) {
                this.isShowSelect = true
            }
        },
        // 输入框值改变
        inputChange() {
            if(this.searchUnitName) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.querySearch();
                },2000);
            }
        },
        // 选择框焦点
        shiFocus() {
            clearTimeout(this.timeout1);
        },
        // 省市区镇选择事件
        selCity(value){
            this.parentCityID = value
            this.filters.regionID = ""
            this.filters.townID = ""
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        selRegion(value){
            this.parentCityID = value
            this.filters.townID = ""
            var params = {
                parentId:this.parentCityID
            }
            this.getTown(params)
        },
        getCity(params){
            getAreaDictionaryListNToken(params).then(res => {
                if(res.data.Success){
                    this.cityIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryListNToken(params).then(res => {
                if(res.data.Success){
                    this.regionIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getTown(params){
            getAreaDictionaryListNToken(params).then(res => {
                if(res.data.Success){
                    this.townIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        // 文件上传前限制
        beforeUploadFile(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            // if (type == 'jpg' || type == 'png') {
            //     this.$message.error('只能上传文件!');
            //     return false;
            // }
            if (!isLt2M === false) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return false;
            }
        },
        // 文件上传成功
        submitFileSuccess(res,file,fileList) {
            if(res.Success){
                this.$message({
                    message:'文件上传成功！',
                    type:'success'
                })
                this.$refs.upload.clearFiles()
                var result = res
                this.stationList.forEach(o => {
                    result.Response.forEach(item => {
                        var newArr = []
                        item.StaffList.forEach(n => {
                            if(n.StationID == o.ID) {
                                var newObj = {
                                    dialogForm: {
                                        name: this.processName(n.Name),
                                        nameCopy: n.Name,
                                        phone: n.PhoneNumber.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2"),
                                        phoneCopy: n.PhoneNumber,
                                        isOlder: true,
                                        ID: n.ID,
                                    }
                                }
                                if(o.arr && o.arr.length > 0 && o.arr[0].dialogForm.phone) {
                                    o.arr.push(newObj)
                                } else {
                                    newArr.push(newObj)
                                    o.arr = newArr
                                }
                            }
                        })
                    })
                })
            }else{
                this.$message({
                    message:res.Message,
                    type:'error'
                })
                this.$refs.upload.clearFiles()
            }
        },
        // 文件上传失败
        submitFileError(err,file,fileList){
            this.$message.error('文件上传失败!')
            this.$refs.upload.clearFiles() 
        },
        submitFileMore(files) {
            this.$message({
                message:'只能上传一个文件！',
                type:'warning'
            })
        },
        // 导出模版
        exportExces() {
            var url = "https://qdzn.obs.cn-east-3.myhuaweicloud.com/gdsc-train/ImportTemplate/人员信息导入excel模板.xlsx"
            window.open(url)
        },
        // 市选中
        selectRowChange2(row) {
            if(!row){
                this.xianArr = []
                this.filters.selecXian = ''
            }else{
                this.parentShi = row
                var params = {
                    parentId: this.parentShi,
                    // level:2
                }
                this.getPlace(params,2)
            }          
        },
        // 区/县选中
        selectRowChange3(row) {
            if(!row){
                this.jieArr = []
                this.addSocialFrom.selecJie = ''
            }else{
                this.jieArr = []
                this.addSocialFrom.selecJie = ''
                var params = {
                    parentId: row,
                    // level: 3
                }
                this.getPlace(params,3)
            }          
        },
        // 获取行政区域
        getPlace(params,level){
            switch(level){
                case 0:
                    this.provinceLoading = true
                    break;
                case 1:
                    // this.cityLoading = true
                    break;
                case 2:
                    this.areaLoading = true
                    break
            }
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    switch(level){
                        case 0:
                            this.provinceLoading = false
                            this.shengArr = res.data.Response;
                            break;
                        case 1:
                            this.cityLoading = false
                            this.shiArr = res.data.Response;
                            break;
                        case 2:
                            this.areaLoading = false
                            this.xianArr = res.data.Response;
                            this.filters.selecXian = ""
                            break
                    }
                }else{return}
            }).catch(() => {})
        },
        // 键盘挡住输入框问题
        isIos() {
            const u = navigator.userAgent;
            return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac os X/);
        },
        judgeInput() {
            if (this.isIos()) {
                window.addEventListener('focusin',function () {
                    if(document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
                        setTimeout(function () {
                            documentdocumentElement.scrollTop = documentbody.scrollHeight;
                        },200);
                    }
                })
            } else {
                window.addEventListener('resize',function () {
                    if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
                        setTimeout(function () {
                            document.activeElement.scrollIntoview()
                        },200);
                    }
                })
            }
        },
        // 姓名*替换
        processName(str){
            if(null != str && str != undefined && str != null && str != "null"){
                let star = ''
                if(str.length <= 2){
                    return str.substring(0,1)+"*"  ;
                } else {
                    for (var i = 0; i < str.length-2; i++) {
                        star= star + '*'
                    }
                    return str.substring(0,1)+ star + str.substring(str.length-1,str.length);
                }
            } else {
                return ""
            }
        },
        // 获取token
        getToken() {
            return localStorage.getItem("Token")
        },
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    watch: {
        'socialCreditCode': function(val) {
            this.personMessageForm = {
                LiablePersonId: 0,
                name1: "",
                nameCopy1: "",
                phone1: "",
                phoneCopy1: "",
                AdministratorId: 0,
                name2: "",
                nameCopy2: "",
                phone2: "",
                phoneCopy2: "",
            },
            this.stationList.forEach(item => {
                item.arr.forEach(o => {
                    o.dialogForm.ID = 0
                    o.dialogForm.isOlder = ""
                    o.dialogForm.name = ""
                    o.dialogForm.nameCopy = ""
                    o.dialogForm.phone = ""
                    o.dialogForm.phoneCopy = ""
                })
            })
        },
        'filters.selecShi': function(val) {
            this.querySearch();
        },
        'filters.selecXian': function(val) {
            this.querySearch();
        },
    },
    created() {
        this.utils = util
        var params = {
            parentId: 1919,
            level: 1,
        }
        this.getCity(params)
    },
    mounted() {
        var token = localStorage.getItem("Token")
        if(!token) {
            var url = "/l"
            this.$router.push(url);
        } else {
            this.getStationList()
            // this.getUnitData()
        }
        this.unitTitleName = this.$route.query.unitName
        this.action = analyseSocialUnitStaffChaos
        var params = {
            parentId: 1919,
        }
        this.getPlace(params,1)
    }
}
</script>
<style lang="stylus" scoped>
    .inputMessagePage {
        width: 90%;
        max-width: 400px;
        margin: 0 auto;
        border-bottom: 1px solid #eee;
    }
    .commerBox {
        width: 90%;
        max-width: 400px;
        margin: 0 auto;
    }
    .pageTitle {
        font-weight: bold;
        font-size: 15px;
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .btnBox {
        text-align: center;
        padding-bottom: 20px;
    }
    .reportBtn {
        width: 90%;
        margin-top: 40px;
        max-width: 400px;
    }
    .boxTips {
        border-bottom: 1px solid #eee;
        padding: 10px 0;
    }
    .commercialPage {
        border-bottom: 1px solid #eee;
    }
    .unitBox {
        text-align: center;
        padding-top: 20px;
        font-size: 15px;
        font-weight: bold;
    }
    .unitNameBox,.exportBox {
        width: 90%;
        max-width: 400px;
        margin: 0 auto;
    }
    .unitNameBox1 {
        width: 90%;
        max-width: 400px;
        margin: -44px auto 0;
    }
    .exportBox {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        margin-top: 20px;
    }
    .unitBoxSty {
        margin-top: 20px;
        margin-bottom: 5px;
    }
    .inline-input {
        width: 100%;
    }
    .completed {
        border: 1px solid #DCDFE6;
        padding: 10px;
        margin-top: 15px;
        border-radius: 3px;
        line-height: 25px;
        position: relative;
        // height: 170px;
        // overflow-y: auto;
    }
    .completedTitle {
        font-weight: bold;
        font-size: 15px;
        margin: 2px 0;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
    }
    .wrapBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .noWrapBox {
        display: flex;
        align-items: center;
    }
    .boxTipsc {
        color: #f56c6c;
        font-size: 14px;
    }
    .viewMorePer {
        position: absolute;
        bottom: 0;
        right: 0;
        background: #fff;
        padding: 0px 20px;
        width: 100%;
        text-align: right;
        height: 27px;
    }
    .viewBtn {
        padding: 0!important;
    }
    .noMoreReport {
        font-size: 16px;
        margin-top: 20px;
        text-align: center;
    }
    .completed1 {
        border: 1px solid #DCDFE6;
        padding: 10px;
        margin-top: 15px;
        border-radius: 3px;
        line-height: 25px;
    }
    .viewPerDetaislBox {
        display: flex;
        align-items: center;
    }
    .deleBtnSty {
        color: #fff;
        background: #f56c6c;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 12px;
        font-weight: bolder;
        border-radius: 50%;
        margin-left: 4px;
        font-size: 22px;
        cursor: pointer;
    }
</style>