<template>
    <div>
        <div class="operationPage">
            <div>
                <img class="pageImage" src="../assets/operation/1.png" alt="">
            </div>
            <div>
                <img class="pageImageCopy" src="../assets/operation/2.png" alt="">
            </div>
            <div>
                <video controls preload="auto" style="background: #000;width: 100%;height: 300px;" src="https://mpv.videocc.net/a21ba01cc4/f/a21ba01cc4aa9f25adc2934cfe68d70f_3.mp4"></video>
            </div>
            <div class="pageTipsBox">
                <div class="pageTips">如果您是单位联系人</div>
                <div class="pageTipsCopy">还请补充填写以下内容</div>
            </div>
            <div>
                <video controls preload="auto" style="background: #000;width: 100%;height: 300px;" src="https://mpv.videocc.net/a21ba01cc4/7/a21ba01cc47ad18f1dc614bf82d7d357_3.mp4"></video>
            </div>
            <div>
                <img class="pageImageCopy" src="../assets/operation/3.png" alt="">
            </div>
            <div>
                <video controls preload="auto" style="background: #000;width: 100%;height: 300px;" src="https://mpv.videocc.net/a21ba01cc4/c/a21ba01cc479f0f54b610fa94e92234c_3.mp4"></video>
            </div>
            <div>
                <img class="pageImageCopy" src="../assets/operation/4.png" alt="">
            </div>
            <div>
                <video controls preload="auto" style="background: #000;width: 100%;height: 300px;" src="https://mpv.videocc.net/a21ba01cc4/7/a21ba01cc42d5474f46d7898ce891b37_3.mp4"></video>
            </div>
            <div>
                <img class="pageImageCopy" src="../assets/operation/5.png" alt="">
            </div>
            <div>
                <video controls preload="auto" style="background: #000;width: 100%;height: 300px;" src="https://mpv.videocc.net/a21ba01cc4/6/a21ba01cc4a8e742484b138c65b18326_3.mp4"></video>
            </div>
            <div>
                <div class="pageEndTips">- END -</div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    data() {
        return {
            
        }
    },
    methods: {
        changePlayVideo() {
            var videos = document.getElementsByTagName("video");
            for(var i = videos.length - 1;i >= 0; i--) {
                (function(){
                    var p = i;
                    videos[p].addEventListener('play',function (){
                        pauseAll(p);
                    })
                })()
            }
            function pauseAll(index){
                for(var j = videos.length - 1; j >= 0; j--) {
                    if (j!=index) videos[j].pause();
                };
            }
        }
    },
    mounted() {
        this.changePlayVideo()
        $(window).scrollTop(0)
    }
}
</script>
<style lang="stylus" scoped>
.operationPage {
    text-align: center;
    padding-bottom: 25px;
}
.pageImage {
    width: 100vw;
    margin-left: -10px!important;
    margin-right: -10px!important;
}
.pageImageCopy {
    width: 70%;
    margin-top: 25px;
    margin-bottom: 15px;
}
.pageTipsBox {
    margin-top: 25px;
    margin-bottom: 15px;
}
.pageTips {
    color: #ff0000;
    font-size: 15px;
}
.pageTipsCopy {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
}
.pageEndTips {
    font-size: 16px;
    font-weight: bold;
    color: #ff0000;
    margin-top: 15px;
}
</style>