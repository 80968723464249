<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="关键字:">
                        <el-input v-model='filter.name' placeholder='请输入单位名称/姓名/手机号' clearable></el-input>
                    </el-form-item>
                    <!-- <el-form-item>
                        <el-select v-model="filter.province" placeholder="请选择省" clearable>
                            <el-option
                                v-for="item in provinceArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-select v-model="filter.city" placeholder="请选择市" clearable>
                            <el-option
                                v-for="item in cityArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filter.county" placeholder="请选择区/县" clearable>
                            <el-option
                                v-for="item in countyArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='search'>查询</el-button>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            highlight-current-row
            style="width: 100%"           
        >
            <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
            <!-- <el-table-column prop="ProvinceID" label="省" align="center"></el-table-column> -->
            <el-table-column prop="CityID" label="市" align="center"></el-table-column>
            <el-table-column prop="RegionID" label="区/县" align="center"></el-table-column>
            <el-table-column prop="UnitName" label="单位名称" align="center"></el-table-column>
            <el-table-column prop="NoticeCount" label="证书数量" align="center"></el-table-column>
            <el-table-column prop="Name" label="联系人" align="center"></el-table-column>
            <el-table-column prop="CellPhoneNumber" label="联系人手机号" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'>
        </el-pagination>
    </div>
</template>

<script>
import { listForSendSMS,getAreaDictionaryList } from '@/api/api'
export default {
    data(){
        return {
            buttonList:[],
            filter:{
                name: '',
                isSendSuccess: '',
                province: 1919,
                city: '',
                county: '',
                parentID: '',
            },
            provinceArr: [],
            cityArr: [],
            countyArr: [],
            tableData:[],
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            id:null
        }
    },
    methods:{
        // 查询
        search(){
            this.getData()
        },
        // 返回
        back(){
            this.$router.go(-1)
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        getData(){
            var params = {
                keyWord:this.filter.name,
                sendStatus:this.filter.isSendSuccess,
                provinceID:this.filter.province,
                cityID:this.filter.city,
                regionID:this.filter.county,
                noticeManageRecordID:this.filter.parentID,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            listForSendSMS(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }else{
                    return
                }
            })
        },
        GetAreaFun(e,i) {
            if(i == 0) {
                var params = {
                    Level: e,
                }
            } else {
                var params = {
                    parentId: e,
                }
            }
            getAreaDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    if(i == 0) {
                        this.provinceArr = result.Response.reverse()
                    } else if(i == 1) {
                        this.cityArr = result.Response.reverse()
                    } else if(i == 2) {
                        this.countyArr = result.Response.reverse()
                    }
                }
            })
        },
        // 时间格式化
        dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        }
    },
    created(){
        var isSendSuccess = this.$route.query.isSendSuccess
        var parentID = this.$route.query.ID
        this.filter.isSendSuccess = isSendSuccess
        this.filter.parentID = parentID
        this.getData()
        // this.GetAreaFun(0,0)
        this.GetAreaFun(1919,1)
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    },
    watch: {
        'filter.province': function(val) {
            this.GetAreaFun(val,1)
            this.filter.city = ''
            this.filter.county = ''
        },
        'filter.city': function(val) {
            this.GetAreaFun(val,2)
            this.filter.county = ''
        }
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>