<template>
    <div>
        <div class="appLoginPage">
            <div class="logoBox">
                <img src="../assets/systemIntroduction/logo.png" alt="">
            </div>
            <el-form :model="phoneForm" :rules="phoneFormRules" ref="phoneForm" label-position="left" label-width="0px">
                <el-form-item prop="userPhone">
                    <el-input type="text" v-model="phoneForm.userPhone" @input="(e) => (phoneForm.userPhone = utils.validForbid(e))" auto-complete="off" placeholder="请输入手机号">
                        <template slot="prepend">中国+86</template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="imageCode">
                    <div style="display: flex;">
                        <el-input v-model="phoneForm.imageCode" style="width: 228px;" auto-complete="off" placeholder="请输入图形验证码"></el-input>
                        <div v-loading="imageLoading">
                            <img :src="codeImage" @click="refreshCode" style="width: 150px;height: 40px;margin-left:20px;" alt="图形验证码">
                        </div>
                    </div>
                </el-form-item>
                <el-form-item prop="verificationCode">
                    <div style="display: flex;">
                        <el-input v-model="phoneForm.verificationCode" auto-complete="off" placeholder="请输入短信验证码"></el-input>
                        <el-button type="text" :loading="codeLogining" style="margin-left: 10px;" @click.native.prevent="getVerCodeFun">
                            {{getVerCode}}{{getVerCode1}}
                        </el-button>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="reportBtn" type="danger" :loading="reportLoding" @click.native.prevent="toReportFun">开始填报</el-button>
                </el-form-item>
                <!-- <el-form-item>
                    <el-checkbox class="protocolBox" v-model="agreeClause"></el-checkbox>阅读并同意<span class="userProtocol" @click="readingProtocol">《用户注册》</span>与<span class="userProtocol" @click="readingProtocol">《隐私保护服务协议》</span>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>
<script>
import util from '../../util/date'
import { validPhone } from "../../util/validate";
import { sendSMSVerificationCode,sendSMSVerificationCodeWithVerify,studentCodeToken,getStudentSocialUnitStatus,captchaImage } from '../api/api';
export default {
    data() {
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
            utils: '',
            getVerCode: '获取验证码',
            getVerCode1: '',
            codeLogining: false,
            reportLoding: false,
            agreeClause: true,
            phoneForm: {
                userPhone: "",
                imageCode: "",
                verificationCode: "",
            },
            codeImage: "",
            Uuid: "",
            imageLoading: false,
            phoneFormRules: {
                userPhone: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    { validator: isPhoneNumberNum },
                ],
                verificationCode: [
                    {required: true, message: '请输入短信验证码', trigger: 'blur'},
                ],
            },
            timer: '',
        }
    },
    methods: {
        // 获取图形验证码
        refreshCode() {
            var params = {}
            this.imageLoading = true
            captchaImage(params).then(res => {
                var result = res
                if(result.Success) {
                    this.codeImage = "data:image/jpg;base64," + result.Response.Img
                    this.Uuid = result.Response.Uuid
                    this.imageLoading = false
                }
            })
        },
        // 获取验证码
        getVerCodeFun() {
            if(this.phoneForm.userPhone && validPhone(this.phoneForm.userPhone)){
                if(this.phoneForm.imageCode) {
                    this.$confirm("将要获取短信验证码", "提示", {}).then(() => {
                        this.codeLogining = true
                        var params = {
                            SendType: 5,
                            PhoneNumber: this.phoneForm.userPhone,
                            Uuid: this.Uuid,
                            Code: this.phoneForm.imageCode,
                        }
                        sendSMSVerificationCodeWithVerify(params).then(res => {
                            var result = res.data
                            if(result.Success){
                                this.sendPhone();
                                this.getVerCode1 = "秒后重新获取"
                                this.$message({
                                    message: '验证码发送成功！',
                                    type: 'success'
                                });
                                var nowTimer = new Date()
                                sessionStorage.setItem("nowTimer",nowTimer)
                            }else{
                                this.refreshCode()
                                this.$message({
                                    message: result.Message,
                                    type: 'error'
                                });
                                this.codeLogining = false
                            }
                        })
                    })
                    .catch(() => {})
                } else {
                    this.$message.warning("请先输入图形验证码！")
                }
            }else{
                this.$message.warning("请输入正确的手机号！")
            }
        },
        // 获取学员社会单位联系人状态
        getStudentStatus(val) {
            var params = {}
            getStudentSocialUnitStatus().then(res => {
                var result = res.data
                if(result.Success) {
                    if(result.Response.IsContacts) {
                        setTimeout(() => {
                            var url = "/i"
                            this.$router.replace({
                                path: url,
                                query: {unitName: result.Response.SocialUnitName}
                            });
                        },200)
                    } else {
                        setTimeout(() => {
                            var url = "/no"
                            this.$router.push(url);
                        },200)
                    }
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        // 开始填报
        toReportFun() {
            // if(!this.agreeClause) {
            //     this.$message.warning("请选阅读并同意《用户注册》与《隐私保护服务协议》")
            //     return
            // }
            if(this.phoneForm.userPhone && validPhone(this.phoneForm.userPhone) && this.phoneForm.verificationCode) {
                this.reportLoding = true
                var params = {
                    account: this.phoneForm.userPhone,
                    code: this.phoneForm.verificationCode
                }
                studentCodeToken(params).then(res => {
                    var result = res.data
                    if(result.Success) {
                        // 获取学员社会单位联系人状态
                        this.$store.commit("saveToken",result.Response.Token)
                        localStorage.setItem("Token",result.Response.Token)
                        this.getStudentStatus(result.Response.Token)
                        this.codeLogining = false;
                        this.getVerCode1 = ""
                        this.getVerCode = "获取验证码"
                        clearInterval(this.timer); // 清除定时器
                        this.timer = null;
                        sessionStorage.removeItem("codeData")
                        sessionStorage.removeItem("nowTimer")
                    } else {
                        this.$message.error(result.Message)
                    }
                    this.reportLoding = false
                })
            } else {
                this.$message.warning("请正确输入手机号或验证码！")
            }
        },
        // 阅读协议
        readingProtocol() {
            var url = "/p"
            this.$router.push(url);
        },
        // 倒计时
        sendPhone(){
            const TIME_COUNT = sessionStorage.getItem("codeData") || 60; //  更改倒计时时间
            if (!this.timer) {
                this.getVerCode = TIME_COUNT;
                this.codeLogining = true;
                this.timer = setInterval(() => {
                    if (this.getVerCode > 0 && this.getVerCode <= TIME_COUNT) {
                        this.getVerCode--;
                        sessionStorage.setItem("codeData",this.getVerCode)
                    } else {
                        this.codeLogining = false;
                        this.getVerCode1 = ""
                        this.getVerCode = "获取验证码"
                        clearInterval(this.timer); // 清除定时器
                        this.timer = null;
                        sessionStorage.removeItem("codeData")
                        sessionStorage.removeItem("nowTimer")
                    }
                }, 1000);
            }
        },
    },
    created() {
        this.utils = util
        // 已经获取验证码后防止刷新页面数据丢失
        var nowTimer = sessionStorage.getItem("nowTimer")
        var timeData = sessionStorage.getItem("codeData")
        var newNowTimer = new Date()
        if(newNowTimer - nowTimer > 60) {
            if(timeData){
                this.codeLogining = false
                this.getVerCode1 = "获取验证码"
            }
        } else {
            if(timeData){
                this.sendPhone()
                this.codeLogining = true
                this.getVerCode1 = "秒后重新获取"
            }
        }
    },
    mounted() {
        this.refreshCode()
    }
}
</script>
<style lang="stylus" scoped>
    .logoBox {
        text-align: center;
        margin-bottom: 50px;
    }
    .logoBox img {
        width: 100px;
    }
    .appLoginPage {
        width: 90%;
        margin: 0px auto 0;
        padding-top: 50px;
        max-width: 400px;
    }
    .reportBtn {
        width: 100%;
        margin-top: 40px;
    }
    .protocolBox {
        margin-top: 150px;
    }
    .userProtocol {
        color: red;
    }
</style>