<template>
    <div>
        <el-row :gutter='20' style='margin-bottom:15px'>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item>
                        <el-input placeholder='输入号码' v-model='phone' clearable @clear='handleClear'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='search'>查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data="tableData" border style="width: 50%" :cell-style="rowClass">
            <el-table-column prop='PhoneNumber' label='号码'></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
        class='pageBar'
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="this.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getPhoneNumberLibraryDetailListPage } from '@/api/api'
export default {
    data(){
        return {
            tableData:[],
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            phone:''
        }
    },
    methods:{
        rowClass () {
            return 'padding:8px 0!important;'
        },
        // 筛选清空
        handleClear(){
            this.getData()
        },
        // 查询
        search(){
            this.getData()
        },
        // 返回
        back(){
            this.$router.replace({path:'/NoticeManage/PhoneNumberLibrary'})
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
        getData(){
            var params = {
                phoneNumberLibraryID:this.$route.query.id,
                phoneNumber:this.phone,
                pageIndex:1,
                pageSize:20
            }
            getPhoneNumberLibraryDetailListPage(params).then(res => {
                if(res.data.Success){
                    this.$message({
                        message:res.data.Message,
                        type:'success'
                    })
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message({
                        message:res.data.Message,
                        type:'success'
                    })
                }
            }).catch(() => {})
        }
    },
    created(){
        this.getData()
    },
    mounted(){
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>