<template>
    <div style="font-size: 18px;text-align: justify;width: 95%;margin: 0 auto;line-height: 30px;">
        <!-- <p>&nbsp;</p> -->
        <p style="text-align: center;font-weight: bold;font-size: 18px;">使用“培训考试APP”进行评价考试的注意事项</p>
        <!-- <p>&nbsp;</p> -->
        <p>社会单位人员使用手机运行“培训考试APP”进行评价考试时，为了避免使用过程中出现意外情况干扰考试，请注意以下事项：</p>
        <!-- <p>&nbsp;</p> -->
        <p>1、请诚信考试，不要作弊。系统监控到考试过程中存在替考、在旁边提示答案等作弊等行为时，会强制交卷、考试成绩作废。</p>
        <!-- <p>&nbsp;</p> -->
        <p>2、请考试前关闭手机息屏、将手机锁屏时间设置为“永不”，避免因为手机黑屏或锁屏引起考试中断，影响考试成绩。</p>
        <!-- <p>&nbsp;</p> -->
        <p>3、请将手机调至“免打扰模式”。避免因为来电引起考试中断，影响考试成绩。</p>
        <!-- <p>&nbsp;</p> -->
        <p>4、请保证手机电量充足。避免因为手机电量不足引起考试中断，影响考试成绩。</p>
        <!-- <p>&nbsp;</p> -->
        <p>5、请确保考试环境安静、光线充足。避免因为系统无法正常进行声音、图像监测造成强制交卷，影响考试成绩。</p>
        <!-- <p>&nbsp;</p> -->
        <p>6、请确保在良好的网络环境下进行考试。避免因为网络状况不佳而影响系统正常运行造成强制交卷，影响考试成绩。</p>
        <!-- <p>&nbsp;</p> -->
        <p><strong>7、考试过程中，由于系统原因造成闪退，系统会恢复考生的考试资格，考生可再次点击“去考试”按钮进行考试。</strong></p>
        <!-- <p>&nbsp;</p> -->
        <p><strong>8、考试过程中，如果被系统抓拍到多人入框、眼睛未正视屏幕、歪头低头、身体侧坐、头部距屏幕较远、使用手机拍题等行为，即使考试成绩及格，也会被审核驳回，不予发证。</strong></p>
        <!-- <p>&nbsp;</p> -->
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="stylus" scoped>
 
</style>