<template>
	<!-- <div style="height:790px;background:#39B3FF;"> -->
	<div>
		<div class="boxWelcomeTitle">
			<div ></div>
			<!-- <div @click="backWelcome()" style="cursor: pointer;" v-else>
				<img style="padding-left: 15px;margin-top:7px;" src="../assets/quit.png" />
			</div> -->
			<!-- <div>社会消防安全教育培训管理系统</div> -->
			<div></div>
		</div>
	</div>
</template>
<script>
// var echarts = require('echarts');
import { cityMapFun } from "../../util/map";
import { getStudentLearnRecords,getJsonFile,examenrollCountGroupByProjectNameByID,getParticipatingJurisdictionsRegion,getProportionCertificateHoldersByID,getSocialUnitCertificateHoldersStatistics,getHomeSocialUnitStatistics,getSocialUnitListPage,getAreaDictionaryList,getUnitsAndCertificatesResponseByID,getUnitsAndCertificatesSingleResponseByID } from '@/api/api'
// 改版接口
import { getCertificateCountGroupByDate,getCertificateCountGroupByTradeByID,getCurrentLevelCertificateHoldingByID,getCertificateHoldingUnderTheJurisdiction,getCertificateCountByID,getMapOfCertificateHolding } from '@/api/api'
import { getAttendTrainingCountGroupByDate,getTrainingCountGroupByTradeByID,getCurrentLevelParticipationByID,getMapRegistrationRateResponseStatistics,getParticipatingJurisdictions,getTrainingCountByID,getTrainingMap } from '@/api/api'
import { getExamenrollCountGroupByDateByID,getTrainingCountGroupByTradeByIDByType,getUnitAndRegistrationCountRateStatisticsByID,getUnitAndRegistrationCountRateStatistics } from '@/api/api'
import { getMaterialExamPaperRightRatio,getToBeSupervisedSocialUnitCount,getTrainingExaminationCertificateStatistics,getTrainingExaminationCertificateByID,getUnitAndSupervisionResponseStatistics,getAgencyInfoPass } from '@/api/api'
import api from '@/api'
export default {
	name: "Welcome",
	data() {
		return {
			backImageUrl: "url('https://gd.119.gov.cn/kaoshi/img/bg.png')",
			pageHeight: 830,
			tableHeight1: 318,
			tableHeight2: 357,
			mapHeight: 520,
			unitBoxHeight: 380,
		}
	},
	methods:{},
	beforeMount() {
		
		// localStorage.removeItem("nowCityID")
	},
	mounted() {
		let base = window.location.origin
		this.backImageUrl = "url('" + base + "/kaoshi/img/bg.png')"
		localStorage.removeItem("nowCityID")
	},
};
</script>

<style lang="stylus" scoped>
	.boxWelcomeTitle {
		display: flex;
		justify-content: space-between;
		margin-top: -20px;
		background: rgba(6, 23, 64, 1)!important;
		font-size: 20px;
		color: #39B3FF;
		line-height: 40px;
	}
	.tableTopBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(6, 23, 64, 0.7)!important;
		padding-top: 4px;
		height: 29px;
	}
	.tableTitle {
		color #fff;
		font-size: 14px;
		padding-left: 3px;
		cursor: pointer;
	}
	.tableTopBox img{
		width: 30px;
    	height: 30px;
		padding-right: 3px;
		cursor: pointer;
	}
	.tableClass>>>.el-table__header-wrapper table thead tr th {
		background: rgba(6, 23, 64, 0.7)!important;
		color #fff;
	}
	.tableClass>>>.el-table__body tbody .el-table__row {
		background: rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass>>>.el-table__body tbody .el-table__row td .cell .el-tag {
		background-color: rgba(6, 23, 64, 0.7)!important;
		border-color: #409EFF!important;
	}
	.tableClass>>>td, .tableClass>>>th.is-leaf {
		border-bottom: 1px solid rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass {
		background-color: rgba(6, 23, 64, 0.7)!important;
	}
	.tableClass>>>.el-table__body-wrapper,.tableClass>>>.is-scrolling-none {
		// padding-right: 20px;
	}
	.tableClass>>>.el-table__body-wrapper::-webkit-scrollbar {
		width: 0px;
	}
	.tableRightBottom>>>.el-table__body {
		padding-bottom: 32px!important;
	}
	.tableClass>>>.el-table__header-wrapper .el-table__header .has-gutter th .cell {
		font-size: 12px!important;
	}
	.knowRateClass>>>.el-table__header-wrapper table thead tr th {
		background: rgba(6, 23, 64, 0.7)!important;
		color #fff;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row {
		background: rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagWarning {
		background-color: #ffc760!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagDanger {
		background-color: #d62c2c!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>.el-table__body tbody .el-table__row td .cell .tagBlue {
		background-color: #00b6b2!important;
		border-color: #409EFF!important;
		color: #fff!important;
	}
	.knowRateClass>>>td, .knowRateClass>>>th.is-leaf {
		border-bottom: 1px solid rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass {
		background-color: rgba(6, 23, 64, 0.7)!important;
	}
	.knowRateClass>>>.el-table__body-wrapper::-webkit-scrollbar {
		width: 0px;
	}
	// 鼠标滑上表格行颜色的变化
	.knowRateClass>>>.el-table__body tr:hover>td {
		background-color: #eee!important;
		color: rgba(6, 23, 64, 0.7)!important;
	}
	.centerViews {
		display: flex;
    	justify-content: space-around;
    	align-items: center;
		height: 140px;
	}
	.viewsBox {
		width: 250px;
    	height: 100px;
		background: rgba(6, 23, 64, 0.7);
		display: flex;
		justify-content: space-around;
		border: 1px dashed #47e0e0
	}
	.viewsBox div {
		display: flex;
		flex-direction: column;
		line-height: 36px;
		justify-content: center;
	}
	.boxTitle {
		font-size: 14px;
		color: #fff;
	}
	.boxViewNum {
		font-size: 26px;
		font-weight: bold;
		color: #47E0E0;
		cursor: pointer;
	}
	#knowRate {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}
	#lineTable {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}

	#trainingSituation {
		margin-top: 18px;
		border: 1px solid #47e0e0;
	}

	#pieTable {
		border-right: 1px solid #47e0e0;
		border-bottom: 1px solid #47e0e0;
		border-left: 1px solid #47e0e0;
	}

	.pieTableTitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: rgba(6, 23, 64, 0.8)!important;
		margin-top: 20px;
		font-size: 14px;
		height: 29px;
		margin-right: -2px;
		color: #fff;
		border-right: 1px solid #47e0e0;
		border-top: 1px solid #47e0e0;
		border-left: 1px solid #47e0e0;
		cursor: pointer;
	}

	#StyloliticTable,#sortTable,#unitParentBox {
		border-right: 1px solid #47e0e0;
		border-left: 1px solid #47e0e0;
		border-bottom: 1px solid #47e0e0;
	}
	.table1 {
		border-top: 1px solid #47e0e0;
	}
	.buttonBox {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
		width: 50%;
	}
	.buttonBoxCopy {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0px;
		width: 50%;
	}
	.buttonVies {
		width: 110px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		color: #fff;
		border: 1px solid #47e0e0;
		margin: 0 7px;
		cursor: pointer;
		user-select: none;
		transform: skewx(-15deg);
		display: flex;
    	justify-content: center;
		background: rgba(6, 23, 64, 1);
	}
	.buttonVies div {
		transform: skewx(15deg);
		font-size: 14px;
	}
	.buttonVies span {
		transform: skewx(15deg);
		font-size: 14px;
	}
	.buttonActive {
		color: #ffdd77;
	}
	#unitParentBox>>>.el-radio {
		margin-right: 5px;
	}
	#unitParentBox>>>.el-radio__label {
		padding-left: 3px;
		color: #fff;
	}
	.floatBoxTitle {
		font-size: 15px;
    	font-weight: 600;
		color: #47e0e0;
	}
	.floatingBox {
		display: flex;
		justify-content: flex-start;
		line-height: 24px;
	}
	.floatBoxLeft {
		width: 200px;
		color: #47e0e0;
	}
	.floatBoxRight {
		color: #47e0e0;
	}
	.topBoxAverage {
		width: 26px;
		height: 24px;
		line-height: 24px;
		border: 1px solid;
		border-radius: 18px;
		font-size: 12px;
		margin-bottom: 2px;
	}
</style>
