<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model='filters.keyWord' placeholder="请输入关键字" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="数据来源:">
                        <el-select v-model="filters.dataSource" clearable>
                            <el-option
                                v-for="item in dataSourceArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="重发次数:">
                        <el-select v-model="filters.pendingClearcause" clearable>
                            <el-option
                                v-for="item in pendingClearcauseArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button @click="getTableData" type="primary" size="">查询</el-button>
                        <el-button @click="resendSMS" type="primary" size="">重新发送</el-button>
                        <el-button @click="back" type="warning" size="">返回</el-button>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <!--列表-->
        <el-table
            :data="listData"
            highlight-current-row
            v-loading="listLoading"
            border=""
            @current-change="selectCurrentRow"
            style="width: 95%;"
            ref="multipleTable"
            @selection-change="handleSelectChange"
        >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column prop="Name" align="center" label="姓名" min-width="100"></el-table-column>
            <el-table-column prop="PhoneNumber" align="center" label="手机号" min-width="130"></el-table-column>
            <el-table-column prop="UnitName" align="center" label="单位名称" min-width="150"></el-table-column>
            <el-table-column prop="ResourceType" align="center" label="数据来源" min-width="130">
                <template scope="scope">
                    <el-tag v-if="scope.row.ResourceType == 0" type="">数据中台</el-tag>
                    <el-tag v-if="scope.row.ResourceType == 1" type="">H5自行填报</el-tag>
                    <el-tag v-if="scope.row.ResourceType == 2" type="">大数据甄选</el-tag>
                    <el-tag v-if="scope.row.ResourceType == 3" type="">自主注册</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="NoticeCount" align="center" label="重发次数" min-width="150"></el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar" style="display: flex;">
            <div style="margin-top: 6px;">已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
    </div>
</template>

<script>
// import Toolbar from "../../components/Toolbar"
// import { getButtonList } from "../../promissionRouter";
import { getReceiptFailData,resendReceiptFailStaff } from '@/api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
    // components: {Toolbar},
    data() {
        return{
            socialGroupID: 0,
            noticeType: 0,
            filters: {
                keyWord: "",
                dataSource: "",
                pendingClearcause: "",
            },
            buttonList: [],
            listLoading: false,
            listData: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            editData: '',
            dataSourceArr: [
                {value: 0, name: "数据中台"},
                {value: 1, name: "H5自行填报"},
                {value: 2, name: "大数据甄选"},
                {value: 3, name: "自主注册"},
            ],
            pendingClearcauseArr: [
                {value: 0, name: "0"},
                {value: 1, name: "1"},
                {value: 2, name: "2"},
            ],
            allSelect: "",
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取分页列表
        getListData() {
            var _this = this
            var params = {
                socialGroupID: this.socialGroupID,
                type: this.noticeType,
                keyWord: this.filters.keyWord,
                resourceType: this.filters.dataSource,
                noticeCount: this.filters.pendingClearcause,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true;
            getReceiptFailData(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    this.listData = result.Response.Data
                }
            })
        },
        // 回执失败短信重发
        resendSMS() {
            var selectionData = this.$refs.multipleTable.selection
            if(selectionData.length > 0) {
                this.$confirm(`确定要重新发送短信？`,"提示",{
                }).then(() => {
                    var ids = []
                    selectionData.forEach(item => {
                        ids.push(item.NoticeManageRecordID)
                    });
                    var params = {
                        Ids: ids,
                        socialGroupID: this.socialGroupID,
                        type: this.noticeType,
                    }
                    resendReceiptFailStaff(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getListData()
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((error) => {

                })
            } else {
                this.$message.warning("请至少选择一行！")
            }
        },
        // 返回
        back() {
            this.$router.go(-1)
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val           
            this.getListData();
        },
        // 点击table某一行
        selectCurrentRow(val) {
            this.editData = val
        },
        // 选中表格行
        handleSelectChange(allRow) {
            this.allSelect = allRow
        },
        // 查询
        getTableData() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    mounted() {
        // let routers = window.localStorage.router
        // ? JSON.parse(window.localStorage.router)
        // : [];
        // this.buttonList = getButtonList(this.$route.path, routers);
        this.socialGroupID = this.$route.query.socialGroupID
        this.noticeType = this.$route.query.noticeType
        this.getListData();
    },
}
</script>

<style lang="stylus" scoped>
    .el-textarea>>>.el-textarea__inner {
        min-height 150px!important;
    }
</style>