<template>
    <div>
        <div class="appLoginPage">
            您无需填报消防工作相关人员名单!
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    created() {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="stylus" scoped>
    .appLoginPage {
        width: 90%;
        margin: 150px auto 0;
        text-align: center;
        font-size: 16px;
    }
    .reportBtn {
        width: 100%;
        margin-top: 40px;
    }
</style>