<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row :gutter="10">
            <!-- 左侧区域 -->
            <el-col :xs="dataType != 0?15:23" :sm="dataType != 0?15:23" :md="dataType != 0?15:23" :lg="dataType != 0?15:23" :xl="dataType != 0?15:23">
                <div class="grid-content bg-purple-light">
                    <el-card class="box-card" style="width: 97%;margin:0 auto;">
                        <!-- <div slot="header" class="clearfix">
                        </div> -->
                        <div style="display:flex;justify-content: space-between;margin-top:-20px;">
                            <h2>{{titleName}}</h2>
                            <h1 style="color:#D92919;">{{paperMark}}<span style="font-size:20px;margin-left:2px;">分</span></h1>
                        </div>
                        <div style="display:flex;justify-content: space-between;">
                            <div style="display:flex;margin-top:10px;">
                                <div>创建时间<span style="margin-left:10px;"></span>{{timeNow}}</div>
                                <div v-if="isFixedType === '0'" style="margin:0 20px;">共<span style="margin:0 5px;">{{tableData.length}}</span>题</div>
                                <div style="margin-left: 20px;">{{paperType}}</div>
                            </div>
                            <div>
                                <el-button size="small" @click="editTitleChange()">编辑</el-button>
                            </div>
                        </div>
                        <div v-if="titleDescribe" style="margin-top:20px;">{{titleDescribe}}</div>
                    </el-card>
                    <div style="width:94%;margin:10px auto;">
                        <el-button type="primary" size="small" @click="goBack()">返回</el-button>
                        <el-button type="danger" v-if="isFixedType === '0'" size="small" @click="deleteAll()">删除</el-button>
                        <el-button type="primary" size="small" @click="randomPreserve()" :loading="addLoading">保存</el-button>
                        <el-button type="primary" v-if="isFixedType === '0'" size="small" @click="selectQuestion()">手动选题</el-button>
                        <el-button type="primary" size="small" @click="settingMark()">批量设置分数</el-button>
                        <el-button type="primary" v-if="isFixedType === '0'" size="small" @click="allIsViwerChange()">{{allIsViwes?'关闭':'展开'}}</el-button>
                        <el-button type="primary" size="small" @click="doFreashPaper()">重置</el-button>
                        <el-button type="primary" v-if="isFixedType === '0'" size="small" @click="updatapaperMarkFun()">刷新统计信息</el-button>
                    </div>
                    <!-- 固定试卷table -->
                    <el-table
                    :data="tableData"
                    row-key="num"
                    v-if="isFixedType === '0'"
                    :show-header="false"
                    class="tableLeft"
                    style="">
                        <el-table-column prop="ApprovalProcessName" label="试卷名称">
                            <template slot-scope="scope">
                                <el-card class="box-card">
                                    <div style="display:flex;justify-content: space-between;">
                                        <div>
                                            <el-checkbox v-model="allCheckbox" :label="scope.row.num">{{scope.row.numc}}</el-checkbox>
                                            {{scope.$index+1}},
                                            <el-tag type="success" v-if="scope.row.QuestionsType == 0">单选题</el-tag>
                                            <el-tag type="success" v-if="scope.row.QuestionsType == 1">多选题</el-tag>
                                            <el-tag type="success" v-if="scope.row.QuestionsType == 2">判断题</el-tag>
                                            <el-tag type="success" v-if="scope.row.Difficulty == 0" style="margin:0px 10px;">难度：高</el-tag>
                                            <el-tag type="success" v-if="scope.row.Difficulty == 1" style="margin:0px 10px;">难度：中</el-tag>
                                            <el-tag type="success" v-if="scope.row.Difficulty == 2" style="margin:0px 10px;">难度：低</el-tag>
                                        </div>
                                        <div>
                                            <!-- <el-input v-model="scope.row.Score" size="small" style="width:110px;">
                                                <template slot="append">分</template>
                                            </el-input> -->
                                            <input type="text" style="width: 50px;height: 27px;border-radius: 4px;border: 1px solid #e3e3e3;outline: none;padding-left:4px;" v-model="scope.row.Score" />
                                            <span style="margin-left:10px">分</span>
                                            <el-button size="small" style="margin-left:10px;" @click="isviewsChange(scope.row)">{{scope.row.isViwes?'关闭':'展开'}}</el-button>
                                            <el-button type="danger" @click="deleteOne(scope.$index)" size="small" icon="el-icon-delete"></el-button>
                                        </div>
                                    </div>
                                    <div style="margin:10px 0 0 10px;">{{scope.row.QuestionsTitle}}</div>
                                    <div v-if="scope.row.QuestionsType == 0 && scope.row.isViwes">
                                        <div v-for="(item,index) in scope.row.Options" :key="index" style="margin-top:10px;margin-left:10px;" class="answer">
                                            <el-radio disabled v-model="item.IsAnswer" :label="true">{{item.AnswerContent}}</el-radio>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.QuestionsType == 1 && scope.row.isViwes">
                                        <div v-for="(item,index) in scope.row.Options" :key="index" style="margin-top:10px;margin-left:10px;" class="answer">
                                            <el-checkbox disabled v-model="item.IsAnswer" :label="true">{{item.AnswerContent}}</el-checkbox>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.QuestionsType == 2 && scope.row.isViwes">
                                        <div v-for="(item,index) in scope.row.Options" :key="index" style="margin-top:10px;margin-left:10px;" class="answer">
                                            <el-radio disabled v-model="item.IsAnswer" :label="true">{{item.AnswerContent}}</el-radio>
                                        </div>
                                    </div>
                                </el-card>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 随机试卷table -->
                    <el-row v-if="isFixedType === '1'">
                        <el-col :span="19">
                            <el-table
                            highlight-current-row
                            :data="manualLeft11"
                            v-loading="listLoading3"
                            @current-change="selectCurrentRow"
                            border
                            lazy
                            :load="load1"
                            row-key="ID"
                            :tree-props="{children: 'Children', hasChildren: 'HasChildren'}"
                            ref="multipleTable"
                            style="width: 100%">
                                <!-- <el-table-column type="selection" align="center" prop="date" label="" width="60">
                                    <template scope="scope">
                                        <el-checkbox v-model="textbookID" :label="scope.row.ID">{{scope.row.ids}}</el-checkbox>
                                    </template>
                                </el-table-column> -->
                                <el-table-column prop="Name" label="教材名称" width="250"></el-table-column>
                                <el-table-column prop="Remarks" label="选择" width="">
                                    <template scope="scope">
                                    <!-- <template> -->
                                        <el-card class="box-card" shadow="never">
                                            <div class="text item" style="display:flex;justify-content: space-between;align-items: center;">
                                                <div style="display:flex;align-items: center;">
                                                    <div>单选题：</div>
                                                    <div>
                                                        <div v-if="!scope.row.isDifficulty1" class="viewsPar">
                                                            <div>共</div><div class="viewsQuesNum">{{scope.row.danxuan1}}</div><div>题，选取</div><el-input v-model="scope.row.allSelect1" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty1" class="viewsPar">
                                                            <div>难度低：共</div><span class="viewsQuesNum">{{scope.row.di1}}</span><div>题，选取</div><el-input v-model="scope.row.nanduDI1" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty1" class="viewsPar">
                                                            <div>难度中：共</div><span class="viewsQuesNum">{{scope.row.zhong1}}</span><div>题，选取</div><el-input v-model="scope.row.nanduZhong1" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty1" class="viewsPar">
                                                            <div>难度高：共</div><span class="viewsQuesNum">{{scope.row.gao1}}</span><div>题，选取</div><el-input v-model="scope.row.nanduGao1" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div><el-checkbox v-model="scope.row.isDifficulty1">按难易度</el-checkbox></div>
                                            </div>
                                            <el-divider v-if="scope.row.isDifficulty2 || scope.row.isDifficulty1"></el-divider>
                                            <div class="text item" style="display:flex;justify-content: space-between;align-items: center;">
                                                <div style="display:flex;align-items: center;">
                                                    <div>多选题：</div>
                                                    <div>
                                                        <div v-if="!scope.row.isDifficulty2" class="viewsPar">
                                                            <div>共</div><div class="viewsQuesNum">{{scope.row.duoxuan2}}</div><div>题，选取</div><el-input v-model="scope.row.allSelect2" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty2" class="viewsPar">
                                                            <div>难度低：共</div><span class="viewsQuesNum">{{scope.row.di2}}</span><div>题，选取</div><el-input v-model="scope.row.nanduDI2" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty2" class="viewsPar">
                                                            <div>难度中：共</div><span class="viewsQuesNum">{{scope.row.zhong2}}</span><div>题，选取</div><el-input v-model="scope.row.nanduZhong2" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty2" class="viewsPar">
                                                            <div>难度高：共</div><span class="viewsQuesNum">{{scope.row.gao2}}</span><div>题，选取</div><el-input v-model="scope.row.nanduGao2" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div><el-checkbox v-model="scope.row.isDifficulty2">按难易度</el-checkbox></div>
                                            </div>
                                            <el-divider v-if="scope.row.isDifficulty3 || scope.row.isDifficulty2"></el-divider>
                                            <div class="text item" style="display:flex;justify-content: space-between;align-items: center;">
                                                <div style="display:flex;align-items: center;">
                                                    <div>判断题：</div>
                                                    <div>
                                                        <div v-if="!scope.row.isDifficulty3" class="viewsPar">
                                                            <div>共</div><span class="viewsQuesNum">{{scope.row.panduan3}}</span><div>题，选取</div><el-input v-model="scope.row.allSelect3" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty3" class="viewsPar">
                                                            <div>难度低：共</div><span class="viewsQuesNum">{{scope.row.di3}}</span><div>题，选取</div><el-input v-model="scope.row.nanduDI3" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty3" class="viewsPar">
                                                            <div>难度中：共</div><span class="viewsQuesNum">{{scope.row.zhong3}}</span><div>题，选取</div><el-input v-model="scope.row.nanduZhong3" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                        <div v-if="scope.row.isDifficulty3" class="viewsPar">
                                                            <div>难度高：共</div><span class="viewsQuesNum">{{scope.row.gao3}}</span><div>题，选取</div><el-input v-model="scope.row.nanduGao3" size="small" style="width:50px;margin:0 10px;"></el-input><div>题</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div><el-checkbox v-model="scope.row.isDifficulty3">按难易度</el-checkbox></div>
                                            </div>
                                        </el-card>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                        <el-col :span="5">
                            <div style="padding:0 0 0 20px;">
                                <h3>试卷统计</h3>
                                <div style="margin-bottom:5px;font-weight: bold;">题型统计</div>
                                <div>单选题：{{freePaperData.radioTypeQues}}</div>
                                <div>多选题：{{freePaperData.cheboxTypeQues}}</div>
                                <div>判断题：{{freePaperData.judgeTypeQues}}</div>
                                <!-- <div style="margin:20px 0 5px 0;font-weight: bold;">难易度统计</div>
                                <div>低：{{freePaperData.radioTypeQuesTage}}</div>
                                <div>中：22%</div>
                                <div>高：33%</div> -->
                                <div style="margin:20px 0 5px 0;font-weight: bold;">分数显示</div>
                                <div>单选题：{{viewsScore.radio}}</div>
                                <div>多选题：{{viewsScore.check}}</div>
                                <div>判断题：{{viewsScore.judge}}</div>
                                <div style="margin-top:50px;font-weight:bold;">总题数：{{freePaperData.questionSumNum}}题</div>
                                <el-button type="primary" size="small" @click="updataPaperData()" style="margin:15px auto 0;">刷新统计信息</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <!-- 右侧区域 -->
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" v-if="dataType != 0">
                <div class="grid-content bg-purple">
                    <h2 style="text-align:center">考试设置</h2>
                    <el-collapse v-model="activeNames" @change="handleChange" style="padding:5px;">
                        <el-collapse-item class="settingSome" title="基础设置" icon="el-icon-platform-eleme" name="1">
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    时长
                                </div>
                                <div>
                                    <el-input placeholder="" v-model="baseSettingData.examTimeLeng" size="small" style="width:120px;">
                                        <template slot="append">分钟</template>
                                    </el-input>
                                </div>
                            </div>
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    及格分
                                </div>
                                <div>
                                    <el-input placeholder="" v-model="baseSettingData.qualifiedScoe" size="small" style="width:120px;">
                                        <template slot="append">分</template>
                                    </el-input>
                                </div>
                            </div>
                            <!-- <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    考试时间
                                </div>
                                <div>
                                    <el-switch
                                        v-model="baseSettingData.timerValue"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6">
                                    </el-switch>
                                </div>
                            </div>
                            <div class="examSettingFrom" v-if="baseSettingData.timerValue">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    开始时间/结束时间
                                </div>
                            </div>
                            <div class="examSettingFrom" v-if="baseSettingData.timerValue">
                                <div>
                                    <el-date-picker
                                        v-model="baseSettingData.examSetTime"
                                        size="small"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </div> -->
                            <!-- <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    到结束才能看考试结果
                                </div>
                                <div>
                                    <el-switch
                                        v-model="baseSettingData.examResult"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6">
                                    </el-switch>
                                </div>
                            </div>
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    迟到几分钟不能考试
                                </div>
                                <div>
                                    <el-switch
                                        v-model="baseSettingData.beLateExam"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6">
                                    </el-switch>
                                </div>
                                <div>
                                    <el-input :disabled="!baseSettingData.beLateExam" v-model="baseSettingData.beLateTimer" placeholder="" size="small" style="width:120px;">
                                        <template slot="append">分钟</template>
                                    </el-input>
                                </div>
                            </div>
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    开始后几分钟能交卷
                                </div>
                                <div>
                                    <el-switch
                                        v-model="baseSettingData.canSubmitSwitch"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6">
                                    </el-switch>
                                </div>
                                <div>
                                    <el-input :disabled="!baseSettingData.canSubmitSwitch" v-model="baseSettingData.canSubmitPaper" placeholder="" size="small" style="width:120px;">
                                        <template slot="append">分钟</template>
                                    </el-input>
                                </div>
                            </div> -->
                        </el-collapse-item>
                        <!-- <el-collapse-item class="settingSome" title="考试中" name="2">
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    全部答完才能交卷
                                </div>
                                <div>
                                    <el-switch
                                        v-model="inTheSettingData.IsFinishHandPaper"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6">
                                    </el-switch>
                                </div>
                            </div>
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    单选和判断自动跳题
                                </div>
                                <el-switch
                                    v-model="inTheSettingData.AutomaticJump"
                                    active-color="#13ce66"
                                    inactive-color="#dcdfe6">
                                </el-switch>
                            </div>
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                   摄像头监控
                                </div>
                                <div>
                                    <el-switch
                                        v-model="inTheSettingData.IsMonitor"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6">
                                    </el-switch>
                                </div>
                            </div>
                            <div class="chilSetting">
                                <div style="display:flex;justify-content:space-between;">
                                    <div>
                                        监控拍照次数
                                    </div>
                                    <div>
                                        <el-input :disabled="!inTheSettingData.IsMonitor" v-model="inTheSettingData.TakePictureCount" placeholder="" size="small" style="width:120px;">
                                            <template slot="append">次</template>
                                        </el-input>
                                    </div>
                                </div>
                                <div style="display:flex;justify-content:space-between;">
                                    <div>
                                        检测人脸
                                    </div>
                                </div>
                                <div style="display:flex;justify-content:space-between;">
                                    <div>
                                        检测不到人脸超过
                                        <el-input :disabled="!inTheSettingData.IsMonitor" v-model="inTheSettingData.NotCheckFace" placeholder="" size="small" style="width:60px;"></el-input>
                                        秒算一次作弊，
                                    </div>
                                </div>
                                <div style="display:flex;justify-content:space-between;">
                                    <div>
                                        大于等于
                                        <el-input :disabled="!inTheSettingData.IsMonitor" v-model="inTheSettingData.NotCheckFaceCount" placeholder="" size="small" style="width:60px;"></el-input>
                                        次系统自动交卷。(0代表不启用)
                                    </div>
                                </div>
                                <div style="display:flex;justify-content:space-between;">
                                    <div>
                                        检测到多个人脸超过
                                        <el-input :disabled="!inTheSettingData.IsMonitor" v-model="inTheSettingData.CheckMultipleFace" placeholder="" size="small" style="width:60px;"></el-input>
                                        秒算一次作弊，
                                    </div>
                                </div>
                                <div style="display:flex;justify-content:space-between;">
                                    <div>
                                        大于等于
                                        <el-input :disabled="!inTheSettingData.IsMonitor" v-model="inTheSettingData.CheckMultipleFaceCount" placeholder="" size="small" style="width:60px;"></el-input>
                                        次，系统自动交卷。(0代表不启用)
                                    </div>
                                </div>
                            </div>
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    麦克风监控灵敏值
                                </div>
                                <div class="examSettingFrom" >
                                    <div style="margin-right:5px;">
                                        <el-switch
                                            v-model="inTheSettingData.IsVoiceMonitoring"
                                            active-color="#13ce66"
                                            inactive-color="#dcdfe6">
                                        </el-switch>
                                    </div>
                                    <div>
                                        <el-input :disabled="!inTheSettingData.IsVoiceMonitoring" v-model="inTheSettingData.ExceedDecibelLine" placeholder="" size="small" style="width:120px;">
                                            <template slot="append">分贝</template>
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                            <div class="chilSetting">
                                大于等于<el-input :disabled="!inTheSettingData.IsVoiceMonitoring" v-model="inTheSettingData.ExceedDecibelLineCount" placeholder="" size="small" style="width:60px;margin:0 15px;"></el-input>次，系统自动交卷。
                            </div>
                        </el-collapse-item> -->
                        <!-- <el-collapse-item class="settingSome" title="考试后" name="3">
                            <div class="examSettingFrom">
                                <div class="settingFromHeader" style="font-weight:blod!important">
                                    成绩查看
                                </div>
                                <div>
                                    <div>
                                        <el-radio v-model="afterSettingData.ResultsViewRange" label="0">不可查成绩</el-radio>
                                    </div>
                                    <div>
                                        <el-radio v-model="afterSettingData.ResultsViewRange" label="1">可查成绩</el-radio>
                                    </div>
                                    <div>
                                        <el-radio v-model="afterSettingData.ResultsViewRange" label="2">可查成绩和对错</el-radio>
                                    </div>
                                    <div>
                                        <el-radio v-model="afterSettingData.ResultsViewRange" label="3">可查成绩、对错答案和解析</el-radio>
                                    </div>
                                    <div>
                                        <el-radio v-model="afterSettingData.ResultsViewRange" label="0">不可查对错答案和解析</el-radio>
                                    </div>
                                    <div>
                                        <el-radio v-model="afterSettingData.ResultsViewRange" label="1">可查对错答案和解析</el-radio>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item> -->
                    </el-collapse>
                </div>
            </el-col>
        </el-row>
        <!-- 试卷名称等头部编辑 -->
        <el-dialog
        title="编辑"
        :visible.sync="editDialogView"
        width="50%"
        :close-on-click-modal="false">
            <el-form :model="editDataStr" label-width="120px" label-position="center" ref="editFormRef" :rules="editFormRules">
                <el-form-item label="试卷名称：" prop="titleName">
                    <el-input v-model="editDataStr.titleName"></el-input>
                </el-form-item>
                <el-form-item label="描述：">
                    <el-input v-model="editDataStr.titleDescribe"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogView = false">取 消</el-button>
                <el-button type="primary" @click="editSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 批量设置分数 -->
        <el-dialog
        title="批量设置分数"
        :visible.sync="dialogVisible"
        width="40%"
        :close-on-click-modal="false">
            <el-form :model="settingMarkForm" label-width="120px" label-position="center">
                <el-form-item label="单选题：">
                    <div>共{{settingMarkForm.radioTypeNum}}题，每题<el-input v-model="settingMarkForm.radioTypeMark" size="mini" style="width:50px;margin:0 5px;"></el-input>分 总分{{settingMarkForm.radioTypescore}}分</div>
                </el-form-item>
                <el-form-item label="多选题：">
                    <div>共{{settingMarkForm.cheboxTypeNum}}题，每题<el-input v-model="settingMarkForm.cheboxTypeMark" size="mini" style="width:50px;margin:0 5px;"></el-input>分 总分{{settingMarkForm.cheboxTypescore}}分</div>
                </el-form-item>
                <el-form-item label="判断题：">
                    <div>共{{settingMarkForm.judgeTypeNum}}题，每题<el-input v-model="settingMarkForm.judgeTypeMark" size="mini" style="width:50px;margin:0 5px;"></el-input>分 总分{{settingMarkForm.judgeTypescore}}分</div>
                </el-form-item>
                <el-form-item label="统计：" style="font-weight: bold;font-size:18px;">
                    <div>总分{{settingMarkForm.aggregateScore}}分</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelSetting">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 手动选择试题 -->
        <el-dialog
        title="选择试题"
        :visible.sync="dialogSelect"
        width="80%"
        :close-on-click-modal="false">
            <el-row>
                <el-col :span="6">
                    <div class="grid-content bg-purple">
                        <el-table
                        highlight-current-row
                        :data="manualLeft"
                        v-loading="listLoading"
                        @current-change="selectCurrentRow"
                        border
                        lazy
                        :load="load"
                        row-key="ID"
                        :tree-props="{children: 'Children', hasChildren: 'HasChildren'}"
                        ref="multipleTable"
                        style="width: 100%">
                            <!-- <el-table-column type="selection" align="center" prop="date" label="" width="60">
                                <template scope="scope">
                                    <el-checkbox v-model="textbookID" :label="scope.row.ID">{{scope.row.ids}}</el-checkbox>
                                </template>
                            </el-table-column> -->
                            <!-- <el-table-column align="center" prop="date" label="" width="50">
                                <template>
                                    <el-checkbox></el-checkbox>
                                </template>
                            </el-table-column> -->
                            <el-table-column prop="Name" label="教材名称" width=""></el-table-column>
                        </el-table>
                    </div>
                </el-col>
                <el-col :span="15">
                    <div class="grid-content bg-purple-light">
                        <el-form :inline="true">
                            <el-form-item label="题型:">
                                <el-select v-model="filters.questionsType" placeholder="请选择">
                                    <el-option
                                    v-for="item in questionsTypeArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="难度:">
                                <el-select v-model="filters.difficulty" placeholder="请选择">
                                    <el-option
                                    v-for="item in difficultyArr"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="关键字:">
                                <el-input placeholder="请输入试题编号/题目" v-model="filters.questionsTitle"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="searchQuestion()">查询</el-button>
                            </el-form-item>
                        </el-form>
                        <el-table
                        :data="manualCenter"
                        border
                        v-loading="listLoading1"
                        style="width: 100%">
                            <el-table-column align="center" prop="date" label="" width="60">
                                <template scope="scope">
                                    <el-checkbox v-model="relatedQuestionID" @change="getRow(scope.row)" :label="scope.row.ID">{{scope.row.ids}}</el-checkbox>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="QuestionsTitle" label="题目"></el-table-column>
                            <el-table-column align="center" prop="QuestionsType" label="题型" width="130">
                                <template scope="scope">
                                    <el-tag
                                    :type="''"
                                    disable-transitions v-if="scope.row.QuestionsType== 0">{{"单选题"}}
                                    </el-tag>
                                    <el-tag
                                    :type="'success'"
                                    disable-transitions v-if="scope.row.QuestionsType== 1">{{"多选题"}}
                                    </el-tag>
                                    <el-tag
                                    :type="'warning'"
                                    disable-transitions v-if="scope.row.QuestionsType== 2">{{"判断题"}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" prop="Difficulty" label="难易度" width="100">
                                <template scope="scope">
                                    <el-tag
                                    :type="'warning'"
                                    disable-transitions v-if="scope.row.Difficulty== 0">{{"高"}}
                                    </el-tag>
                                    <el-tag
                                    :type="'success'"
                                    disable-transitions v-if="scope.row.Difficulty== 1">{{"中"}}
                                    </el-tag>
                                    <el-tag
                                    :type="''"
                                    disable-transitions v-if="scope.row.Difficulty== 2">{{"低"}}
                                    </el-tag>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--底部工具条-->
                        <el-col :span="15" class="pageBar">
                            <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pages.pageIndex"
                            :page-sizes="pages.pageArr"
                            :page-size="pages.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pages.dataCount">
                            </el-pagination>
                        </el-col>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="grid-content bg-purple">
                        <el-form label-width="80px" label-position="center">
                            <el-form-item label="单选题：">
                                <div>共{{statistics.radioNumber}}题</div>
                            </el-form-item>
                            <el-form-item label="多选题：">
                                <div>共{{statistics.cheboxNumber}}题</div>
                            </el-form-item>
                            <el-form-item label="判断题：">
                                <div>共{{statistics.judgeNumber}}题</div>
                            </el-form-item>
                            <el-form-item label="统计：" style="font-weight: bold;font-size:18px;">
                                <div>总共{{statistics.numberSun}}题</div>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogSelect = false">取 消</el-button>
                <el-button type="primary" @click="selectQuesSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getTeachingMaterialTree,getExamPaperTeachingMaterialTree,getExaminationQuestionsByTMIDListPageAsync,addExaminationPaper,updateExaminationPaper,getExaminationPaperByID } from '../../api/api';
import Sortable from 'sortablejs'
export default {
    data() {
        return {
            tableData:[],
            titleDescribe: '',
            titleName: '',
            dataType: '',
            dictionaryType: '',
            tradeType: '',
            timeNow: '',
            paperMark: 0,
            paperType: '',
            isFixedType: 0,
            allIsViwes: false,
            addLoading: false,
            allCheckbox: [],
            editDialogView: false,
            editDataStr: {
                titleName: '',
                titleDescribe: '',
            },
            editFormRules: {
                titleName:[{required:true,message:'请输入试卷名称',trigger:'blur'}],
            },
            // 批量设置分数
            dialogVisible: false,
            settingMarkForm: {
                radioTypeMark: 1,
                cheboxTypeMark: 1,
                judgeTypeMark: 1,

                radioTypeNum: 0,
                cheboxTypeNum: 0,
                judgeTypeNum: 0,

                radioTypescore: 0,
                cheboxTypescore: 0,
                judgeTypescore: 0,

                aggregateScore: 1,
            },
            // 手动选题
            dialogSelect: false,
            listLoading: false,
            listLoading1: false,
            listLoading3: false,
            teachingMaterialID: '',
            textbookID: [],
            relatedQuestionID: [],
            recordAllData: [],
            manualLeft: [],
            manualLeft11: [],
            manualCenter: [],
            manualRight: [],
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            filters: {
                questionsTitle: '',
                questionsType: 5,
                difficulty: 5,
            },
            statistics: {
                radioNumber: 0,
                cheboxNumber: 0,
                judgeNumber: 0,
                numberSun: 0
            },
            questionsTypeArr: [
                {value:5,label:'所有试题'},
                {value:0,label:'单选题'},
                {value:1,label:'多选题'},
                {value:2,label:'判断题'},
            ],
            difficultyArr: [
                {value:5,label:'所有试题'},
                {value:0,label:'高'},
                {value:1,label:'中'},
                {value:2,label:'低'},
            ],
            // 随机试卷统计相关数据
            freePaperData: {
                radioTypeQues: 0,
                cheboxTypeQues: 0,
                judgeTypeQues: 0,
                questionSumNum: '',
                radioTypeQuesTage: '',
                cheboxTypeQuesTage: '',
                judgeTypeQuesTage: '',
            },
            // 考试设置相关参数
            activeNames: ['1'],
            // 基本设置
            baseSettingData: {
                examTimeLeng: 60,//考试时长
                qualifiedScoe: 60,//合格分数
                timerValue: true,//考试时间
                examSetTime: '',
                examResult: true,//查看考试结果
                beLateExam: true,//迟到几分钟不能考试
                beLateTimer: 10,
                canSubmitSwitch: true,//迟到后几分钟能交卷
                canSubmitPaper: 30,
                examAddreddSwitch: true,//考试地点Switch
                examAddressVal: '',
                repeatedlyExam: true,//多次考试
                repeatedlyExamVal: '',
                expenseSwitch: true,//收费考试
                expenseVal: '',
                viewsResultSwitch: true,//显示分值
                viewsQuestTypeSwitch: true,//显示题型
                viewsFaceSwitch: true,//显示人脸识别
            },
            // 考试中
            inTheSettingData: {
                IsFinishHandPaper: true,
                AutomaticJump: true,
                IsMonitor: true,
                TakePictureCount: 10,
                NotCheckFace: 5,
                NotCheckFaceCount: 3,
                CheckMultipleFace:5,
                CheckMultipleFaceCount:3,
                IsVoiceMonitoring: true,
                ExceedDecibelLine: 60,
                ExceedDecibelLineCount: 3,
            },
            afterSettingData: {
                ResultsViewRange: "1",
            },
            // 临时数据
            isCanBack: 333,
            editFixedData: '',
            editFixedData1: '',
            editFixedDataID: '',
            idAddOrEdit:true,
            freeIsSubmit: 0,
            paperMarkNoChange:333,
            viewsScore: {
                radio: 1,
                check: 1,
                judge: 1,
            },
            // params相关数据
            teachingArrData: [],
        }
    },
    methods: {
        // 更新固定试卷总分
        updatapaperMarkFun() {
            var aggregateScore = 0
            this.tableData.forEach(item => {
                aggregateScore += Number(item.Score)
            })
            this.paperMark = aggregateScore
            this.baseSettingData.qualifiedScoe = parseInt(this.paperMark * 0.6)
        },
        // 返回
        goBack() {
            if(this.isCanBack == 0){
                this.$confirm('还未保存确认返回？',"提示",{
                }).then(() => {
                    this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
                }).catch(() => {
                    
                })
            }else{
                this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
            }
        },
        // 获取菜单树
        getNavTree(params) {
            this.listLoading = true
            getTeachingMaterialTree(params).then(res => {
                var data = res.data
                if(data.Success){
                    this.listLoading = false
                    var result = data.Response
                    if(this.isFixedType === '1'){
                        var item = this.manualLeft
                        var o = 1
                        this.addBindData(result,item,o);
                    }else{
                        this.manualLeft = result
                    }
                }
            })
        },
        // 获取菜单树(二级)
        getNavSecTree(){
            this.listLoading3 = true
            var params = {
                ddId: this.dictionaryType
            }
            getExamPaperTeachingMaterialTree(params).then(res => {
                var data = res.data
                if(data.Success){
                    var result = data.Response
                    // this.manualLeft11 = result
                    if(this.isFixedType === '1'){
                        var item = this.manualLeft11
                        var o = 2
                        this.addBindData(result,item,o);
                        
                        this.manualLeft11 = result
                        result.forEach(item => {
                            if(item.Children && item.Children.length > 0){
                                var childrenData = item.Children
                                this.addBindData(childrenData,item,o);
                            }
                        })
                        if(this.isFixedType === '1' && this.manualLeft11.length > 0){
                            // var a = this.editFixedData1
                            // var b = this.manualLeft11
                            // if(this.idAddOrEdit){}else{
                            //     this.editBackViews(a,b)
                            //     this.statisticsQuestionNum(a)
                            // }
                        }
                        // console.log("result",result)
                        this.viewsQuesNum(result)
                    }
                    // this.manualLeft11 = result
                    // else{
                    //     this.manualLeft = result
                    // }
                    this.listLoading3 = false
                }else{
                    var _this = this
                    setTimeout(() => {
                        _this.listLoading3 = false
                    },3000)
                }
            })
        },
        load1(tree, treeNode, resolve) {
            let params = {
                parentId: tree.ID,
                ddId: this.dictionaryType
            };
            this.listLoading3 = true
            getExamPaperTeachingMaterialTree(params).then(res => {
                if(res.data.Success){
                    var result = res.data.Response
                    if(this.isFixedType === '1'){
                        var a = result   //点击子集不加载已有的单选或者多选判断题数目，所以注释
                        var dd = this.manualLeft11
                        var b = tree.ID
                        var c = result
                        // var d = result
                        // this.findChild(a,b,c);
                        
                        var o = 2
                        var item = this.manualLeft
                        this.addBindData(result,item,o);
                        this.manualLeft11.forEach(o => {
                            if(o.ID == tree.ID){
                                o.Children = result
                            }
                        })
                        var e = this.editFixedData1
                        if(this.idAddOrEdit){}else{
                            this.editBackViews(e,a) //编辑时打开
                        }
                        this.viewsQuesNum(result)
                    }
                    resolve(result);
                    this.listLoading3 = false
                }
            });
        },
        // table懒加载
        load(tree, treeNode, resolve) {
            let params = {
                parentId: tree.ID,
            };
            getTeachingMaterialTree(params).then(res => {
                var result = res.data.Response
                // if(this.isFixedType === '1'){
                //     var a = this.manualLeft11
                //     var b = tree.ID
                //     var c = result
                //     this.findChild(a,b,c);
                //     var e = this.editFixedData
                    // this.editBackViews(e,a) //编辑时打开
                // }
                resolve(result);
            });
        },
        // 寻找子集
        findChild(a,b,c,d){
            a.forEach(item => {
                if(item.ID == b){
                    if(c){
                        this.addBindData(c,item);
                    }
                    if(d){
                        var e = item
                        var a = this.dataViews(d,e);
                        item = a
                    }
                }else{
                    if(item.HasChildren && item.Children && item.Children.length > 0){
                        item.Children.forEach(item1 => {
                            if(item1.ID == b){
                                if(c){
                                    this.addBindData(c,item1);
                                }
                                if(d){
                                    var e = item1
                                    this.dataViews(d,e);
                                }
                            }else{
                                if(item1.HasChildren && item1.Children && item1.Children.length > 0){
                                    item1.Children.forEach(item2 => {
                                        if(item2.ID == b){
                                            if(c){
                                                this.addBindData(c,item2);
                                            }
                                            if(d){
                                                var e = item2
                                                this.dataViews(d,e);
                                            }
                                        }else{
                                            if(item2.HasChildren && item2.Children && item2.Children.length > 0){
                                                item2.Children.forEach(item3 => {
                                                    if(item3.ID == b){
                                                        if(c){
                                                            this.addBindData(c,item3);
                                                        }
                                                        if(d){
                                                            var e = item3
                                                            this.dataViews(d,e);
                                                        }
                                                    }else{
                                                        if(item3.HasChildren && item3.Children && item3.Children.length > 0){
                                                            item3.Children.forEach(item4 => {
                                                                if(item4.ID == b){
                                                                    if(c){
                                                                        this.addBindData(c,item4);
                                                                    }
                                                                    if(d){
                                                                        var e = item4
                                                                        this.dataViews(d,e);
                                                                    }
                                                                }else{
                                                                    if(item4.HasChildren && item4.Children && item4.Children.length > 0){
                                                                        item4.Children.forEach(item6 => {
                                                                            if(item6.ID == b){
                                                                                if(c){
                                                                                    this.addBindData(c,item6);
                                                                                }
                                                                                if(d){
                                                                                    var e = item6
                                                                                    this.dataViews(d,e);
                                                                                }
                                                                            }else{
                                                                                if(item6.HasChildren && item6.Children && item6.Children.length > 0){
                                                                                    item6.Children.forEach(item7 => {
                                                                                        if(item7.ID == b){
                                                                                            if(c){
                                                                                                this.addBindData(c,item7);
                                                                                            }
                                                                                            if(d){
                                                                                                var e = item7
                                                                                                this.dataViews(d,e);
                                                                                            }
                                                                                        }else{
                                                                                            if(item7.HasChildren && item7.Children && item7.Children.length > 0){
                                                                                                item7.Children.forEach(item8 => {
                                                                                                    
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
            return a
        },
        // 添加属性
        addBindData(result,item,o) {
            var freeData1 = {
                isDifficulty:false,
                allSelect: '',
                nanduDI:'',
                nanduZhong:'',
                nanduGao:'',

                danxuan:0,
                duoxuan:0,
                panduan:0,
                gao:0,
                zhong:0,
                di:0,
            }
            var freeData2 = {
                isDifficulty:false,
                allSelect: '',
                nanduDI:'',
                nanduZhong:'',
                nanduGao:'',

                danxuan:0,
                duoxuan:0,
                panduan:0,
                gao:0,
                zhong:0,
                di:0,
            }
            var freeData3 = {
                isDifficulty:false,
                allSelect: '',
                nanduDI:'',
                nanduZhong:'',
                nanduGao:'',

                danxuan:0,
                duoxuan:0,
                panduan:0,
                gao:0,
                zhong:0,
                di:0,
            }
            result.forEach(item => {
                item.isDifficulty1 = freeData1.isDifficulty
                item.allSelect1 = freeData1.allSelect
                item.nanduDI1 = freeData1.nanduDI
                item.nanduZhong1 = freeData1.nanduZhong
                item.nanduGao1 = freeData1.nanduGao

                item.danxuan1  = freeData1.danxuan
                item.duoxuan1  = freeData1.duoxuan
                item.panduan1  = freeData1.panduan
                item.gao1  = freeData1.gao
                item.zhong1  = freeData1.zhong
                item.di1  = freeData1.di

                item.isDifficulty2 = freeData2.isDifficulty
                item.allSelect2 = freeData2.allSelect
                item.nanduDI2 = freeData2.nanduDI
                item.nanduZhong2 = freeData2.nanduZhong
                item.nanduGao2 = freeData2.nanduGao

                item.danxuan2  = freeData2.danxuan
                item.duoxuan2  = freeData2.duoxuan
                item.panduan2  = freeData2.panduan
                item.gao2  = freeData2.gao
                item.zhong2  = freeData2.zhong
                item.di2  = freeData2.di

                item.isDifficulty3 = freeData3.isDifficulty
                item.allSelect3 = freeData3.allSelect
                item.nanduDI3 = freeData3.nanduDI
                item.nanduZhong3 = freeData3.nanduZhong
                item.nanduGao3 = freeData3.nanduGao

                item.danxuan3  = freeData3.danxuan
                item.duoxuan3  = freeData3.duoxuan
                item.panduan3  = freeData3.panduan
                item.gao3  = freeData3.gao
                item.zhong3  = freeData3.zhong
                item.di3  = freeData3.di


            })
            if(o === 1){
                this.manualLeft11 = result
            }else{
                item.Children = result
            }
        },
        // 数据展示
        dataViews(d,e) {
            var danxuan1 = 0
            var duoxuan1 = 0
            var panduan1 = 0

            var danxuan2 = 0
            var duoxuan2 = 0
            var panduan2 = 0

            var danxuan3 = 0
            var duoxuan3 = 0
            var panduan3 = 0
            
            var gao1 = 0
            var zhong1 = 0
            var di1 = 0

            var gao2 = 0
            var zhong2 = 0
            var di2 = 0

            var gao3 = 0
            var zhong3 = 0
            var di3 = 0
            d.forEach(o => {
                if(o.QuestionsType == 0){
                    danxuan1++
                    if(o.Difficulty == 0){
                        gao1++
                    }
                    if(o.Difficulty == 1){
                        zhong1++
                    }
                    if(o.Difficulty == 2){
                        di1++
                    }
                }
                if(o.QuestionsType == 1){
                    duoxuan2++
                    if(o.Difficulty == 0){
                        gao2++
                    }
                    if(o.Difficulty == 1){
                        zhong2++
                    }
                    if(o.Difficulty == 2){
                        di2++
                    }
                }
                if(o.QuestionsType == 2){
                    panduan3++
                    if(o.Difficulty == 0){
                        gao3++
                    }
                    if(o.Difficulty == 1){
                        zhong3++
                    }
                    if(o.Difficulty == 2){
                        di3++
                    }
                }
            })
            e.danxuan1 = danxuan1
            e.duoxuan1 = duoxuan1
            e.panduan1 = panduan1
            
            e.danxuan2 = danxuan2
            e.duoxuan2 = duoxuan2
            e.panduan2 = panduan2

            e.danxuan3 = danxuan3
            e.duoxuan3 = duoxuan3
            e.panduan3 = panduan3

            e.gao1 = gao1
            e.zhong1 = zhong1
            e.di1 = di1

            e.gao2 = gao2
            e.zhong2 = zhong2
            e.di2 = di2

            e.gao3 = gao3
            e.zhong3 = zhong3
            e.di3 = di3
        },
        // 随机试卷题库题数展示
        viewsQuesNum(d){
            d.forEach(o => {
                o.EqgroupByCount.forEach(n => {
                    if(n.QuestionsType == 0){
                        o.danxuan1 += n.Gbcount
                        if(n.Difficulty == 0){
                            o.gao1 += n.Gbcount
                        }
                        if(n.Difficulty == 1){
                            o.zhong1 += n.Gbcount
                        }
                        if(n.Difficulty == 2){
                            o.di1 += n.Gbcount
                        }
                    }
                    if(n.QuestionsType == 1){
                        o.duoxuan2 += n.Gbcount
                        if(n.Difficulty == 0){
                            o.gao2 += n.Gbcount
                        }
                        if(n.Difficulty == 1){
                            o.zhong2 += n.Gbcount
                        }
                        if(n.Difficulty == 2){
                            o.di2 += n.Gbcount
                        }
                    }
                    if(n.QuestionsType == 2){
                        o.panduan3 += n.Gbcount
                        if(n.Difficulty == 0){
                            o.gao3 += n.Gbcount
                        }
                        if(n.Difficulty == 1){
                            o.zhong3 += n.Gbcount
                        }
                        if(n.Difficulty == 2){
                            o.di3 += n.Gbcount
                        }
                    }
                })
            })
        },
        // 点击table一行
        selectCurrentRow(val) {
            this.teachingMaterialID = val.ID
            // this.textbookID.push(val.ID)
            // const r = [...new Set(this.textbookID)];
            // this.textbookID = r
            var params = {
                teachingMaterialId: val.ID
            }
            if(this.isFixedType === '0'){
                this.getListPageById(params)
            }
        },
        // 遍历寻找所填随机试题参数
        traverseData(a){
            var allTraverArr = []
            a.forEach(item => {
                if(item.allSelect1 || item.allSelect2 || item.allSelect3 ||
                item.nanduDI1 || item.nanduZhong1 || item.nanduGao1 ||
                item.nanduDI2 || item.nanduZhong2 || item.nanduGao2 ||
                item.nanduDI3 || item.nanduZhong3 || item.nanduGao3){
                    if(item.Children && item.Children.length > 0){
                        var aa1 = 1
                        item.Children.forEach(item1 => {
                            if(item1.allSelect1 || item1.allSelect2 || item1.allSelect3 ||
                            item1.nanduDI1 || item1.nanduZhong1 || item1.nanduGao1 ||
                            item1.nanduDI2 || item1.nanduZhong2 || item1.nanduGao2 ||
                            item1.nanduDI3 || item1.nanduZhong3 || item1.nanduGao3){
                                this.$message({
                                    message: '上下级不能同时设置选题！',
                                    type: 'warning'
                                });
                                return
                            }else{
                                if(item1.Children && item1.Children.length > 0){
                                    var aa2 = 1
                                    item1.Children.forEach(item2 => {
                                        if(item2.allSelect1 || item2.allSelect2 || item2.allSelect3 ||
                                        item2.nanduDI1 || item2.nanduZhong1 || item2.nanduGao1 ||
                                        item2.nanduDI2 || item2.nanduZhong2 || item2.nanduGao2 ||
                                        item2.nanduDI3 || item2.nanduZhong3 || item2.nanduGao3){
                                            this.$message({
                                                message: '上下级不能同时设置选题！',
                                                type: 'warning'
                                            });
                                            return
                                        }else{
                                            if(item2.Children && item2.Children.length > 0){
                                                var aa3 = 1
                                                item2.Children.forEach(item3 => {
                                                    if(item3.allSelect1 || item3.allSelect2 || item3.allSelect3 ||
                                                    item3.nanduDI1 || item3.nanduZhong1 || item3.nanduGao1 ||
                                                    item3.nanduDI2 || item3.nanduZhong2 || item3.nanduGao2 ||
                                                    item3.nanduDI3 || item3.nanduZhong3 || item3.nanduGao3){
                                                        this.$message({
                                                            message: '上下级不能同时设置选题！',
                                                            type: 'warning'
                                                        });
                                                        return
                                                    }else{
                                                        if(item3.Children && item3.Children.length > 0){
                                                            var aa4 = 1
                                                            item3.Children.forEach(item4 => {
                                                                if(item4.allSelect1 || item4.allSelect2 || item4.allSelect3 ||
                                                                item4.nanduDI1 || item4.nanduZhong1 || item4.nanduGao1 ||
                                                                item4.nanduDI2 || item4.nanduZhong2 || item4.nanduGao2 ||
                                                                item4.nanduDI3 || item4.nanduZhong3 || item4.nanduGao3){
                                                                    this.$message({
                                                                        message: '上下级不能同时设置选题！',
                                                                        type: 'warning'
                                                                    });
                                                                    return
                                                                }else{
                                                                    if(item4.Children && item4.Children.length > 0){
                                                                        var aa5 = 1
                                                                        item4.Children.forEach(item5 => {
                                                                            if(item5.allSelect1 || item5.allSelect2 || item5.allSelect3 ||
                                                                            item5.nanduDI1 || item5.nanduZhong1 || item5.nanduGao1 ||
                                                                            item5.nanduDI2 || item5.nanduZhong2 || item5.nanduGao2 ||
                                                                            item5.nanduDI3 || item5.nanduZhong3 || item5.nanduGao3){
                                                                                this.$message({
                                                                                    message: '上下级不能同时设置选题！',
                                                                                    type: 'warning'
                                                                                });
                                                                                return
                                                                            }else{
                                                                                if(item5.Children && item5.Children.length > 0){
                                                                                    var aa6 = 1
                                                                                    item5.Children.forEach(item6 => {
                                                                                        if(item6.allSelect1 || item6.allSelect2 || item6.allSelect3 ||
                                                                                        item6.nanduDI1 || item6.nanduZhong1 || item6.nanduGao1 ||
                                                                                        item6.nanduDI2 || item6.nanduZhong2 || item6.nanduGao2 ||
                                                                                        item6.nanduDI3 || item6.nanduZhong3 || item6.nanduGao3){
                                                                                            this.$message({
                                                                                                message: '上下级不能同时设置选题！',
                                                                                                type: 'warning'
                                                                                            });
                                                                                            return
                                                                                        }else{
                                                                                            if(item6.Children && item6.Children.length > 0){
                                                                                                item6.Children.forEach(item7 => {
                                                                                                    if(item7.allSelect1 || item7.allSelect2 || item7.allSelect3 ||
                                                                                                    item7.nanduDI1 || item7.nanduZhong1 || item7.nanduGao1 ||
                                                                                                    item7.nanduDI2 || item7.nanduZhong2 || item7.nanduGao2 ||
                                                                                                    item7.nanduDI3 || item7.nanduZhong3 || item7.nanduGao3){
                                                                                                        this.$message({
                                                                                                            message: '上下级不能同时设置选题！',
                                                                                                            type: 'warning'
                                                                                                        });
                                                                                                        return
                                                                                                    }else{
                                                                                                        
                                                                                                    }
                                                                                                })
                                                                                            }else{
                                                                                                var a = this.secondTraver(item5)
                                                                                                if(a && aa6 === 1){
                                                                                                    aa6++
                                                                                                    a.forEach(o => {
                                                                                                        allTraverArr.push(o)
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }else{
                                                                                    var a = this.secondTraver(item4)
                                                                                    if(a && aa5 === 1){
                                                                                        aa5++
                                                                                        a.forEach(o => {
                                                                                            allTraverArr.push(o)
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }
                                                                        })
                                                                    }else{
                                                                        var a = this.secondTraver(item3)
                                                                        if(a && aa4 === 1){
                                                                            aa4++
                                                                            a.forEach(o => {
                                                                                allTraverArr.push(o)
                                                                            })
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }else{
                                                            var a = this.secondTraver(item2)
                                                            if(a && aa3 === 1){
                                                                aa3++
                                                                a.forEach(o => {
                                                                    allTraverArr.push(o)
                                                                })
                                                            }
                                                        }
                                                    }
                                                })
                                            }else{
                                                var a = this.secondTraver(item1)
                                                if(a && aa2 === 1){
                                                    aa2++
                                                    a.forEach(o => {
                                                        allTraverArr.push(o)
                                                    })
                                                }
                                            }
                                        }
                                    })
                                }else{
                                    var a = this.secondTraver(item)
                                    if(a && aa1 === 1){
                                        aa1++
                                        a.forEach(o => {
                                            allTraverArr.push(o)
                                        })
                                    }
                                }
                            }
                        })
                    }
                    else{
                        var a = this.secondTraver(item)
                        if(a){
                            a.forEach(o => {
                                allTraverArr.push(o)
                            })
                        }
                    }
                }else{
                    if(item.Children && item.Children.length > 0){
                        item.Children.forEach(item1 => {
                            if(item1.allSelect1 || item1.allSelect2 || item1.allSelect3 ||
                            item1.nanduDI1 || item1.nanduZhong1 || item1.nanduGao1 ||
                            item1.nanduDI2 || item1.nanduZhong2 || item1.nanduGao2 ||
                            item1.nanduDI3 || item1.nanduZhong3 || item1.nanduGao3){
                                var a = this.secondTraver(item1)
                                if(a){
                                    a.forEach(o => {
                                        allTraverArr.push(o)
                                    })
                                }
                            }else{
                                if(item1.Children && item1.Children.length > 0){
                                    item1.Children.forEach(item2 => {
                                        if(item2.allSelect1 || item2.allSelect2 || item2.allSelect3 ||
                                        item2.nanduDI1 || item2.nanduZhong1 || item2.nanduGao1 ||
                                        item2.nanduDI2 || item2.nanduZhong2 || item2.nanduGao2 ||
                                        item2.nanduDI3 || item2.nanduZhong3 || item2.nanduGao3){
                                            var a = this.secondTraver(item2)
                                            if(a){
                                                a.forEach(o => {
                                                    allTraverArr.push(o)
                                                })
                                            }
                                        }else{
                                            if(item2.Children && item2.Children.length > 0){
                                                item2.Children.forEach(item3 => {
                                                    if(item3.allSelect1 || item3.allSelect2 || item3.allSelect3 ||
                                                    item3.nanduDI1 || item3.nanduZhong1 || item3.nanduGao1 ||
                                                    item3.nanduDI2 || item3.nanduZhong2 || item3.nanduGao2 ||
                                                    item3.nanduDI3 || item3.nanduZhong3 || item3.nanduGao3){
                                                        var a = this.secondTraver(item3)
                                                        if(a){
                                                            a.forEach(o => {
                                                                allTraverArr.push(o)
                                                            })
                                                        }
                                                    }else{
                                                        if(item3.Children && item3.Children.length > 0){
                                                            item3.Children.forEach(item4 => {
                                                                if(item4.allSelect1 || item4.allSelect2 || item4.allSelect3 ||
                                                                item4.nanduDI1 || item4.nanduZhong1 || item4.nanduGao1 ||
                                                                item4.nanduDI2 || item4.nanduZhong2 || item4.nanduGao2 ||
                                                                item4.nanduDI3 || item4.nanduZhong3 || item4.nanduGao3){
                                                                    var a = this.secondTraver(item4)
                                                                    if(a){
                                                                        a.forEach(o => {
                                                                            allTraverArr.push(o)
                                                                        })
                                                                    }
                                                                }else{
                                                                    if(item4.Children && item4.Children.length > 0){
                                                                        item4.Children.forEach(item5 => {
                                                                            if(item5.allSelect1 || item5.allSelect2 || item5.allSelect3 ||
                                                                            item5.nanduDI1 || item5.nanduZhong1 || item5.nanduGao1 ||
                                                                            item5.nanduDI2 || item5.nanduZhong2 || item5.nanduGao2 ||
                                                                            item5.nanduDI3 || item5.nanduZhong3 || item5.nanduGao3){
                                                                                var a = this.secondTraver(item5)
                                                                                if(a){
                                                                                    a.forEach(o => {
                                                                                        allTraverArr.push(o)
                                                                                    })
                                                                                }
                                                                            }else{
                                                                                if(item5.Children && item5.Children.length > 0){
                                                                                    item5.Children.forEach(item6 => {
                                                                                        if(item6.allSelect1 || item6.allSelect2 || item6.allSelect3 ||
                                                                                        item6.nanduDI1 || item6.nanduZhong1 || item6.nanduGao1 ||
                                                                                        item6.nanduDI2 || item6.nanduZhong2 || item6.nanduGao2 ||
                                                                                        item6.nanduDI3 || item6.nanduZhong3 || item6.nanduGao3){
                                                                                            var a = this.secondTraver(item6)
                                                                                            if(a){
                                                                                                a.forEach(o => {
                                                                                                    allTraverArr.push(o)
                                                                                                })
                                                                                            }
                                                                                        }else{
                                                                                            if(item6.Children && item6.Children.length > 0){
                                                                                                item6.Children.forEach(item7 => {
                                                                                                    if(item7.allSelect1 || item7.allSelect2 || item7.allSelect3 ||
                                                                                                    item7.nanduDI1 || item7.nanduZhong1 || item7.nanduGao1 ||
                                                                                                    item7.nanduDI2 || item7.nanduZhong2 || item7.nanduGao2 ||
                                                                                                    item7.nanduDI3 || item7.nanduZhong3 || item7.nanduGao3){
                                                                                                        var a = this.secondTraver(item7)
                                                                                                        if(a){
                                                                                                            a.forEach(o => {
                                                                                                                allTraverArr.push(o)
                                                                                                            })
                                                                                                        }
                                                                                                    }else{
                                                                                                        
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
            return allTraverArr
            
        },
        // 二次封装
        secondTraver(item) {
            var paramsArrData = []
            if(item.isDifficulty1){
                if(item.nanduGao1 > item.gao1 || item.nanduZhong1 > item.zhong1 || item.nanduDI1 > item.di1){
                    this.$message({
                        message: '已超出题库范围！',
                        type: 'warning'
                    });
                    this.freeIsSubmit++
                    return
                }
                var paramsObjData = {}
                paramsObjData.TeachingMaterialInfoID = item.ID
                paramsObjData.IsDifficulty = true
                var paramsChilArr = []
                if(item.nanduGao1){
                    var chilObj = {}
                    chilObj.QuestionsType = 0
                    chilObj.ChoseCount = item.nanduGao1
                    chilObj.Difficulty = 0
                    chilObj.Score = this.settingMarkForm.radioTypeMark
                    paramsChilArr.push(chilObj)
                }
                if(item.nanduZhong1){
                    // var paramsChilArr = []
                    var chilObj = {}
                    chilObj.QuestionsType = 0
                    chilObj.ChoseCount = item.nanduZhong1
                    chilObj.Difficulty = 1
                    chilObj.Score = this.settingMarkForm.radioTypeMark
                    paramsChilArr.push(chilObj)
                }
                if(item.nanduDI1){
                    // var paramsChilArr = []
                    var chilObj = {}
                    chilObj.QuestionsType = 0
                    chilObj.ChoseCount = item.nanduDI1
                    chilObj.Difficulty = 2
                    chilObj.Score = this.settingMarkForm.radioTypeMark
                    paramsChilArr.push(chilObj)
                }
                paramsObjData.ExamPaperTMRDSettingList = paramsChilArr
                paramsArrData.push(paramsObjData)
            }else{
                if(item.allSelect1 > item.danxuan1 || item.allSelect2 > item.duoxuan2 || item.allSelect3 > item.panduan3){
                    this.$message({
                        message: '已超出题库范围！',
                        type: 'warning'
                    });
                    this.freeIsSubmit++
                    return
                }
                if(item.allSelect1){
                    var paramsObjData = {}
                    paramsObjData.TeachingMaterialInfoID = item.ID
                    paramsObjData.IsDifficulty = false
                    var paramsChilArr = []
                    var chilObj = {}
                    chilObj.QuestionsType = 0
                    chilObj.ChoseCount = item.allSelect1
                    chilObj.Score = this.settingMarkForm.radioTypeMark
                    paramsChilArr.push(chilObj)
                    paramsObjData.ExamPaperTMRDSettingList = paramsChilArr
                    paramsArrData.push(paramsObjData)
                }
            }
            if(item.isDifficulty2){
                if(item.nanduGao2 > item.gao2 || item.nanduZhong2 > item.zhong2 || item.nanduDI2 > item.di2){
                    this.$message({
                        message: '已超出题库范围！',
                        type: 'warning'
                    });
                    this.freeIsSubmit++
                    return
                }
                var paramsObjData = {}
                paramsObjData.TeachingMaterialInfoID = item.ID
                paramsObjData.IsDifficulty = true
                var paramsChilArr = []
                if(item.nanduGao2){
                    var chilObj = {}
                    chilObj.QuestionsType = 1
                    chilObj.ChoseCount = item.nanduGao2
                    chilObj.Difficulty = 0
                    chilObj.Score = this.settingMarkForm.cheboxTypeMark
                    paramsChilArr.push(chilObj)
                }
                if(item.nanduZhong2){
                    var chilObj = {}
                    chilObj.QuestionsType = 1
                    chilObj.ChoseCount = item.nanduZhong2
                    chilObj.Difficulty = 1
                    chilObj.Score = this.settingMarkForm.cheboxTypeMark
                    paramsChilArr.push(chilObj)
                }
                if(item.nanduDI2){
                    var chilObj = {}
                    chilObj.QuestionsType = 1
                    chilObj.ChoseCount = item.nanduDI2
                    chilObj.Difficulty = 2
                    chilObj.Score = this.settingMarkForm.cheboxTypeMark
                    paramsChilArr.push(chilObj)
                }
                paramsObjData.ExamPaperTMRDSettingList = paramsChilArr
                paramsArrData.push(paramsObjData)
            }else{
                if(item.allSelect1 > item.danxuan1 || item.allSelect2 > item.duoxuan2 || item.allSelect3 > item.panduan3){
                    this.$message({
                        message: '已超出题库范围！',
                        type: 'warning'
                    });
                    this.freeIsSubmit++
                    return
                }
                if(item.allSelect2){
                    var paramsObjData = {}
                    paramsObjData.TeachingMaterialInfoID = item.ID
                    paramsObjData.IsDifficulty = false
                    var paramsChilArr = []
                    var chilObj = {}
                    chilObj.QuestionsType = 1
                    chilObj.ChoseCount = item.allSelect2
                    chilObj.Score = this.settingMarkForm.cheboxTypeMark
                    paramsChilArr.push(chilObj)
                    paramsObjData.ExamPaperTMRDSettingList = paramsChilArr
                    paramsArrData.push(paramsObjData)
                }
            }
            if(item.isDifficulty3){
                if(item.nanduGao3 > item.gao3 || item.nanduZhong3 > item.zhong3 || item.nanduDI3 > item.di3){
                    this.$message({
                        message: '已超出题库范围！',
                        type: 'warning'
                    });
                    this.freeIsSubmit++
                    return
                }
                var paramsObjData = {}
                paramsObjData.TeachingMaterialInfoID = item.ID
                paramsObjData.IsDifficulty = true
                var paramsChilArr = []
                if(item.nanduGao3){
                    var chilObj = {}
                    chilObj.QuestionsType = 2
                    chilObj.ChoseCount = item.nanduGao3
                    chilObj.Difficulty = 0
                    chilObj.Score = this.settingMarkForm.judgeTypeMark
                    paramsChilArr.push(chilObj)
                }
                if(item.nanduZhong3){
                    var chilObj = {}
                    chilObj.QuestionsType = 2
                    chilObj.ChoseCount = item.nanduZhong3
                    chilObj.Difficulty = 1
                    chilObj.Score = this.settingMarkForm.judgeTypeMark
                    paramsChilArr.push(chilObj)
                }
                if(item.nanduDI3){
                    var chilObj = {}
                    chilObj.QuestionsType = 2
                    chilObj.ChoseCount = item.nanduDI3
                    chilObj.Difficulty = 2
                    chilObj.Score = this.settingMarkForm.judgeTypeMark
                    paramsChilArr.push(chilObj)
                }
                paramsObjData.ExamPaperTMRDSettingList = paramsChilArr
                paramsArrData.push(paramsObjData)
            }else{
                if(item.allSelect1 > item.danxuan1 || item.allSelect2 > item.duoxuan2 || item.allSelect3 > item.panduan3){
                    this.$message({
                        message: '已超出题库范围！',
                        type: 'warning'
                    });
                    this.freeIsSubmit++
                    return
                }
                if(item.allSelect3){
                    var paramsObjData = {}
                    paramsObjData.TeachingMaterialInfoID = item.ID
                    paramsObjData.IsDifficulty = false
                    var paramsChilArr = []
                    var chilObj = {}
                    chilObj.QuestionsType = 2
                    chilObj.ChoseCount = item.allSelect3
                    chilObj.Score = this.settingMarkForm.judgeTypeMark
                    paramsChilArr.push(chilObj)
                    paramsObjData.ExamPaperTMRDSettingList = paramsChilArr
                    paramsArrData.push(paramsObjData)
                }
            }
            return paramsArrData
        },
        // 根据教材id获取分页列表
        getListPageById(params) {
            params.pageIndex = this.pages.pageIndex,
            params.pageSize = this.pages.pageSize,
            params.useRange = 0  //暂时测试关闭
            this.listLoading1 = true
            getExaminationQuestionsByTMIDListPageAsync(params).then(res => {
                this.listLoading1 = false;
                var resData = res.data
                if(resData.Success){
                    this.manualCenter = resData.Response.Data
                    resData.Response.Data.forEach(item => {
                        this.recordAllData.push(item)
                    })
                    var hash = {};
                    this.recordAllData = this.recordAllData.reduce(function(arr, current) {
                        hash[current.ID] ? '' : hash[current.ID] = true && arr.push(current);
                        return arr
                    }, [])
                    this.pages.dataCount = resData.Response.DataCount
                    this.pages.pageIndex = resData.Response.PageIndex
                    if(resData.Response.PageSize > 0){
                        this.pages.pageSize = resData.Response.PageSize
                    }
                    if(this.isFixedType === '0'){
                        var data = resData.Response.Data
                        data.forEach(o => {
                            o.teachID = this.teachingMaterialID
                        })
                    }
                    if(this.isFixedType === '1'){
                        var a = this.manualLeft11
                        var b = params.teachingMaterialID
                        var c = ''
                        var d = resData.Response.Data
                        // this.findChild(a,b,c,d);
                    }
                }else{
                    this.$message({
                        message: resData.Message,
                        type: "error"
                    });
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            var params = {}
            if(this.filters.difficulty != 5){
                params.difficulty = this.filters.difficulty
            }
            if(this.filters.questionsType != 5){
                params.questionsType = this.filters.questionsType
            }
            if(this.filters.questionsTitle != ''){
                params.questionsTitle = this.filters.questionsTitle
            }
            params.teachingMaterialID = this.teachingMaterialID
            this.getListPageById(params);
        },
        handleSizeChange(val){
            this.pages.pageSize = val
            var params = {}
            if(this.filters.difficulty != 5){
                params.difficulty = this.filters.difficulty
            }
            if(this.filters.questionsType != 5){
                params.questionsType = this.filters.questionsType
            }
            if(this.filters.questionsTitle != ''){
                params.questionsTitle = this.filters.questionsTitle
            }
            params.teachingMaterialID = this.teachingMaterialID
            this.getListPageById(params);
        },
        // 折叠面板
        handleChange() {

        },
        // 头部编辑
        editTitleChange() {
            this.editDataStr = {
                titleName: this.titleName,
                titleDescribe: this.titleDescribe,
            },
            this.editDialogView = true;
        },
        // 编辑的保存
        editSubmit() {
            this.$refs['editFormRef'].validate((valid) => {
                if(valid){
                    this.titleName = this.editDataStr.titleName
                    this.titleDescribe = this.editDataStr.titleDescribe
                    this.editDialogView = false;
                }
            })
        },
        // 展开关闭
        // 全部
        allIsViwerChange() {
            this.allIsViwes = !this.allIsViwes
            if(this.allIsViwes){
                this.tableData.forEach(item => {
                    item.isViwes = true;
                });
            }else{
                this.tableData.forEach(item => {
                    item.isViwes = false;
                });
            }
            var a = this.tableData
            this.tableData = []
            var _this = this
            setTimeout(() => {
                _this.tableData = a
            })
        },
        // 单个
        isviewsChange(val) {
            val.isViwes = !val.isViwes
            var a = this.tableData
            this.tableData = []
            var _this = this
            setTimeout(() => {
                _this.tableData = a
            })
        },
        // 删除单个
        deleteOne(val) {
            this.$confirm('确认删除吗？',"提示",{
            }).then(() => {
                this.tableData.splice(val,1)
            }).catch(()=>{

            })
        },
        // 批量删除
        deleteAll() {
            if(this.allCheckbox.length > 0){
                this.$confirm('确认删除吗？',"提示",{
                }).then(() => {
                    for(var a = 0;a < this.allCheckbox.length;a++){
                        for(var b = 0;b < this.tableData.length;b++){
                            if(this.allCheckbox[a] == this.tableData[b].num){
                                this.tableData.splice(b,1)
                                a--
                            }
                        }
                    }
                }).catch(()=>{
    
                })
            }else{
                this.$message({
                    message:'请选择要删除项！',
                    type:'warning'
                })
            }
        },
        // 批量设置分数
        settingMark() {
            if(this.isFixedType === '0'){
                if(this.tableData.length == 0){
                    this.$message({
                        message: '还未选择试题！',
                        type: 'warning'
                    });
                    return;
                }
                this.settingMarkForm = {
                    radioTypeMark: this.settingMarkForm.radioTypeMark,
                    cheboxTypeMark: this.settingMarkForm.cheboxTypeMark,
                    judgeTypeMark: this.settingMarkForm.judgeTypeMark,
    
                    radioTypeNum: 0,
                    cheboxTypeNum: 0,
                    judgeTypeNum: 0,
    
                    radioTypescore: this.settingMarkForm.radioTypescore,
                    cheboxTypescore: this.settingMarkForm.cheboxTypescore,
                    judgeTypescore: this.settingMarkForm.judgeTypescore,
    
                    aggregateScore: this.settingMarkForm.aggregateScore,
                }
                this.tableData.forEach(item => {
                    if(item.QuestionsType == 0){
                        this.settingMarkForm.radioTypeNum++
                    }
                    if(item.QuestionsType == 1){
                        this.settingMarkForm.cheboxTypeNum++
                    }
                    if(item.QuestionsType == 2){
                        this.settingMarkForm.judgeTypeNum++
                    }
                });
                this.settingMarkForm.radioTypescore = this.settingMarkForm.radioTypeMark * this.settingMarkForm.radioTypeNum
                this.settingMarkForm.cheboxTypescore = this.settingMarkForm.cheboxTypeMark * this.settingMarkForm.cheboxTypeNum
                this.settingMarkForm.judgeTypescore = this.settingMarkForm.judgeTypeMark * this.settingMarkForm.judgeTypeNum
                this.settingMarkForm.aggregateScore = this.settingMarkForm.radioTypescore + this.settingMarkForm.cheboxTypescore + this.settingMarkForm.judgeTypescore
                var a = this.tableData
                this.tableData = []
                var _this = this
                setTimeout(() => {
                    _this.tableData = a
                })
                this.dialogVisible = true;
            }else{
                this.settingMarkForm = {
                    radioTypeMark: this.settingMarkForm.radioTypeMark,
                    cheboxTypeMark: this.settingMarkForm.cheboxTypeMark,
                    judgeTypeMark: this.settingMarkForm.judgeTypeMark,
    
                    radioTypeNum: this.freePaperData.radioTypeQues,
                    cheboxTypeNum: this.freePaperData.cheboxTypeQues,
                    judgeTypeNum: this.freePaperData.judgeTypeQues,
    
                    radioTypescore: this.settingMarkForm.radioTypeMark*this.freePaperData.radioTypeQues,
                    cheboxTypescore: this.settingMarkForm.cheboxTypeMark*this.freePaperData.cheboxTypeQues,
                    judgeTypescore: this.settingMarkForm.judgeTypeMark*this.freePaperData.judgeTypeQues,
    
                    // aggregateScore: this.settingMarkForm.aggregateScore,
                }
                this.settingMarkForm.aggregateScore = this.settingMarkForm.radioTypescore + this.settingMarkForm.cheboxTypescore + this.settingMarkForm.judgeTypescore,
                this.dialogVisible = true;
            }
        },
        // 设置分数提交
        handleSubmit() {
            if(this.isFixedType === '0'){
                this.tableData.forEach(item => {
                    if(item.QuestionsType == 0){
                        item.Score = this.settingMarkForm.radioTypeMark
                    }
                    if(item.QuestionsType == 1){
                        item.Score = this.settingMarkForm.cheboxTypeMark
                    }
                    if(item.QuestionsType == 2){
                        item.Score = this.settingMarkForm.judgeTypeMark
                    }
                });
                var a = this.tableData
                this.tableData = []
                var _this = this
                setTimeout(() => {
                    _this.tableData = a
                })
                this.dialogVisible = false;
            }else{
                var a = this.manualLeft11
                var c = this.traverseData(a)
                
                c.forEach(item => {
                    item.ExamPaperTMRDSettingList.forEach(o => {
                        if(o.questionsType == 0) {
                            o.score = this.settingMarkForm.radioTypeMark
                        }
                        if(o.questionsType == 1) {
                            o.score = this.settingMarkForm.cheboxTypeMark
                        }
                        if(o.questionsType == 2) {
                            o.score = this.settingMarkForm.judgeTypeMark
                        }
                    })
                })
                this.viewsScore.radio = this.settingMarkForm.radioTypeMark
                this.viewsScore.check = this.settingMarkForm.cheboxTypeMark
                this.viewsScore.judge = this.settingMarkForm.judgeTypeMark
                this.paperMark = this.settingMarkForm.aggregateScore
                this.paperMarkNoChange = 222
                this.dialogVisible = false;
            }
        },
        // 取消
        cancelSetting() {
            // this.settingMarkForm = {
            //     radioTypeMark: 1,
            //     cheboxTypeMark: 1,
            //     judgeTypeMark: 1,

            //     radioTypeNum: 0,
            //     cheboxTypeNum: 0,
            //     judgeTypeNum: 0,

            //     radioTypescore: 0,
            //     cheboxTypescore: 0,
            //     judgeTypescore: 0,

            //     aggregateScore: 1,
            // }
            this.dialogVisible = false;
        },
        // 手动选题
        selectQuestion() {
            this.dialogSelect = true;
            this.getNavTree();
        },
        // 手动选题查询
        searchQuestion() {
            var params = {}
            if(this.filters.difficulty != 5){
                params.difficulty = this.filters.difficulty
            }
            if(this.filters.questionsType != 5){
                params.questionsType = this.filters.questionsType
            }
            if(this.filters.questionsTitle != ''){
                params.questionsTitle = this.filters.questionsTitle
            }
            params.teachingMaterialID = this.teachingMaterialID
            this.getListPageById(params);
        },
        // 手动选题确定
        selectQuesSubmit() {
            var newArr2 = []
            this.relatedQuestionID.forEach(item => {
                this.recordAllData.forEach(o => {
                    if(item == o.ID){
                        o.num = o.ID
                        o.Score = 1
                        o.isViwes = false
                        // if(o.QuestionsType == 2){
                        //     o.Options[0].AnswerContent = "正确"
                        //     o.Options[1].AnswerContent = "错误"
                        // }
                        newArr2.push(o)
                    }
                })
            })
            this.tableData = newArr2
            this.isCanBack = 0
            this.dialogSelect = false;
        },
        // 点击了试题得chebox
        getRow(val) {
            this.statistics = {
                radioNumber: 0,
                cheboxNumber: 0,
                judgeNumber: 0,
            }
            var newArrRecord = []
            this.recordAllData.forEach(item => {
                this.relatedQuestionID.forEach(o => {
                    if(o == item.ID){
                        newArrRecord.push(item)
                    }
                })
            })
            var newArrRecordcc = []
            this.manualCenter.forEach(item => {
                this.relatedQuestionID.forEach(o => {
                    if(o == item.ID){
                        newArrRecordcc.push(item)
                    }
                })
            })
            var paramsObj = {}
            paramsObj.teachingMaterialInfoID = this.teachingMaterialID
            paramsObj.isDifficulty = false
            var paramsArr = []
            newArrRecordcc.forEach(item => {
                var chilObj = {}
                chilObj.examinationQuestionsID = item.ID
                chilObj.score = 1
                paramsArr.push(chilObj)
            })
            newArrRecord.forEach(item => {
                // var chilObj = {}
                // chilObj.examinationQuestionsID = item.ID
                // chilObj.score = 1
                // paramsArr.push(chilObj)
                if(item.QuestionsType == 0){
                    this.statistics.radioNumber++
                }
                if(item.QuestionsType == 1){
                    this.statistics.cheboxNumber++
                }
                if(item.QuestionsType == 2){
                    this.statistics.judgeNumber++
                }
            })
            paramsObj.examPaperEQList = paramsArr
            if(this.teachingArrData.length > 0){
                var a = this.teachingArrData.map(a => a.teachingMaterialInfoID).indexOf(paramsObj.teachingMaterialInfoID)
                if(a > -1){
                    for(var a = 0;a < this.teachingArrData.length;a++){
                        if(this.teachingArrData[a].teachingMaterialInfoID == paramsObj.teachingMaterialInfoID){
                            this.teachingArrData.splice(a,1)
                            this.teachingArrData.push(paramsObj)
                        }
                    }
                }else{
                    this.teachingArrData.push(paramsObj)
                }
            }else{
                this.teachingArrData.push(paramsObj)
            }
        },
        // 试题保存
        randomPreserve() {
            var params = {}
            params.PaperName = this.titleName
            if(this.dataType !== '' && this.dataType !== null){
                params.DataType = this.dataType
            }
            if(this.dictionaryType !== '' && this.dictionaryType !== null){
                params.DataDictionaryId = this.dictionaryType
            }
            if(this.tradeType !== '' && this.tradeType !== null){
                params.TradeType = this.tradeType
            }
            params.MakeQuestionType = this.isFixedType
            // return
            this.isCanBack = 333
            // if(!this.baseSettingData.examSetTime){
            //     this.$message({
            //         message: '未设置基础设置的考试时间项！',
            //         type: 'warning'
            //     });
            //     return
            // }
            // 基础设置
            var baseSetting = {}
            baseSetting.ExamDuration = this.baseSettingData.examTimeLeng
            baseSetting.PassScoreLine = this.baseSettingData.qualifiedScoe
            // baseSetting.StartTime = this.timeFormate(this.baseSettingData.examSetTime[0])
            // baseSetting.EndTime = this.timeFormate(this.baseSettingData.examSetTime[1])
            // baseSetting.ViewResult = this.baseSettingData.examResult
            // baseSetting.IsLate = this.baseSettingData.beLateExam
            // baseSetting.LateDuration = this.baseSettingData.beLateTimer
            // baseSetting.IsHandPaper = this.baseSettingData.canSubmitSwitch
            // baseSetting.HandPaperDuration = this.baseSettingData.canSubmitPaper
            if(this.dataType != 0) {
                params.BaseSetting = baseSetting
                // params.InTheSetting = this.inTheSettingData
                // params.AfterSetting = this.afterSettingData
            }
            if(this.isFixedType === '0'){
                // 暂时注销
                // this.teachingArrData.forEach(item => {
                //     item.examPaperEQList.forEach(o => {
                //         this.tableData.forEach(a => {
                //             if(a.ID == o.examinationQuestionsID){
                //                 o.score = a.Score
                //             }
                //         })
                //     })
                // })
                // params.examPaperTMList = this.teachingArrData
                if(this.tableData.length > 0) {
                    var fixedArr = []
                    this.tableData.forEach(item => {
                        var fixedObj = {}
                        var examPaperEQList = []
                        var examPaperEQListObj = {}
                        fixedObj.TeachingMaterialInfoID = item.teachID
                        fixedObj.IsDifficulty = false
                        examPaperEQListObj.ExaminationQuestionsID = item.ID || item.ExaminationQuestionsID
                        examPaperEQListObj.Score = item.Score
                        examPaperEQList.push(examPaperEQListObj)
                        fixedObj.ExamPaperEQList = examPaperEQList
                        fixedArr.push(fixedObj)
                    })
                    params.ExamPaperTMList = fixedArr
                }else{
                    this.$confirm('未选择试题，确认返回？',"提示",{
                    }).then(() => {
                        this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
                    }).catch(() => {
                        
                    })
                    return
                }
                // console.log("params",params)
                // return
                if(this.idAddOrEdit){
                    this.addPaper(params);
                    // this.editPaper(params);
                }else{
                    this.editPaper(params);
                }
            }else{
                var a = this.manualLeft11
                var n1 = this.freeIsSubmit
                var c = this.traverseData(a)
                var n2 = this.freeIsSubmit
                if(c.length > 0){
                    params.ExamPaperTMList = c
                }else{
                    this.$confirm('未选择试题，确认返回？',"提示",{
                    }).then(() => {
                        this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
                    }).catch(() => {
                        
                    })
                    return
                }
                if(n1 === n2){
                    // console.log("params",params)
                    // return
                    if(params.ExamPaperTMList.length > 0){
                        for(var a = 0; a < params.ExamPaperTMList.length; a++){
                            var m = params.ExamPaperTMList[a]
                            if(m.ExamPaperTMRDSettingList.length > 0){}else{
                                params.ExamPaperTMList.splice(a,1)
                            }
                        }
                    }
                    if(this.idAddOrEdit){
                        this.addPaper(params);
                        // this.editPaper(params);
                    }else{
                        this.editPaper(params);
                    }
                }
            }
            
        },
        // 重置试卷
        doFreashPaper() {
            if(this.isFixedType === '0'){
                this.tableData = []
                this.relatedQuestionID = []
                this.statistics = {
                    radioNumber: 0,
                    cheboxNumber: 0,
                    judgeNumber: 0,
                    numberSun: 0
                }
            }else{
                this.listLoading3 = true
                this.manualLeft11.forEach(item => {
                    this.secondDoFreash(item)
                    if(item.Children){
                        item.Children.forEach(o => {
                            this.secondDoFreash(o)
                        })
                    }
                })
                this.freePaperData = {
                    radioTypeQues: 0,
                    cheboxTypeQues: 0,
                    judgeTypeQues: 0,
                    questionSumNum: '',
                    radioTypeQuesTage: '',
                    cheboxTypeQuesTage: '',
                    judgeTypeQuesTage: '',
                }
                this.settingMarkForm = {
                    radioTypeMark: 1,
                    cheboxTypeMark: 1,
                    judgeTypeMark: 1,

                    radioTypeNum: 0,
                    cheboxTypeNum: 0,
                    judgeTypeNum: 0,

                    radioTypescore: 0,
                    cheboxTypescore: 0,
                    judgeTypescore: 0,

                    aggregateScore: 1,
                }
                this.viewsScore = {
                    radio: 1,
                    check: 1,
                    judge: 1,
                }
                this.paperMark = 0
                this.listLoading3 = false
            }
        },
        // 随机试卷重置二次封装
        secondDoFreash(data) {
            data.allSelect1 = ''
            data.allSelect2 = ''
            data.allSelect3 = ''

            data.nanduDI1 = ''
            data.nanduDI2 = ''
            data.nanduDI3 = ''
            
            data.nanduGao1 = ''
            data.nanduGao2 = ''
            data.nanduGao3 = ''
            
            data.nanduZhong1 = ''
            data.nanduZhong2 = ''
            data.nanduZhong3 = ''

            data.isDifficulty1 = false
            data.isDifficulty2 = false
            data.isDifficulty3 = false
        },
        // 新增接口
        addPaper(params) {
            this.addLoading = true;
            addExaminationPaper(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.addLoading = false;
                    this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    this.addLoading = false;
                }
            })
        },
        // 编辑接口
        editPaper(params){
            this.addLoading = true;
            params.ID = this.editFixedData.ID
            // params.id = this.editFixedDataID || this.editFixedData.ID
            updateExaminationPaper(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.addLoading = false;
                    this.$router.replace(this.$route.query.redirect ? this.$route.query.redirect : "/");
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    this.addLoading = false;
                }
            })
        },
        // 更新试卷统计
        updataPaperData() {
            var a = this.manualLeft11
            var c = this.traverseData(a)
            var n = {}
            n.ExamPaperTMList = c
            n.ExamPaperTMList.forEach(item => {
                item.ExamPaperTMRDSettingList.forEach(a => {
                    if(a.QuestionsType == 0){
                        a.score = this.viewsScore.radio
                    }
                    if(a.QuestionsType == 1){
                        a.score = this.viewsScore.check
                    }
                    if(a.QuestionsType == 2){
                        a.score = this.viewsScore.judge
                    }
                })
            })
            this.statisticsQuestionNum(n)
        },
        // 编辑渲染函数
        editBackViews(a,b) {
            a.ExamPaperTMList.forEach(o => {
                b.forEach(n => {
                    if(o.TeachingMaterialInfoID == n.ID){
                        if(!o.IsDifficulty){
                            if(o.ExamPaperTMRDSettingList[0].QuestionsType == 0){
                                n.allSelect1 = o.ExamPaperTMRDSettingList[0].ChoseCount
                                // n.isDifficulty1 = false
                            }
                            if(o.ExamPaperTMRDSettingList[0].QuestionsType == 1){
                                n.allSelect2 = o.ExamPaperTMRDSettingList[0].ChoseCount
                                // n.isDifficulty2 = false
                            }
                            if(o.ExamPaperTMRDSettingList[0].QuestionsType == 2){
                                n.allSelect3 = o.ExamPaperTMRDSettingList[0].ChoseCount
                                // n.isDifficulty3 = false
                            }
                        }else{
                            o.ExamPaperTMRDSettingList.forEach(c => {
                                if(c.QuestionsType == 0){
                                    n.isDifficulty1 = true
                                    if(c.Difficulty == 0){
                                        n.nanduGao1 = c.ChoseCount
                                    }
                                    if(c.Difficulty == 1){
                                        n.nanduZhong1 = c.ChoseCount
                                    }
                                    if(c.Difficulty == 2){
                                        n.nanduDI1 = c.ChoseCount
                                    }
                                }
                                if(c.QuestionsType == 1){
                                    n.isDifficulty2 = true
                                    if(c.Difficulty == 0){
                                        n.nanduGao2 = c.ChoseCount
                                    }
                                    if(c.Difficulty == 1){
                                        n.nanduZhong2 = c.ChoseCount
                                    }
                                    if(c.Difficulty == 2){
                                        n.nanduDI2 = c.ChoseCount
                                    }
                                }
                                if(c.QuestionsType == 2){
                                    n.isDifficulty3 = true
                                    if(c.Difficulty == 0){
                                        n.nanduGao3 = c.ChoseCount
                                    }
                                    if(c.Difficulty == 1){
                                        n.nanduZhong3 = c.ChoseCount
                                    }
                                    if(c.Difficulty == 2){
                                        n.nanduDI3 = c.ChoseCount
                                    }
                                }
                            })
                        }
                    }
                })
            })
        },
        // 获取试题详细信息
        getPaperDetail(params){
            getExaminationPaperByID(params).then((res) => {
                var result = res.data
                if(result.Success){
                    var data = result.Response
                    if(this.isFixedType === '0'){
                        var timeData = []
                        var timeData1 = []
                        data.ExamPaperTMList.forEach(item => {
                            var timeObj = {}
                            var timeArr = []
                            item.ExamPaperEQList.forEach((o,index) => {
                                var timeObj2 = {}
                                o.num = o.ExaminationQuestionsID
                                o.teachID = item.TeachingMaterialInfoID
                                this.tableData.push(o)
                                timeData.push(item.TeachingMaterialInfoID)
                                timeData1.push(o.ExaminationQuestionsID)
                                var _this = this

                                timeObj2.examinationQuestionsID = o.ExaminationQuestionsID
                                timeObj2.score = o.Score
                                timeArr.push(timeObj2)
                                setTimeout(() => {
                                    if(o.QuestionsType == 0){
                                        this.statistics.radioNumber++
                                    }
                                    if(o.QuestionsType == 1){
                                        this.statistics.cheboxNumber++
                                    }
                                    if(o.QuestionsType == 2){
                                        this.statistics.judgeNumber++
                                    }
                                },20)
                            })
                            timeObj.teachID = item.TeachingMaterialInfoID
                            timeObj.isDifficulty = false
                            timeObj.examPaperEQList = timeArr
                            this.teachingArrData.push(timeObj)
                        })
                        const r = [...new Set(timeData)];
                        const c = [...new Set(timeData1)];
                        this.textbookID = r
                        this.relatedQuestionID = c
                        this.idAddOrEdit = false
                    }else{
                        data.ExamPaperTMList.forEach(item => {
                            item.ExamPaperTMRDSettingList.forEach(o => {
                                if(o.QuestionsType == 0){
                                    this.viewsScore.radio = o.Score
                                }
                                if(o.QuestionsType == 1){
                                    this.viewsScore.check = o.Score
                                }
                                if(o.QuestionsType == 2){
                                    this.viewsScore.judge = o.Score
                                }
                            })
                        })
                        this.editFixedData1 = data
                        // var a = this.editFixedData1
                        // var b = this.manualLeft11
                        // console.log("传参",b)
                        // this.editBackViews(a,b)
                        // this.statisticsQuestionNum(a)
                        // if(this.idAddOrEdit){}else{
                        // }
                        this.idAddOrEdit = false
                    }
                }
            })
        },
        // 题型统计函数
        statisticsQuestionNum(a) {
            var aa = 0
            var bb = 0
            var cc = 0
            var dd = 0
            var ee1 = 0
            var ee2 = 0
            var ee3 = 0
            a.ExamPaperTMList.forEach(item => {
                if(item.ExamPaperTMRDSettingList){
                    item.ExamPaperTMRDSettingList.forEach(o => {
                        if(o.QuestionsType == 0 && o.ChoseCount && o.ChoseCount > 0){
                            aa += Number(o.ChoseCount)
                            ee1 = o.Score
                        }
                        if(o.QuestionsType == 1 && o.ChoseCount && o.ChoseCount > 0){
                            bb += Number(o.ChoseCount)
                            ee2 = o.Score
                        }
                        if(o.QuestionsType == 2 && o.ChoseCount && o.ChoseCount > 0){
                            cc += Number(o.ChoseCount)
                            ee3 = o.Score
                        }
                    })
                }else{
                    item.ExamPaperTMRDSettingList.forEach(o => {
                        if(o.questionsType == 0 && o.choseCount && o.choseCount > 0){
                            aa += Number(o.choseCount)
                            ee1 = o.score
                        }
                        if(o.questionsType == 1 && o.choseCount && o.choseCount > 0){
                            bb += Number(o.choseCount)
                            ee2 = o.score
                        }
                        if(o.questionsType == 2 && o.choseCount && o.choseCount > 0){
                            cc += Number(o.choseCount)
                            ee3 = o.score
                        }
                    })
                }
            })
            this.freePaperData.radioTypeQues = aa
            this.freePaperData.cheboxTypeQues = bb
            this.freePaperData.judgeTypeQues = cc
            dd = (aa*ee1)+(bb*ee2)+(cc*ee3)
            // if(this.paperMarkNoChange == 333){
                this.paperMark = dd
                if(!this.editFixedData){
                    this.baseSettingData.qualifiedScoe = parseInt(this.paperMark * 0.6)
                }
            // }
        },
        //行拖拽
        rowDrop() {
            if(document.querySelector('.tableLeft .el-table__body-wrapper tbody')){
                const tbody = document.querySelector('.tableLeft .el-table__body-wrapper tbody')
                const _this = this
                Sortable.create(tbody, {
                    onEnd({ newIndex, oldIndex }) {
                        const currRow = _this.tableData.splice(oldIndex, 1)[0]
                        _this.tableData.splice(newIndex, 0, currRow)
                    }
                })
            }
        },
        // 时间显示
        createTime() {
            this.timeNow = this.timeFormate(new Date());
        },
        // 时间格式化
        timeFormate(timeStamp) {
            let year = new Date(timeStamp).getFullYear();
            let month =new Date(timeStamp).getMonth() + 1 < 10? "0" + (new Date(timeStamp).getMonth() + 1): new Date(timeStamp).getMonth() + 1;
            let date =new Date(timeStamp).getDate() < 10? "0" + new Date(timeStamp).getDate(): new Date(timeStamp).getDate();
            let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
            let mm =new Date(timeStamp).getMinutes() < 10? "0" + new Date(timeStamp).getMinutes(): new Date(timeStamp).getMinutes();
            let ss =new Date(timeStamp).getSeconds() < 10? "0" + new Date(timeStamp).getSeconds(): new Date(timeStamp).getSeconds();
            return year + "年" + month + "月" + date +"日"+" "+hh+":"+mm+':'+ss ;
        },
        // 计算百分比
        GetPercent(num, total) {
            num = parseFloat(num);
            total = parseFloat(total);
            if (isNaN(num) || isNaN(total)) {
                return "-";
            }
            return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00)+"%";
        },
    },
    watch: {
        'manualLeft11': function() {
            var a = this.editFixedData1
            var b = this.manualLeft11
            if(this.idAddOrEdit){}else{
                this.editBackViews(a,b)
                this.statisticsQuestionNum(a)
            }
        },
        'settingMarkForm.radioTypeMark': function(){
            this.settingMarkForm.radioTypescore = this.settingMarkForm.radioTypeMark * this.settingMarkForm.radioTypeNum
            this.settingMarkForm.aggregateScore = this.settingMarkForm.radioTypescore + this.settingMarkForm.cheboxTypescore + this.settingMarkForm.judgeTypescore
        },
        'settingMarkForm.cheboxTypeMark': function(){
            this.settingMarkForm.cheboxTypescore = this.settingMarkForm.cheboxTypeMark * this.settingMarkForm.cheboxTypeNum
            this.settingMarkForm.aggregateScore = this.settingMarkForm.radioTypescore + this.settingMarkForm.cheboxTypescore + this.settingMarkForm.judgeTypescore
        },
        'settingMarkForm.judgeTypeMark': function(){
            this.settingMarkForm.judgeTypescore = this.settingMarkForm.judgeTypeMark * this.settingMarkForm.judgeTypeNum
            this.settingMarkForm.aggregateScore = this.settingMarkForm.radioTypescore + this.settingMarkForm.cheboxTypescore + this.settingMarkForm.judgeTypescore
        },
        'statistics.radioNumber': function(){
            this.statistics.numberSun = this.statistics.radioNumber + this.statistics.cheboxNumber + this.statistics.judgeNumber
        },
        'statistics.cheboxNumber': function(){
            this.statistics.numberSun = this.statistics.radioNumber + this.statistics.cheboxNumber + this.statistics.judgeNumber
        },
        'statistics.judgeNumber': function(){
            this.statistics.numberSun = this.statistics.radioNumber + this.statistics.cheboxNumber + this.statistics.judgeNumber
        },
        'freePaperData.radioTypeQues': function(){
            this.freePaperData.questionSumNum = this.freePaperData.radioTypeQues + this.freePaperData.cheboxTypeQues + this.freePaperData.judgeTypeQues
            this.freePaperData.radioTypeQuesTage = this.GetPercent(this.freePaperData.radioTypeQues,this.freePaperData.questionSumNum)
        },
        'freePaperData.cheboxTypeQues': function(){
            this.freePaperData.questionSumNum = this.freePaperData.radioTypeQues + this.freePaperData.cheboxTypeQues + this.freePaperData.judgeTypeQues
            this.freePaperData.cheboxTypeQuesTage = this.GetPercent(this.freePaperData.cheboxTypeQues,this.freePaperData.questionSumNum)
        },
        'freePaperData.judgeTypeQues': function(){
            this.freePaperData.questionSumNum = this.freePaperData.radioTypeQues + this.freePaperData.cheboxTypeQues + this.freePaperData.judgeTypeQues
            this.freePaperData.judgeTypeQuesTage = this.GetPercent(this.freePaperData.judgeTypeQues,this.freePaperData.questionSumNum)
        },
        'tableData': function() {
            this.paperMark = 0
            this.tableData.forEach(item => {
                this.paperMark = this.paperMark+Number(item.Score)
                this.baseSettingData.qualifiedScoe = parseInt(this.paperMark * 0.6)
            })
        },
    },
    created() {
        var _this = this
        // setTimeout(()=>{
            if(typeof(_this.$route.query.time) === 'object' || typeof(_this.$route.query.time) === 'undefined'){
                _this.timeNow = _this.timeFormate(new Date())
            }else{
                _this.timeNow = _this.timeFormate(_this.$route.query.time)
            }
            _this.titleName = _this.$route.query.titel
            if(_this.$route.query.dataType !== ''){
                _this.dataType = _this.$route.query.dataType
            }
            if(_this.$route.query.dictionaryType !== ''){
                _this.dictionaryType = _this.$route.query.dictionaryType
            }
            if(_this.$route.query.tradeType !== ''){
                _this.tradeType = _this.$route.query.tradeType
            }
            _this.editDataStr.titleName = _this.$route.query.titel
            _this.paperType = _this.$route.query.paperType == 'true'?'固定试卷':'随机试卷'
            _this.isFixedType = _this.$route.query.paperType === 'true'?'0':'1'
            if(_this.$route.query.ID){
                _this.editFixedDataID = _this.$route.query.ID
            }
        // })
        if(this.isFixedType === '1') {
            this.getNavSecTree();
            // this.getNavTree();
        }
    },
    mounted() {
        var o = this.freePaperData
        o.questionSumNum = o.radioTypeQues + o.cheboxTypeQues + o.judgeTypeQues
        o.radioTypeQuesTage = this.GetPercent(o.radioTypeQues,o.questionSumNum)
        o.cheboxTypeQuesTage = this.GetPercent(o.cheboxTypeQues,o.questionSumNum)
        o.judgeTypeQuesTage = this.GetPercent(o.judgeTypeQues,o.questionSumNum)
        this.editFixedData = JSON.parse(sessionStorage.getItem("needData"))
        if(this.editFixedData){
            var params = {
                id: this.editFixedData.ID,
            }
            this.getPaperDetail(params);
            // if(this.isFixedType === '0'){
            //     var timeData = []
            //     var timeData1 = []
            //     this.editFixedData.ExamPaperTMList.forEach(item => {
            //         var timeObj = {}
            //         var timeArr = []
            //         item.ExamPaperEQList.forEach((o,index) => {
            //             var timeObj2 = {}
            //             o.num = o.ExaminationQuestionsID
            //             this.tableData.push(o)
            //             timeData.push(item.TeachingMaterialInfoID)
            //             timeData1.push(o.ExaminationQuestionsID)
            //             var _this = this
    
            //             timeObj2.examinationQuestionsID = o.ExaminationQuestionsID
            //             timeObj2.score = o.Score
            //             timeArr.push(timeObj2)
            //             setTimeout(() => {
            //                 if(o.QuestionsType == 0){
            //                     this.statistics.radioNumber++
            //                 }
            //                 if(o.QuestionsType == 1){
            //                     this.statistics.cheboxNumber++
            //                 }
            //                 if(o.QuestionsType == 2){
            //                     this.statistics.judgeNumber++
            //                 }
            //             },20)
            //         })
            //         timeObj.teachingMaterialInfoID = item.TeachingMaterialInfoID
            //         timeObj.isDifficulty = false
            //         timeObj.examPaperEQList = timeArr
            //         this.teachingArrData.push(timeObj)
            //     })
            //     const r = [...new Set(timeData)];
            //     const c = [...new Set(timeData1)];
            //     this.textbookID = r
            //     this.relatedQuestionID = c
            //     this.idAddOrEdit = false
            // }else{
            //     this.idAddOrEdit = false
            //     this.editFixedData.ExamPaperTMList.forEach(item => {
            //         item.ExamPaperTMRDSettingList.forEach(o => {
            //             if(o.QuestionsType == 0){
            //                 this.viewsScore.radio = o.Score
            //             }
            //             if(o.QuestionsType == 1){
            //                 this.viewsScore.check = o.Score
            //             }
            //             if(o.QuestionsType == 2){
            //                 this.viewsScore.judge = o.Score
            //             }
            //         })
            //     })
            // }
            if(this.editFixedData.BaseSetting){
                var e = this.editFixedData.BaseSetting
                this.baseSettingData.examTimeLeng = e.ExamDuration
                this.baseSettingData.qualifiedScoe = e.PassScoreLine
                // var newArr = []
                // newArr.push(e.StartTime)
                // newArr.push(e.EndTime)
                // this.baseSettingData.examSetTime = newArr
                this.baseSettingData.examResult = e.ViewResult
                this.baseSettingData.beLateExam = e.IsLate
                this.baseSettingData.beLateTimer = e.LateDuration
                this.baseSettingData.canSubmitSwitch = e.IsHandPaper
                this.baseSettingData.canSubmitPaper = e.HandPaperDuration
            }
            if(this.editFixedData.InTheSetting){
                var q = this.editFixedData.InTheSetting
                this.inTheSettingData = {
                    IsFinishHandPaper: q.IsFinishHandPaper,
                    AutomaticJump: q.AutomaticJump,
                    IsMonitor: q.IsMonitor,
                    TakePictureCount: q.TakePictureCount,
                    NotCheckFace: q.NotCheckFace,
                    NotCheckFaceCount: q.NotCheckFaceCount,
                    CheckMultipleFace: q.CheckMultipleFace,
                    CheckMultipleFaceCount: q.CheckMultipleFaceCount,
                    IsVoiceMonitoring: q.IsVoiceMonitoring,
                    ExceedDecibelLine: q.ExceedDecibelLine,
                    ExceedDecibelLineCount: q.ExceedDecibelLineCount,
                }
            }
            if(this.editFixedData.AfterSetting){
                this.afterSettingData = {
                    ResultsViewRange: (this.editFixedData.AfterSetting.ResultsViewRange).toString(),
                }
            }
        }
        // if(this.isFixedType === '1' && this.manualLeft11.length > 0){
        //     var a = this.editFixedData
        //     var b = this.manualLeft11
        //     if(this.idAddOrEdit){}else{
        //         this.editBackViews(a,b)
        //         this.statisticsQuestionNum(a)
        //     }
        // }
    },
    updated() {
        this.rowDrop();
    }
}
</script>

<style lang="stylus" scoped>
    .el-col {
        border-radius: 4px;
    }
    .bg-purple {
        border 1px solid #eee;
    }
    .bg-purple-light {
        // border 1px solid #eee;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .tableLeft>>>.el-table__row td{
        border: 1px solid #fff!important;
    }
    .tableLeft>>>.el-table::before {
        border: 1px solid #fff!important;
        background-color: #fff!important;
    }
    .el-table,.tableLeft,.el-table--fit,.el-table--enable-row-hover,.el-table--enable-row-transition::before {
        background-color: #fff!important;
    }
    .answer>>>.el-radio__input.is-disabled+span.el-radio__label{
        color #000!important
    }
    .answer>>>.el-checkbox__input.is-disabled+span.el-checkbox__label{
        color #000!important
    }
    .grid-content,.bg-purple{
        border: 1px solid #fff!important;
    }
    .el-table,.el-table--fit,.el-table--border,.el-table--enable-row-hover,.el-table--enable-row-transition{
        padding-bottom 1px!important
    }
    .content-az div:first-child .el-row{
        margin-left 0!important
        margin-right 0!important
    }
    .settingSome>>>.el-collapse-item__header{
        font-weight: bold!important;
        font-size: 16px!important;
    }
    .examSettingFrom{
        display flex;
        justify-content space-between;
        align-items center;
    }
    .settingFromHeader{
        font-size 16px;
        font-weight blod!important;
    }
    .chilSetting{
        margin-left 20px;
    }
    .viewsPar{
        display flex;
        align-items: center;
    }
    .viewsQuesNum{
        width 30px;
        text-align: center;
    }
</style>