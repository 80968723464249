<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="选择证书">
                        <el-select v-model="filter.certificateInfo" placeholder="选择证书">
                            <el-option
                                v-for="item in CertificateInfoArr"
                                :key="item.ID"
                                :label="item.CertificateName"
                                :value="item.ID"
                            >
                            </el-option>
                            <el-pagination
                                layout="prev, pager, next"
                                @current-change="handleCurrentChange1"
                                :total="cerPage">
                            </el-pagination>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="持证人：">
                        <el-input v-model='filter.name' placeholder='输入持证人' clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='search'>查询</el-button>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            v-loading="listLoading"
            :cell-style="rowClass"
            highlight-current-row
            style="width: 100%">
            <el-table-column prop="CertificateName" align="center" label="证书名称"></el-table-column>
            <el-table-column prop="CertificateNumber" label="证书编号" align="center"></el-table-column>
            <el-table-column prop="CertificateHolderName" label="持证人" align="center"></el-table-column>
            <el-table-column prop="CertificateHolderPhoneNumer" label="手机" align="center"></el-table-column>
            <el-table-column prop="CertificateRegisterDate" :formatter="formatCreateTime" align="center" label="登记日期"></el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
    </div>
</template>

<script>
import {getCertificateRegisterBySocialUnitIDListPage,getCertificateInfoListPage} from '@/api/api'
import util from "../../../util/date";
export default {
    data() {
        return {
            filter: {
                name: '',
                certificateInfo: '',
            },
            listLoading: false,
            tableData: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            CertificateInfoArr: [],
            cerPage: 0,
        }        
    },
    methods: {
        // 查询
        search() {
            this.getListData();
        },
        // 返回
        back(){
            this.$router.go(-1)
        },
        //   表格样式处理
        rowClass ({columnIndex}) {
            return 'padding:8px 0!important;'
        },
        // 获取证书设计
        getCertificateListPage(params) {
            getCertificateInfoListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.CertificateInfoArr = result.Response.Data
                    this.cerPage = result.Response.DataCount
                }
            })
        },
        // 获取列表
        getListData() {
            var _this = this
            var params = {
                socialUnitID: this.$route.query.id,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filter.name){
                params.certHolderName = this.filter.name
            }
            if(this.filter.certificateInfo){
                params.certificateDesignID = this.filter.certificateInfo
            }
            this.listLoading = true;
            getCertificateRegisterBySocialUnitIDListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    _this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.tableData = result.Response.Data
                }
            })
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
        handleCurrentChange1(val) {
            var params = {
                pageIndex: val,
            }
            this.getCertificateListPage(params);
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CertificateRegisterDate || row.CertificateRegisterDate == ""
            ? ""
            : util.formatDate.format(new Date(row.CertificateRegisterDate), "yyyy-MM-dd hh:mm");
        },
    },
    created() {
        this.getCertificateListPage();
    },
    mounted() {
        this.getListData();
    }
}
</script>

<style lang="stylus" scoped>

</style>