<template>
    <div class="pageBox">
        <el-row v-if="isShowBack">
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item>
                        <el-button type='warning' size="small" @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div class="pageTopBox">
            <div class="topBoxChi">
                <div class="topBoxTitle">总人数</div>
                <div class="topBoxPerNum">{{topSumData ? topSumData.StaffSumNum : 0}}</div>
                <div class="topBoxUnitNum">单位数 {{topSumData ? topSumData.UnitSumNum : 0}}</div>
            </div>
            <div class="topBoxChi">
                <div class="topBoxTitle">数据中台来源人数</div>
                <div class="topBoxPerNum">{{topSumData ? topSumData.SxptStaffSumNum : 0}}</div>
                <div class="topBoxUnitNum">单位数 {{topSumData ? topSumData.SxptUnitSumNum : 0}}</div>
            </div>
            <div class="topBoxChi">
                <div class="topBoxTitle">单位填报人数</div>
                <div class="topBoxPerNum">{{topSumData ? topSumData.UnitFillStaffSumNum : 0}}</div>
                <div class="topBoxUnitNum">单位数 {{topSumData ? topSumData.UnitFillUnitSumNum : 0}}</div>
            </div>
            <div class="topBoxChi">
                <div class="topBoxTitle">自主报考人数</div>
                <div class="topBoxPerNum">{{topSumData ? topSumData.FillStaffBySelf : 0}}</div>
                <div class="topBoxUnitNum">单位数 {{topSumData ? topSumData.FillUnitBySelf : 0}}</div>
            </div>
            <div class="topBoxChi">
                <div class="topBoxTitle">大数据甄选人数</div>
                <div class="topBoxPerNum">{{topSumData ? topSumData.HadoopChooseStaffSumNum : 0}}</div>
                <div class="topBoxUnitNum">单位数 {{topSumData ? topSumData.HadoopChooseUnitSumNum : 0}}</div>
            </div>
        </div>
        <div>
            <div class="timerBox">
                <el-date-picker
                    v-model="tiemrData"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
            </div>
            <!-- 表格 -->
            <el-table 
                v-loading="tableLoading"
                :data="tableData" 
                style="width:100%" 
                border 
                ref="multipleTable"
                highlight-current-row
                :cell-style='rowClass'
            >
                <el-table-column  label="日期" min-width="130" align="center" prop="Date" :formatter="formatCreateTime1"></el-table-column>
                <el-table-column  label="总人数" min-width="100" align="center" prop="StaffSumNum"></el-table-column>
                <el-table-column  label="总单位数" min-width="100" align="center" prop="UnitSumNum"></el-table-column>
                <el-table-column  label="数据中台来源人数" min-width="140" align="center" prop="SxptStaffNum"></el-table-column>
                <el-table-column  label="数据中台来源单位数" min-width="160" align="center" prop="SxptUnitNum"></el-table-column>
                <el-table-column label="单位填报人数" min-width="120" align="center" prop="UnitFillStaffNum"></el-table-column>
                <el-table-column label="单位填报单位数" min-width="130" align="center" prop="UnitFillUnitNum"></el-table-column>
                <el-table-column label="自主报考人数" min-width="130" align="center" prop="FillStaffBySelfNum"></el-table-column>
                <el-table-column  label="自主报考单位数" min-width="130" align="center" prop="FillUnitBySelfNum"></el-table-column>
                <el-table-column  label="大数据甄选人数" min-width="130" align="center" prop="HadoopChooseStaffNum"></el-table-column>
                <el-table-column  label="大数据甄选单位数" min-width="140" align="center" prop="HadoopChooseUnitNum"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { getNewListSumData,getNewListAddDetail } from '@/api/api'
import util from "../../../util/date";
export default {
    data() {
        return{
            topSumData: "",
            tiemrData: "",
            tableLoading: false,
            tableData: [],
            // 设置当天-之前30天时间可选
            pickerOptions:{
                disabledDate(time){
                	return time.getTime() < (Date.now()-(24 * 60 * 60 * 1000 * 31)) || time.getTime() > (Date.now());
                }
            },
            isShowBack: false,
        }
    },
    methods: {
        // 返回
        back(){
            this.$router.go(-1)
        },
        getTopDetailFun() {
            var params = {

            }
            getNewListSumData(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.topSumData = result.Response
                }
            })
        },
        getTableDetailFun() {
            var params = {
                startDate: this.formatCreateTime2(this.tiemrData[0]),
                endDate: this.formatCreateTime2(this.tiemrData[1]),
            }
            this.tableLoading = true
            getNewListAddDetail(params).then(res => {
                var result = res.data
                if(result.Success) {
                    var newObj = {
                        Date: "合计",
                        FillStaffBySelfNum: 0,
                        FillUnitBySelfNum: 0,
                        HadoopChooseStaffNum: 0,
                        HadoopChooseUnitNum: 0,
                        StaffSumNum: 0,
                        SxptStaffNum: 0,
                        SxptUnitNum: 0,
                        UnitFillStaffNum: 0,
                        UnitFillUnitNum: 0,
                        UnitSumNum: 0,
                    }
                    if(result.Response && result.Response.length > 0) {
                        result.Response.forEach(item => {
                            newObj.FillStaffBySelfNum += item.FillStaffBySelfNum
                            newObj.FillUnitBySelfNum += item.FillUnitBySelfNum
                            newObj.HadoopChooseStaffNum += item.HadoopChooseStaffNum
                            newObj.HadoopChooseUnitNum += item.HadoopChooseUnitNum
                            newObj.StaffSumNum += item.StaffSumNum
                            newObj.SxptStaffNum += item.SxptStaffNum
                            newObj.SxptUnitNum += item.SxptUnitNum
                            newObj.UnitFillStaffNum += item.UnitFillStaffNum
                            newObj.UnitFillUnitNum += item.UnitFillUnitNum
                            newObj.UnitSumNum += item.UnitSumNum
                        });
                    }
                    result.Response.push(newObj)
                    this.tableData = result.Response.reverse()
                }
                this.tableLoading = false
            })
        },
        // 列表样式
        rowClass(){
            return 'padding: 8px 0!important;'
        },
        formatCreateTime:function(){
            var today = new Date();
            var nowDay = new Date(today);
            nowDay.setDate(today.getDate() - 0);
            var day = String(nowDay.getDate()).length == 2 ? nowDay.getDate() : ('0' + nowDay.getDate())
            var nowDayTime = nowDay.getFullYear() + "-" + (nowDay.getMonth() + 1) + "-" + day

            var preTimer30 = new Date(today);
            preTimer30.setDate(today.getDate() - 30);
            var day = String(preTimer30.getDate()).length == 2 ? preTimer30.getDate() : ('0' + preTimer30.getDate())
            var preTimer30Time = preTimer30.getFullYear() + "-" + (preTimer30.getMonth() + 1) + "-" + day

            var preTimer10 = new Date(today);
            preTimer10.setDate(today.getDate() - 10);
            var day = String(preTimer10.getDate()).length == 2 ? preTimer10.getDate() : ('0' + preTimer10.getDate())
            var preTimer10Time = preTimer10.getFullYear() + "-" + (preTimer10.getMonth() + 1) + "-" + day

            this.tiemrData = [preTimer10Time,nowDayTime]
            // this.getTableDetailFun()
        },
        //时间格式化
        formatCreateTime1: function(row) {
            return !row.Date || row.Date == ""
            ? ""
            : row.Date == "合计" ? "合计" : util.formatDate.format(new Date(row.Date), "yyyy-MM-dd");
        },
        formatCreateTime2: function(row) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
    },
    watch: {
        'tiemrData': function(val) {
            this.getTableDetailFun()
        }
    },
    created(){

    },
    mounted(){
        if(this.$route.query.isShowBack) {
            this.isShowBack = this.$route.query.isShowBack
        }
        this.getTopDetailFun()
        this.formatCreateTime()
    }
}
</script>

<style lang="stylus" scoped>
.pageBox {
    overflow: auto!important;
    height: 100%!important;
}
.pageTopBox {
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: 0 auto;
}
.topBoxChi {
    line-height: 34px;
}
.topBoxPerNum {
    font-weight: bold;
    font-size: 26px;
    margin-top: 10px;
}
.topBoxUnitNum {
    font-size: 14px;
}
.topBoxTitle {
    font-size: 18px;
}
.timerBox {
    display: flex;
    justify-content: flex-end;
    margin: 15px auto;
    width: 97%;
}
</style>