import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import otherLogin from '../views/otherLogin.vue'
import CallBackLogin from '../views/CallBackLogin.vue'
import FixedTestPaper from '../views/ExamManage/fixedTestPaper.vue'
import EnterDetails from '../views/CertificateManager/EnterDetails.vue'
import EnterNoticeResult from '../views/NoticeManage/EnterNoticeResult.vue'
import CrowdList from '../views/LicensedSupervision/CrowdList.vue'
import UnitList from '../views/LicensedSupervision/UnitList.vue'
import NoticCrowdList from '../views/LicensedSupervision/NoticCrowdList.vue'
import YingxunList from '../views/LicensedSupervision/YingxunList.vue'
import SendNoteList from '../views/LicensedSupervision/SendNoteList.vue'
import NewMenulistDetails from '../views/NotificationEffect/NewMenulistDetails.vue'
import FailedSMSresend from '../views/NotificationEffect/FailedSMSresend.vue'
import EnterPhoneDetail from '@/views/NoticeManage/EnterPhoneDetail.vue'
import SocialUnitDetail from '@/views/LicensedSupervision/SocialUnitDetail.vue'
import ApplyRecordDetails from '@/views/StudentExaminee/ApplyRecordDetails.vue'
import Welcome from '../views/Welcome'
import NoPage from '../views/404'
import Layout from "../views/Layout/Layout";
import download from '../views/download.vue'
import operation from '../views/operation.vue'
import protocol from '../views/protocol.vue'
import examKnow from '../views/examKnow.vue'
import fireContent from '../views/fireContent.vue'
import systemIntroduction from '../views/systemIntroduction.vue'
import systemVideo from '../views/systemVideo.vue'
import appLogin from '../views/appLogin.vue'
import inputMessage from '../views/inputMessage.vue'
import noAuthority from '../views/noAuthority.vue'
import openAppByHttp from '../views/openAppByHttp.vue'
import Router from 'vue-router'
import yinsiPolicy from "@/views/yinsiPolicy.vue";
import zhuceProto from "@/views/zhuceProto.vue";


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch((err) => err);

};

const createRouter = () => new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/404', component: NoPage, name: 'NoPage',
            meta: {
                title: 'NoPage',
                requireAuth: false,
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        }, 
        {
            path: '/',
            component: Welcome,
            name: '工作台主页面',
            iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                title: '工作台主页面',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/login',
            component: Login,
            name: 'login',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '登录',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/otherlogin',
            component: otherLogin,
            name: 'otherlogin',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '登录',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/callBackLogin',
            component: CallBackLogin,
            name: 'callBackLogin',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '修改密码',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/fixedTestPaper',
            component: FixedTestPaper,
            name: '考试管理 / 试卷管理',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/EnterDetails',
            component: EnterDetails,
            name: '证书管理 / 证书登记',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/EnterNoticeResult',
            component: EnterNoticeResult,
            name: '通知管理 / 通知记录',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/EnterPhoneDetail',
            component: EnterPhoneDetail,
            name: '通知管理 / 号码库',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/SocialUnitDetail',
            component: SocialUnitDetail,
            name: '持证监管 / 社会单位明细',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/ApplyRecordDetails',
            component: ApplyRecordDetails,
            name: '免赔考试管理 / 考试申请审核',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/CrowdList',
            component: CrowdList,
            name: '持证监管 / 人群管理',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/UnitList',
            component: UnitList,
            name: '持证监管 / 通知管理',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/NoticCrowdList',
            component: NoticCrowdList,
            name: '持证监管 / 通知管理',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/YingxunList',
            component: YingxunList,
            name: '持证监管 / 人群管理',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/SendNoteList',
            component: SendNoteList,
            name: '持证监管 / 通知管理',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/NewMenulistDetails',
            component: NewMenulistDetails,
            name: '通知效果跟踪 / 新名单新增明细',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/FailedSMSresend',
            component: FailedSMSresend,
            name: '通知效果跟踪 / 回执失败短信重发',
            // iconCls: 'fa-qq',//图标样式class
            // hidden: true,
            meta: {
                // title: '固定试卷',
                requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
            }
        },
        {
            path: '/d',
            component: download,
            name: 'download',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '下载',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/f',
            component: operation,
            name: 'operation',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '介绍',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/p',
            component: protocol,
            name: 'protocol',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '协议',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/k',
            component: examKnow,
            name: 'examKnow',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '考试须知',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/n',
            component: fireContent,
            name: 'fireContent',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '通知公告',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/c',
            component: systemIntroduction,
            name: 'systemIntroduction',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '系统介绍',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/y',
            component: yinsiPolicy,
            name: 'yinsiPolicy',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '系统介绍',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/z',
            component: zhuceProto,
            name: 'zhuceProto',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '系统介绍',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/v',
            component: systemVideo,
            name: 'systemVideo',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '视频播放',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/l',
            component: appLogin,
            name: 'appLogin',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '登录注册',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/i',
            component: inputMessage,
            name: 'inputMessage',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '录入信息',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/no',
            component: noAuthority,
            name: 'noAuthority',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '暂无权限',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
        {
            path: '/o',
            component: openAppByHttp,
            name: 'openAppByHttp',
            iconCls: 'fa-address-card',//图标样式class
            meta: {
                title: '下载app',
                NoTabPage: true,
                NoNeedHome: true // 添加该字段，表示不需要home模板
            },
            hidden: true
        },
    ]
})

const router = createRouter()

export function filterAsyncRouter(asyncRouterMap) {
  //注意这里的 asyncRouterMap 是一个数组
  const accessedRouters = asyncRouterMap.filter(route => {
      if (route.path && !route.IsButton) {
          if (route.path === '/' || route.path === '-') {//Layout组件特殊处理
              route.component = Layout
          } else {
              try {
                  route.component = _import(route.path.replace('/:id',''))
              } catch (e) {
                  try {
                      route.component = () => import('@/views' + route.path.replace('/:id','') + '.vue');
                  } catch (error) {
                      console.info('%c 当前路由 ' + route.path.replace('/:id','') + '.vue 不存在，因此如法导入组件，请检查接口数据和组件是否匹配，并重新登录，清空缓存!', "color:red")
                  }
              }
          }
      }
      if (route.children && route.children.length && !route.IsButton) {
          route.children = filterAsyncRouter(route.children)
      }
      return true
  })

  return accessedRouters
}

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

router.$addRoutes = (params) => {

  var f = item => {
      
      if (item['children']) {
          item['children'] = item['children'].filter(f);
          return true;
      } else if (item['IsButton']) {
          return item['IsButton']===false;
      }  else {
          return true;
      }
      
  }
    
    // const filterRoutes = (item) => {
    //     if (item.children) {
    //         item.children = item.children.filter(filterRoutes);
    //         return item.children.length > 0; // 保留有子路由的项
    //     } else {
    //         return !item.IsButton; // 过滤掉 IsButton 为 true 的项
    //     }
    // };
  
  var params = params.filter(f);

  router.addRoutes(params)
}

export default router
