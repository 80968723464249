<template>
    <div class="operationPage" id="operationPage">
        <div class="introduce">
            <div class="row clearfix">
                <div class="col left" tabindex="0" wzades="索引号：006939748/2019-01304">
                    <label>索引号：</label>
                    <span>006939748/2019-01304</span>
                </div>
                <div class="col left" tabindex="0" wzades="分类：科技、教育">
                    <label>分类：</label>
                    <span>科技、教育</span>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col left" tabindex="0" wzades="发布机构：广东省人民政府办公厅">
                    <label>发布机构：</label>
                    <span>广东省人民政府办公厅</span>
                </div>
                <div class="col left" tabindex="0" wzades="成文日期：2019-08-07">
                    <label>成文日期：</label>
                    <span>2019-08-07</span>
                </div>
            </div>
            <div class="row">
                <div class="col allLeft" tabindex="0" wzades="标题：广东省人民政府办公厅关于印发广东省职业技能提升行动实施方案（2019-2021年）的通知">
                    <label>标题：</label>
                    <span>广东省人民政府办公厅关于印发广东省职业技能提升行动实施方案（2019-2021年）的通知</span>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col left" tabindex="0" wzades="文号：粤府办〔2019〕14号">
                    <label>文号：</label>
                    <span>粤府办〔2019〕14号</span>
                </div>
                <div class="col left" tabindex="0" wzades="发布日期：2019-08-16">
                    <label>发布日期：</label>
                    <span>2019-08-16</span>
                </div>
            </div>
        </div>
        <div class="zwPage">
            <p class="zwTitle" style="text-align: center;"><strong>广东省人民政府办公厅关于印发</strong></p>
            <p class="zwTitle" style="text-align: center;"><strong>广东省职业技能提升行动</strong></p>
            <p class="zwTitle" style="text-align: center;"><strong>实施方案（2019-2021年）的通知</strong></p>
            <p class="zwTitleTimer" style="text-align: center;">粤府办〔2019〕14号</p>
            <p class="zwTips" style="text-align: justify;">各地级以上市人民政府，省政府各部门、各直属机构：</p>
            <p style="text-align: justify;">　　《广东省职业技能提升行动实施方案（2019-2021年）》已经省人民政府同意，现印发给你们，请认真贯彻落实。实施中遇到的问题，请径向省人力资源社会保障厅反映。</p>
            <p class="zwlk" style="text-align: right;">广东省人民政府办公厅</p>
            <p style="text-align: right;">2019年8月7日</p>

            <p class="zwSencTitle" style="text-align: center;"><strong>广东省职业技能提升行动实施方案</strong></p>
            <p class="zwTimer" style="text-align: center;"><strong>（2019－2021年）</strong></p>
            <p style="text-align: justify;">&nbsp; &nbsp; &nbsp; &nbsp;为深入贯彻习近平新时代中国特色社会主义思想和党的十九大精神，深入贯彻习近平总书记对广东重要讲话和重要指示批示精神，全面提升我省劳动者职业技能水平和就业创业能力，根据《国务院办公厅关于印发职业技能提升行动方案（2019-2021年）的通知》（国办发〔2019〕24号），结合我省实际，制定本方案。</p>
            <p class="zwContentTitle" style="text-align: justify;">　　<strong>一、工作目标</strong></p>
            <p style="text-align: justify;">　　2019-2021年全省共开展各类补贴性职业技能培训260万人次以上，其中2019年培训70万人次以上。到2021年底，全省技能劳动者占就业人员总量的比例达25%以上，高技能人才占技能人才的比例达35%以上，基本建立覆盖城乡劳动者的终身职业技能培训制度，劳动者整体技能素质明显提升，技能人才待遇明显提高，技能人才政策环境明显改善，为广东实现“四个走在全国前列”、当好“两个重要窗口”提供坚实的技能人才支撑。</p>
            <p class="zwContentTitle" style="text-align: justify;">　<strong>　二、实施重点群体职业技能提升工程</strong></p>
            <p style="text-align: justify;">　　<strong>（一）实施新生代产业工人培养工程。</strong>结合新一代信息技术、高端装备制造、绿色低碳、人工智能、生物医药、数字经济、新材料、海洋经济等战略性新兴产业、先进制造业发展和现代化经济体系建设需求，大力培养复合型高素质技能人才，全面提升新生代产业工人的综合素质和技能水平。积极引导帮助中小微企业开展职工技能提升培训，深入实施新生代产业工人（含港澳青年）“圆梦计划”，建设适应现代产业发展需要的南粤工匠队伍。(省人力资源社会保障厅、工业和信息化厅，团省委分工负责，省教育厅、科技厅、国资委、总工会等参与)</p>
            <p style="text-align: justify;">　　<strong>（二）实施“粤菜师傅”工程。</strong>以广府菜、客家菜、潮汕菜等粤菜系列为重点，大规模开展“粤菜师傅”职业技能培训，提升粤菜烹饪技能人才培养能力和质量，打造“粤菜师傅”文化品牌。到2021年，全省开展“粤菜师傅”培训5万人次以上，直接带动30万人实现就业创业。（省人力资源社会保障厅负责，省委宣传部，省教育厅、农业农村厅、商务厅、文化和旅游厅、市场监管局、供销合作社等参与）</p>
            <p style="text-align: justify;">　　<strong>（三）实施“南粤家政”工程。</strong>围绕新形势下“一老一小”对家政服务的迫切需求，以母婴服务、居家服务、养老服务、医疗护理服务等领域为重点，突出标准制定、技能培训、职业评价、促进就业、诚信建设、品牌创建等，推动我省家政服务业提质扩容，努力实现家政服务从业人员素质有提升、就业有渠道、权益有保障。到2021年，全省开展家政服务类培训60万人次以上。（省人力资源社会保障厅、商务厅分工负责，省发展改革委、民政厅、农业农村厅、卫生健康委、总工会、妇联等参与）</p>
            <p style="text-align: justify;">　　<strong>（四）实施企业学徒培养工程。</strong>全面推行企业新型学徒制和现代学徒制，推动企业与职业院校（含技工院校，下同）深入合作，联合培养与粤港澳大湾区产业发展相适应的技能人才。到2021年，全省开展企业新型学徒制培训8万人次。（省人力资源社会保障厅、教育厅分工负责，省发展改革委等参与）</p>
            <p style="text-align: justify;">　　<strong>（五）实施企业职工转岗转业培训工程。</strong>发挥行业协会、龙头企业等培训主体作用，对受经济环境等影响的困难企业职工开展提升职业技能和转岗转业能力的各类培训，帮助困难企业稳定职工队伍，促进再就业。对有就业意愿和培训需求的失业人员开展针对性的职业技能培训。（省人力资源社会保障厅、工业和信息化厅、国资委分工负责，省发展改革委、商务厅等参与）</p>
            <p style="text-align: justify;">　　<strong>（六）实施农村劳动力精准培训工程。</strong>继续实施农民工“春潮行动”“求学圆梦行动”“新生代农民工职业技能提升计划”“返乡创业培训计划”“领头雁农村青年致富带头人培育计划”和乡村工匠培训等专项培训。实施“新型职业农民培育工程”和“农村实用人才带头人素质提升计划”。完善省内省际结对帮扶机制和贫困劳动力建档立卡信息系统，对农村贫困劳动力（含贫困家庭子女)开展适应就业需求的职业教育、技工教育和职业技能培训，按规定给予补助。积极培养贫困村产业发展带头人和创业致富带头人。广泛开展各类农村电商培训。（省人力资源社会保障厅、教育厅、农业农村厅，团省委分工负责，省民政厅、省供销合作社、省总工会、人民银行广州分行等参与）</p>
            <p style="text-align: justify;">　　<strong>（七）实施退役军人职业技能培训工程。</strong>大力实施退役军人全员适应性培训与就业创业技能培训，制定技能培训具体专业（工种）目录，鼓励退役军人参加职业技能鉴定并考取相关技能证书，探索建立退役军人定点培训基地。到2021年，退役军人参训人员职业技能证书获取率达到90%以上。（省退役军人事务厅负责，省教育厅、人力资源社会保障厅等参与）</p>
            <p style="text-align: justify;">　　<strong>（八）实施城乡未继续升学初高中毕业生教育培训工程。</strong>全面摸清城乡未继续升学初高中毕业生（以下称“两后生”）生源情况，根据地区就业市场需求和“两后生”的教育培训意愿，分类组织“两后生”精准对接职业院校，采取长、中、短相结合的教育培训模式，提高“两后生”职业素质和技能水平，基本消除“两后生”无技能从业现象。（省人力资源社会保障厅、教育厅分工负责）</p>
            <p style="text-align: justify;">　　<strong>（九）实施安全技能提升工程。</strong>以大力推进高危行业领域安全技能提升行动计划为重点，开展化工、矿山等高危行业领域的企业全员安全技能培训，特别是企业经营者和新工人的安全技能培训及各类特种作业人员安全操作资格培训。鼓励高危企业集中的地区建设安全生产和技能实训基地。持续开展安全培训专项执法监察，确保全省高危行业企业从业人员的安全技能培训覆盖率达到100%，从业人员安全生产意识和技能得到全面提高。（省应急管理厅负责，省工业和信息化厅、人力资源社会保障厅、住房城乡建设厅、市场监管局等参与）</p>
            <p style="text-align: justify;">　　<strong>（十）实施残疾人职业技能提升工程。</strong>以实施推进残疾人职业技能提升计划为重点，针对各类残疾人开展职业技能培训、雇主培训等专项培训。到2021年，全省新增培训3.6万人，各类残疾人接受培训的机会明显增加。（省残联负责，省人力资源社会保障厅、总工会、妇联等参与）</p>
            <p class="zwContentTitle" style="text-align: justify;">　　<strong>三、有效提升培训主体供给能力</strong></p>
            <p style="text-align: justify;">　　<strong>（十一）推动企业扩大培训规模。</strong>各类企业要制定实施企业新录用员工和转岗转业职工的适岗能力培训计划、岗位技能提升培训计划，大力开展高技能人才培训，组织实施高技能领军人才和产业紧缺人才境外培训。支持企业设立职工培训中心、高技能人才培训基地和技能大师工作室，企业可通过职工教育经费提供相应的资金支持，并按规定享受就业补助资金。深化产教融合，支持企业与职业院校共建实训中心、教学工厂等，建成一批产教融合型企业。鼓励企业与参训职工协商一致灵活调整工作时间，保障职工参训期间应有的工资福利待遇。（省国资委、工业和信息化厅、人力资源社会保障厅分工负责，省发展改革委、教育厅、财政厅、住房城乡建设厅、市场监管局、总工会等参与）</p>
            <p style="text-align: justify;">　　<strong>（十二）支持院校扩大培训供给。</strong>支持职业院校以职工、就业重点群体和贫困劳动力为重点对象开展补贴性培训。职业院校承担面向社会职业技能培训的收入在合理扣除直接成本后，可按不超过60%的比例提取补充单位绩效工资，在核定的绩效工资总量之外单列管理。允许职业院校将一定比例的培训收入纳入学校公用经费，学校培训工作量可按一定比例折算成全日制学生培养工作量，具体比例由各地结合实际确定。职业院校在内部分配时，应向承担职业技能培训工作的一线教师倾斜并予以奖补。（省人力资源社会保障厅、教育厅分工负责，省财政厅、总工会等参与）</p>
            <p style="text-align: justify;">　　<strong>（十三）拓展职业技能培训内容。</strong>各地根据本地产业发展和市场需求，制定并定期发布企业紧缺急需职业（工种）目录，对获得目录中职业（工种）高级工以上职业资格、职业技能等级人员，劳动力职业技能提升补贴和失业保险技能提升补贴均可在规定标准基础上提高30%。将通用职业素质、质量意识、法律意识、安全环保、健康卫生、求职能力及就业创业指导等综合性培训内容贯穿培训全过程。（省人力资源社会保障厅负责，省财政厅等参与）</p>
            <p style="text-align: justify;">　　<strong>（十四）大力发展互联网职业技能培训。</strong>大力推广“工学一体化”“职业培训包”“互联网+”等先进培训方式，推动云计算、大数据、移动智能终端等在职业技能培训领域的应用，提高培训便利度和可及性。以远程职业培训网建设为重点，支持建设互联网培训平台，开展前沿技术知识更新培训和行业信息人才培训，搭建行业信息领军人才交流平台。（省人力资源社会保障厅、教育厅分工负责，省政务服务数据管理局等参与）</p>
            <p style="text-align: justify;">　　<strong>（十五）完善职业技能培训考核评价体系。</strong>加快完善职业资格评价、职业技能等级评价与认定、专项职业能力考核及国家认可的特种作业培训等考核评价方式。加强安全生产资格考试体系建设。建立职业技能培训合格证书制度，成立省、市两级职业技能培训课程标准技术委员会；制订职业技能培训课程标准，经委员会备案后纳入培训补贴范围，具体办法由省人力资源社会保障厅另行制定。（省人力资源社会保障厅、住房城乡建设厅、应急管理厅分工负责，省教育厅等参与）</p>
            <p style="text-align: justify;">　　<strong>（十六）提升职业技能培训基础能力。</strong>各地要对公共职业技能培训基础平台建设及其合作方式加强引导。有条件的地区要对企业、院校、培训机构的实训设施设备升级改造予以支持。支持社会培训和评价机构发展，建立同业交流平台。完善专兼职教师制度，职业院校和培训机构可按规定自主招聘企业技能人才任教。完善补贴性培训实名制信息管理系统，加快建立劳动者职业培训电子档案，实现培训评价信息与就业社保信息联通共享。推进网上全流程办理，简化补贴申领程序，及时提供各类技能培训服务。（省人力资源社会保障厅、教育厅分工负责，省发展改革委、财政厅、住房城乡建设厅、应急管理厅等参与）</p>
            <p class="zwContentTitle" style="text-align: justify;">　　<strong>四、完善职业培训补贴政策</strong></p>
            <p style="text-align: justify;">　　<strong>（十七）落实和完善职业技能培训补贴政策。</strong>全面梳理现有职业技能培训补贴政策，确保按规定给予各类重点群体免费职业培训或职业培训补贴，并按规定给予生活费补贴（含交通费）。对企业自主开展或职业院校、培训机构受委托开展项目制培训的，可先行拨付50%的培训补贴资金。各地要重点对受经济环境等影响的企业职工、去产能失业人员、退役军人、贫困劳动力等群体开展项目制培训。劳动者在户籍地、常住地、求职就业地参加培训（或自学）后取得相应证书的，按规定给予职业培训补贴，原则上每人每年可享受不超过3次，同一职业同一等级不可重复享受。鼓励各地对退役军人在享受普惠性就业创业扶持政策和公共服务基础上再给予特殊优待。有条件的地方可建立残疾人创业帮扶基金。对各地组织的专项职业能力考核不收取考核费，组织机构可按规定申请考核补贴。（省人力资源社会保障厅、财政厅分工负责，省发展改革委、教育厅、住房城乡建设厅、农业农村厅、退役军人事务厅、应急管理厅、残联等参与）</p>
            <p style="text-align: justify;">　　<strong>（十八）加大资金支持和监管力度。</strong>各级政府要加大对就业补助资金、地方人才经费、行业产业发展经费和失业保险基金结余提取资金的筹集整合力度，对有关部门各类培训资金和项目进行优化整合，统筹用于职业技能提升行动。企业要按有关规定足额提取和使用职工教育经费，其中60％以上用于一线职工培训，可用于企业“师带徒”津贴补助。各地要落实将企业职工教育经费税前扣除限额提高至工资薪金总额8％的税收政策。推动企业提取职工教育经费开展自主培训与享受政策开展补贴性培训有效衔接，探索完善相关机制。各地可安排经费或通过政府购买服务方式，对职业技能提升组织实施、培训课程标准及教材开发、师资培训、教学改革、考核认定及督导、职业技能竞赛及其他相关基础工作给予支持，对培训组织动员工作进行奖补。鼓励各地将财政补助资金与培训工作绩效挂钩，加大激励力度。加强资金监督检查、专项审计和风险防控，定期向社会公开资金使用情况。（省人力资源社会保障厅、财政厅、审计厅分工负责）</p>
            <p class="zwContentTitle" style="text-align: justify;">　　<strong>五、加强组织保障</strong></p>
            <p style="text-align: justify;">　　<strong>（十九）强化组织领导。</strong>各级政府要把职业技能提升行动作为重要民生工程，纳入当地经济社会发展总体布局。在省就业工作领导小组框架下，建立健全职业技能提升行动工作协调机制，加强统筹协调，推动工作落实。十项重点群体职业技能提升工程排第一位的责任部门，要牵头制定具体实施方案，细化工作措施；相关责任部门要积极配合，分工协作，形成工作合力。建立工作季报和年报制度，加强统计分析和监测研判，及时推动政策完善。</p>
            <p style="text-align: justify;">　　<strong>（二十）加强宣传引导。</strong>各地各有关部门要依托互联网、基层公共服务平台等，广泛宣传职业技能提升行动各项政策和实践成果，加强宣传解读，提高政策知晓度和惠及面。各地要大力弘扬劳模精神和工匠精神，进一步提高技术工人待遇，加强技能人才激励表彰，总结推广典型经验做法，带动整体工作取得新进展。</p>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    methods: {
        
    },
    mounted() {
        var width = document.getElementById("operationPage").offsetWidth
        if(width > 600) {
            $(".clearfix").css("flex-direction","row")
        }
    }
}
</script>
<style scoped>
    .operationPage {
        width: 95%;
        margin: 10px auto 0;
        padding-bottom: 20px;
    }
    .introduce {
        padding: 15px 0;
        border: solid 1px #aed4f5;
    }
    .clearfix {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    .left {
        width: 90%;
        max-width: 500px;
        margin: 5px 3%;
        font-size: 14px;
        font-family: 微软雅黑, serif;
    }
    .left label {
        font-weight: bold;
        font-size: 15px;
    }
    .allLeft {
        width: 95%;
        margin: 12px 0;
        padding: 0 3%;
        font-size: 14px;
        font-family: 微软雅黑, serif;
    }
    .allLeft label {
        font-weight: bold;
        font-size: 15px;
    }
    .zwPage {
        border: solid 1px #aed4f5;
        margin-top: 20px;
        padding: 0 3% 20px;
    }
    .zwTitle {
        margin-top: 0px;
    }
    .zwTitleTimer {
        margin-top: 25px;
    }
    .zwTips {
        margin: 45px 0 25px;
    }
    .zwlk {
        margin: 45px 0 10px;
    }
    .zwSencTitle {
        margin: 45px 0 25px;
    }
    .zwTimer {
        margin-bottom: 55px;
    }
    .zwContentTitle {
        margin: 15px 0;
        font-weight: bold;
    }
    p {
        line-height: 30px;
        font-family: 微软雅黑, serif;
        margin-top: 10px;
    }
</style>