<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <!-- <el-form-item label="审核状态:" placeholder="请选择审核状态">
                    <el-select v-model="filters.reviewStatus" clearable>
                        <el-option 
                            v-for="item in reviewStatusArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <el-button @click="backPages()" type="warning">返回</el-button>
                    <el-button @click="getExamApplyRecordList()" type="primary">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading"
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row 
            @current-change="handlerowChange"
        >
            <el-table-column type='index' label='序号' align="center" width='60' :index='indexMethod'></el-table-column>
            <el-table-column label="姓名" width="" align="center" prop="Person"></el-table-column>
            <el-table-column label="手机号" width="" align="center" prop="PhoneNumber"></el-table-column>
            <el-table-column  label="审核状态" width="" align="center" prop="SendStatus">
                <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.SendStatus == 1">发送成功</el-tag>
                    <el-tag :type="'warning'" v-else-if="scope.row.SendStatus == 2">发送失败</el-tag>
                    <el-tag :type="'info'" v-else-if="scope.row.SendStatus == 4">发送中</el-tag>
                    <el-tag v-else>未发送</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="" align="center" prop="CreateTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <el-pagination
                small
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                :current-page="pages.pageIndex"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
                @size-change="handleSizeChange" 
                @current-change="handleCurrentChange"
                class="pageBar"       
                >
            </el-pagination>
        </el-col>
    </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import Toolbar from "../../components/Toolbar";
import router from '../../router/index';
// 数据请求
import { getExamBatchMsgDetailRecordList,reviewBatchMsg, } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            redirect: "",
            ID: "",
            filters:{
                reviewStatus: '',
            },
            reviewStatusArr: [
                {value: 0,name: "未审核"},
                {value: 1,name: "审核通过"},
                {value: 2,name: "审核驳回"},
            ],
            // 表格
            tableData: [],
            tableLoading: false,
            currentRow: '',
            // 分页
            pages:{
                pageArr:[20,40,80,100],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
        }
    },
    methods: {
        // 返回
        backPages() {
            this.$router.replace("/StudentExaminee/ExamBatchMsgApplyRecord");
        },
        // 查询
        getExamApplyRecordList() {
            this.pages.pageIndex = 1
            this.getTableDataFun();
        },
        // 列表
        getTableDataFun() {
            var params = {
                sendStatus: this.redirect,
                applyId: this.ID,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.tableLoading = true
            getExamBatchMsgDetailRecordList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                }
            })
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        currentChange(val) {
            this.brandListPage.pageIndex = val
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        }, 
    },
    created(){
        var _this = this
        if(_this.$route.query.redirect !== ''){
            _this.redirect = _this.$route.query.redirect
        }
        if(_this.$route.query.ID !== ''){
            _this.ID = _this.$route.query.ID
        }
        this.getTableDataFun();
    },
    mounted(){
        
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>