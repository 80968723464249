<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <!-- <el-form-item>
                        <el-select v-model="filter.province" placeholder="请选择省" clearable>
                            <el-option
                                v-for="item in provinceArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-select v-model="filter.city" placeholder="请选择市" clearable>
                            <el-option
                                v-for="item in cityArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filter.county" placeholder="请选择区/县" clearable>
                            <el-option
                                v-for="item in countyArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关键字:">
                        <el-input v-model='filter.name' placeholder='请输入单位名称/姓名/手机号' clearable></el-input>
                    </el-form-item>
                    <el-form-item label="岗位:" placeholder="请选择岗位">
                        <el-select v-model="filter.station" clearable>
                            <el-option 
                                v-for="item in stationArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行业:" placeholder="请选择行业">
                        <el-select v-model="filter.trade" clearable>
                            <el-option 
                                v-for="item in tradeArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="通知次数:">
                        <el-select v-model="filter.Notified" placeholder="请选择已通知次数" clearable>
                            <el-option
                                v-for="item in NotifiedArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="状态:">
                        <el-select v-model="filter.ForensicStatus" placeholder="请选择取证状态" clearable>
                            <el-option
                                v-for="item in ForensicStatusArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label="已发送短信模板:">
                        <el-select v-model="filter.NoteTem" placeholder="请选择已发送短信模板" multiple collapse-tags clearable>
                            <el-option
                                v-for="item in NoteTemArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button type='primary' @click='search'>查询</el-button>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            v-loading="tableLoading"
            border
            highlight-current-row
            style="width: 100%"
            v-if="isCrowd == 1"
        >
            <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
            <!-- <el-table-column prop="Province" label="省" align="center"></el-table-column> -->
            <el-table-column prop="City" label="市" align="center"></el-table-column>
            <el-table-column prop="Region" label="区/县" align="center"></el-table-column>
            <el-table-column prop="UnitName" label="单位名称" align="center"></el-table-column>
            <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" align="center"></el-table-column>
            <el-table-column prop="StationName" label="岗位" align="center"></el-table-column>
            <el-table-column prop="TradeName" label="行业" align="center"></el-table-column>
            <el-table-column prop="NoticeCount" label="已通知次数" align="center"></el-table-column>
            <el-table-column prop="NoticeTime" label="最后通知时间" align="center"></el-table-column>
        </el-table>
        <el-table
            :data="tableData"
            v-loading="tableLoading"
            border
            highlight-current-row
            style="width: 100%"
            v-else           
        >
            <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
            <!-- <el-table-column prop="ProvinceID" label="省" align="center"></el-table-column> -->
            <el-table-column prop="CityID" label="市" align="center"></el-table-column>
            <el-table-column prop="RegionID" label="区/县" align="center"></el-table-column>
            <el-table-column prop="UnitName" label="单位名称" align="center"></el-table-column>
            <el-table-column prop="NumberOfCertificates" label="证书数量" align="center"></el-table-column>
            <el-table-column prop="PersonSafety" label="消防安全责任人" align="center"></el-table-column>
            <el-table-column prop="ResponsiblePhone" label="责任人手机号" align="center"></el-table-column>
            <el-table-column prop="FireSafetyManager" label="消防安全管理人" align="center"></el-table-column>
            <el-table-column prop="PhoneAdministrator" label="管理人手机号" align="center"></el-table-column>
            <el-table-column prop="Contacts" label="联系人" align="center"></el-table-column>
            <el-table-column prop="ContactPhoneNumber" label="联系人手机号" align="center"></el-table-column>
            <el-table-column prop="Address" label="地址" align="center"></el-table-column>
            <el-table-column prop="NotifiedTimes" label="已通知次数" align="center"></el-table-column>
            <el-table-column prop="LastNotificationTime" label="最后通知时间" align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'>
        </el-pagination>
    </div>
</template>

<script>
import { getNoticeRecordDetailListPage,getAreaDictionaryList,listForSocialUnit,selectAllSmsTemplateName,getSocialGroupDetailListPageForStaff,getDataDictionaryList } from '@/api/api'
export default {
    data(){
        return {
            buttonList:[],
            filter:{
                name: '',
                province: 1919,
                city: '',
                county: '',
                ForensicStatus: '',
                Notified: "",
                NoteTem: [],
                hasPhone: null,
                parentID: null,
                station: '',
                trade: '',
            },
            provinceArr: [],
            cityArr: [],
            countyArr: [],
            ForensicStatusArr:[{
                label:'未注册',value: 0
            },{
                label:'已注册',value: 1
            },{
                label:'已报考',value: 2
            },{
                label:'已取证',value: 3
            }],
            stationArr: [], //岗位
            tradeArr: [], //行业
            NotifiedArr:[
                {name: "0",value: 0},
                {name: "1",value: 1},
                {name: "2",value: 2},
                {name: "3",value: 3},
                {name: "4",value: 4},
                {name: "5",value: 5},
                {name: "6",value: 6},
                {name: "7",value: 7},
                {name: "8",value: 8},
                {name: "9",value: 9},
                {name: "10",value: 10},
            ],
            NoteTemArr:[{
                label:'模板1',value:0
            },{
                label:'模板2',value:1
            }],
            tableData:[],
            tableLoading: false,
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            id:null,
            isCrowd: '',
            hasSocialUnit: '',
        }
    },
    methods:{
        // 查询
        search(){
            if(this.isCrowd == 1) {
                this.getCrowdData()
            } else {
                this.getData()
            }
        },
        // 返回
        back(){
            this.$router.go(-1)
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.PageSize = value
            if(this.isCrowd == 1) {
                this.getCrowdData()
            } else {
                this.getData()
            }
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            if(this.isCrowd == 1) {
                this.getCrowdData()
            } else {
                this.getData()
            }
        },
        getData(){
            var params = {
                keyWord: this.filter.name,
                provinceID: this.filter.province,
                cityID: this.filter.city,
                regionID: this.filter.county,
                status: this.filter.ForensicStatus,
                noticeCount: this.filter.Notified,
                stationID: this.filter.station,
                tradeID: this.filter.trade,
                pageIndex: this.pages.PageIndex,
                pageSize: this.pages.PageSize,
            }
            this.tableLoading = true
            listForSocialUnit(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.tableData = result.Response.Data
                    this.pages.DataCount = result.Response.DataCount
                }else{
                    return
                }
                this.tableLoading = false
            })
        },
        getCrowdData(){
            var params = {
                keyWord: this.filter.name,
                provinceID: this.filter.province,
                cityID: this.filter.city,
                regionID: this.filter.county,
                status: this.filter.ForensicStatus,
                groupID: this.filter.parentID,
                staffType: this.filter.hasPhone,
                noticeCount: this.filter.Notified,
                stationID: this.filter.station,
                tradeID: this.filter.trade,
                pageIndex: this.pages.PageIndex,
                pageSize: this.pages.PageSize,
            }
            this.tableLoading = true
            getSocialGroupDetailListPageForStaff(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.tableData = result.Response.Data
                    this.pages.DataCount = result.Response.DataCount
                }else{
                    return
                }
                this.tableLoading = false
            })
        },
        GetAreaFun(e,i) {
            if(i == 0) {
                var params = {
                    Level: e,
                }
            } else {
                var params = {
                    parentId: e,
                }
            }
            getAreaDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    if(i == 0) {
                        this.provinceArr = result.Response.reverse()
                    } else if(i == 1) {
                        this.cityArr = result.Response.reverse()
                    } else if(i == 2) {
                        this.countyArr = result.Response.reverse()
                    }
                }
            })
        },
        getSmsTemplateNameFun() {
            selectAllSmsTemplateName().then(res => {
                var result = res.data
                if(result.Success) {
                    this.NoteTemArr = result.Response
                }
            })
        },
        // 获取岗位/行业
        getDataDictionaryList() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationArr.push(item)
                        }
                    });
                }
            })
        },
        // 时间格式化
        dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        }
    },
    created(){
        var hasPhone = this.$route.query.hasPhone
        var parentID = this.$route.query.ID
        this.isCrowd = this.$route.query.isCrowd
        this.hasSocialUnit = this.$route.query.hasSocialUnit
        if(hasPhone == 'null') {
            this.filter.hasPhone = null
        } else {
            this.filter.hasPhone = hasPhone
        }
        if(parentID == 'null') {
            this.filter.parentID = null
        } else {
            this.filter.parentID = parentID
        }
        if(this.isCrowd == 1) {
            this.getCrowdData()
        } else {
            this.getData()
        }
        // this.GetAreaFun(0,0)
        this.GetAreaFun(1919,1)
        this.getDataDictionaryList();
        // this.getSmsTemplateNameFun()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    },
    watch: {
        'filter.province': function(val) {
            this.GetAreaFun(val,1)
            this.filter.city = ''
            this.filter.county = ''
        },
        'filter.city': function(val) {
            this.GetAreaFun(val,2)
            this.filter.county = ''
        }
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>