<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item>
                        <el-select v-model="filters.name" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder='输入持证人' v-model='filters.CertHolderName' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='search'>查询</el-button>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table :data='tableData' border style='width:100%' :highlight-current-row='true' :cell-style="rowClass" :header-cell-style="headClass">
            <el-table-column prop='CertificateName' label='证书名称'></el-table-column>
            <el-table-column prop='CertificateNumber' label='证书编号'></el-table-column>
            <el-table-column prop='CertificateHolderName' label='持证人'></el-table-column>
            <el-table-column prop='CertificateHolderPhoneNumer' label='手机'></el-table-column>
            <el-table-column prop='CertificateRegisterDate' label='登记日期' :formatter="formatCreateTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'>
        </el-pagination>
    </div>
</template>

<script>
import util from "../../../util/date";
import { getRegisterCertificateByEnterpriseIDList,getCertificateInfoListPage } from '@/api/api'
export default {
    data(){
        return {
            buttonList:[],
            // 当前路由参数
            paramsID:null,
            // 筛选数据
            filters:{
                name:'',
                CertHolderName:''
            },
            options:[
                {
                    value:'',
                    label:'选择证书'
                }
            ],
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            tableData:[],
        }
    },
    methods:{
        // 表格表头样式
        headClass () {
            return 'height:20px!important;'
        },
        // 单元格样式
        rowClass () {
            return 'padding:8px 0!important;'
        },
        // 获取路由参数
        getRouteParams(){
            this.paramsID = this.$route.query.id
        },
        // 获取页面数据
        getData(){
            var params = {
                socialUnitID:parseInt(this.paramsID),
                certHolderName:this.filters.CertHolderName,
                certificateDesignID:parseInt(this.filters.name),
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getRegisterCertificateByEnterpriseIDList(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }else{
                    return
                }
            })
        },
        // 获取所有证书名称
        getCertificateName(){
            getCertificateInfoListPage().then(res => {
                var data = res.data.Response.Data
                data.forEach(item => {
                    var obj = {}
                    obj.value = item.ID
                    obj.label = item.CertificateName
                    this.options.push(obj)
                })
            })
        },
        // 返回
        back(){
            this.$router.go(-1)
        },
        // 查询
        search(){
            this.getData()
        },
        // 清空输入内容重新获取数据
        clearContent(){
            this.getData()
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
        return !row.CertificateRegisterDate || row.CertificateRegisterDate == ""
            ? ""
            : util.formatDate.format(new Date(row.CertificateRegisterDate), "yyyy-MM-dd");
        },
    },
    created(){
        this.getRouteParams()
        this.getData()
        this.getCertificateName()
    }
}
</script>

<style scoped>
    /* .pageBar{
        margin-top:15px!important;
    } */
</style>