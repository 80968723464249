var echarts = require('echarts');
export function cityMapFun(myChart,that) {
    var result = {Response: that.GeoJson }
    var mapReponseData = result.Response.features
    if(that.slipupMapArr.length > 0) {
        mapReponseData.forEach(item => {
            if(item.properties.id && item.properties.id == 4419) {
                item.properties.id = 441900
            }
            if(item.properties.unique_id && item.properties.unique_id == 4419) {
                item.properties.unique_id = 441900
            }
            if(item.properties.id && item.properties.id == 4420) {
                item.properties.id = 442000
            }
            if(item.properties.unique_id && item.properties.unique_id == 4420) {
                item.properties.unique_id = 442000
            }
            that.slipupMapArr[0].subData.forEach(o => {
                var newID = item.properties.id ? item.properties.id : item.properties.unique_id
                if(newID == o.AreaCode) {
                    o.oneselfID = newID
                    o.pID = item.properties.pid
                    o.name = item.properties.name
                }
                // if(item.properties.name == o.Name) {
                //     o.oneselfID = newID
                //     o.pID = item.properties.pid
                // }
            })
        });
    }
    var totalData = that.slipupMapArr
    function getSeriesData(name, dataset) {
        if (dataset === undefined) {
            dataset = totalData;
        }
        for (let data of dataset) {
            if (name === data.name) {
                if (data.subData) {
                    return data.subData;
                } else {
                    return [];
                }
            } else {
                if (data.subData) {
                    var subResult = getSeriesData(name, data.subData);
                    if (subResult) {
                        return subResult;
                    }
                }
            }
        }
        return null;
    }
    function loadMap(mapName) {
        var seriesData = getSeriesData(mapName);
        myChart.showLoading();
        echarts.registerMap(mapName, result.Response);
        myChart.hideLoading();
        that.isShowNavBar = 100
        var showCityName = ''
        var showCityData = ''
        //地图具体样式
        var option = {
            backgroundColor: 'rgba(6, 23, 64, 0)',
            tooltip: {
                trigger: 'item',
                borderColor: '#9be8e8',
                borderStyle: "dashed",
                backgroundColor: 'rgb(0,25,60,.9)',
                formatter: function (params) {
                    if(showCityName != params.name) {
                        if(that.slipupMapArr.length > 0 && that.slipupMapArr[0].subData.length) {
                            for(var a = 0; a < that.slipupMapArr[0].subData.length; a++) {
                                if(params.name == that.slipupMapArr[0].subData[a].Name) {
                                    showCityName = that.slipupMapArr[0].subData[a].Name
                                    showCityData = that.slipupMapArr[0].subData[a]
                                    return
                                }
                            }
                        } else {
                            showCityData = {
                                TraineeCount: '-',
                                EnrollmentRate: 0,
                                ParticipationRate: 0,
                                CertificateHoldingRate: 0,
                                UnitsCount: '-',
                                SupervisedCount: '-',
                                
                                TraineesCount: '-',
                                RegistrationPeopleRate: 0,
                                TraineesUnitsCount: '-',
                                UnitParticipationRate: 0,

                                NumberOfTrainees: '-',
                                PersonnelParticipationRate: 0,
                                NumberOfUnitParticipants: '-',
                                UnitParticipationRate: 0,

                                NumberOfPeopleWithCertificates: '-',
                                CurrentNumberOfCertificateHolders: '-',
                                RateToBeSupervised: 0,
                            }
                        }
                    }
                    if(that.buttonActive == 0) {
                        return '<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应训人数：' + showCityData.TraineeCount + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '人员报考率：' + that.redefinitionDecimalFun(showCityData.EnrollmentRate) + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '人员参训率：' + that.redefinitionDecimalFun(showCityData.ParticipationRate) + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '人员持证率：' + that.redefinitionDecimalFun(showCityData.CertificateHoldingRate) + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应训单位数：' + showCityData.UnitsCount + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '待监管单位：' + showCityData.SupervisedCount + '</div>'
                    } else if(that.buttonActive == 1) {
                        return '<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应训人数：' + showCityData.TraineesCount + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '人员报考率：' + that.redefinitionDecimalFun(showCityData.RegistrationPeopleRate) + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应训单位数：' + showCityData.TraineesUnitsCount + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '单位报考率：' + that.redefinitionDecimalFun(showCityData.RegistrationUnitsRate) + '</div>'
                    } else if(that.buttonActive == 2) {
                        return '<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应训人数：' + showCityData.NumberOfTrainees + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '人员参训率：' + that.redefinitionDecimalFun(showCityData.PersonnelParticipationRate) + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应训单位数：' + showCityData.NumberOfUnitsToBeTrained + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '单位参训率：' + that.redefinitionDecimalFun(showCityData.UnitParticipationRate) + '</div>'
                    } else if(that.buttonActive == 3) {
                        return '<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '应持证人数：' + showCityData.NumberOfPeopleWithCertificates + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '当前持证数：' + showCityData.CurrentNumberOfCertificateHolders + '</div>'
                            +'<div style="line-height: 24px;text-align: start;color: #9be8e8">' + '待监管率：' + that.redefinitionDecimalFun(showCityData.RateToBeSupervised) + '</div>'
                    }
                }
            },
            visualMap: {
                min: 0,
                max: 101,
                right: 20,
                bottom: 0,
                showLabel: !0,
                textStyle: {
                    color: '#fff',
                    fontSize: 12
                },
                pieces: [{
                    gte: 70,
                    lt: 2001,
                    label: that.middleMapTitle.mapTitleThree,
                    color: that.mapColor1
                },{
                    gte: 30,
                    lte: 70,
                    label: that.middleMapTitle.mapTitleTwo,
                    color: "#ffdd77"
                }, {
                    gt: -1,
                    lt: 30,
                    label: that.middleMapTitle.mapTitleOne,
                    color: that.mapColor2
                }],
            },
            geo: {
                show: true,
                map: mapName,
                label: {
                    normal: {
                        show: true, //是否显示城市名
                        textStyle: {
                            "color": '#fff'
                        },
                        verticalAlign: 'middle',
                        align: 'left',
                    },
                    "emphasis": {
                        "textStyle": {
                            "color": '#fff'
                        }
                    },
                    "roam": true, //是否允许缩放
                    "mapLocation": {
                        "width": "110%",
                        "height": "97%"
                    },
                },
                zoom: 1.2,
                roam: true,
                itemStyle: {
                    // normal: {
                    //     areaColor: '#FFFFFF',
                    //     borderColor: '#9AA1B7', //地图边框颜色  蓝色
                    //     borderWidth: 1.5,
                    //     //     shadowColor: 'rgba(63, 218, 255, 0.5)',    //地图边框阴影颜色
                    //     //   shadowBlur: 30
                    // },
                    emphasis: {
                        areaColor: 'rgba(147, 235, 248, 0.2)', //鼠标滑过高亮色
                    },
                    borderColor: 'rgba(147, 235, 248, 1)',
                    borderWidth: 1,
                    areaColor: {
                        type: 'radial',
                        x: 0.5,
                        y: 0.5,
                        r: 0.8,
                        colorStops: [{
                            offset: 0,
                            color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: 'rgba(147, 235, 248, .3)' // 100% 处的颜色
                        }],
                        globalCoord: false // 缺省为 false
                    },
                    shadowColor: 'rgba(128, 217, 248, 1)',
                    shadowOffsetX: -2,
                    shadowOffsetY: 2,
                    shadowBlur: 10
                },
            },
            series: [{
                type: 'map',
                map: mapName,
                geoIndex: 0,
                showLegendSymbol: false, // 存在legend时显示
                roam: true,
                animation: false,
                data: seriesData
            }],
        };
        myChart.clear();
        myChart.setOption(option);
        myChart.on('click', function(params) {
            if(params.data) {
                that.isHiddenMap = false
                var cityName = {
                    oneselfName: params.name,
                    oneselfID: params.data.oneselfID
                }
                var paramsObj = ''
                if(params.data) {
                    if(params.data.ProvinceID > 0 || params.data.CityID || params.data.RegionID) {
                        paramsObj = {
                            ProvinceID: params.data.ProvinceID,
                            CityID: params.data.CityID,
                            RegionID: params.data.RegionID,
                            TownID: params.data.TownID,
                        }
                    }
                }
                if(params.data.TownID > 0) {
                    that.baseData.isShowTable1 = true
                    that.baseData.isShowTable2 = false
                }
                setTimeout(() => {
                    if(params.data.TownID > 0) {
                        that.baseData.isShowPie = false
                    }
                    that.isHiddenMap = true
                    localStorage.setItem("nowCityID",JSON.stringify(paramsObj))
                    that.unifiedRequestAPIfun(paramsObj,cityName);
                })
            }
            return
        })
    }
    if(totalData.length > 0) {
        loadMap(totalData[0].name);
    } else {
        loadMap("广东");
    }
}