<template>
    <div style="height: 70%">
        <div class="operationPage">
            <div>
                <video controls preload="auto" autoplay style="background: #000;width: 100%;height: 100%;" src="https://mpv.videocc.net/a21ba01cc4/5/a21ba01cc4ff7b87bb7bf8215b8c91d5_3.mp4"></video>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        changePlayVideo() {
            var videos = document.getElementsByTagName("video");
            for(var i = videos.length - 1;i >= 0; i--) {
                (function(){
                    var p = i;
                    videos[p].addEventListener('play',function (){
                        pauseAll(p);
                    })
                })()
            }
            function pauseAll(index){
                for(var j = videos.length - 1; j >= 0; j--) {
                    if (j!=index) videos[j].pause();
                };
            }
        }
    },
    mounted() {
        this.changePlayVideo()
    }
}
</script>
<style lang="stylus" scoped>
.operationPage {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.pageImage {
    width: 100vw;
    margin-left: -10px!important;
    margin-right: -10px!important;
}
.pageImageCopy {
    width: 70%;
    margin-top: 25px;
    margin-bottom: 15px;
}
.pageTipsBox {
    margin-top: 25px;
    margin-bottom: 15px;
}
.pageTips {
    color: #ff0000;
    font-size: 15px;
}
.pageTipsCopy {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
}
.pageEndTips {
    font-size: 16px;
    font-weight: bold;
    color: #ff0000;
    margin-top: 15px;
}
</style>