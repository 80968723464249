<template>
    <div style="overflow-y: auto;height: 100%;">
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item>
                        <el-input v-model='filter.name' placeholder='名称' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model='filter.phoneNumber' placeholder='号码' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model='filter.socialUnitName' placeholder='单位名称' clearable @clear='clearContent'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="filter.noticeStatus" placeholder="通知结果">
                            <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                        </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' @click='search'>查询</el-button>
                        <el-button type='primary' @click='back'>返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            highlight-current-row
            style="width: 100%"           
            >
            <el-table-column prop="SocialUnitName" label="单位名称"></el-table-column>
            <el-table-column prop="Name" label="名称"></el-table-column>
            <el-table-column prop="PhoneNumber" label="号码"></el-table-column>
            <el-table-column prop="NoticeTime" label="通知时间">
                <template slot-scope='scope'>
                    {{dateFormat('YYYY-mm-dd HH:MM:SS',scope.row.NoticeTime)}}
                </template>
            </el-table-column>
            <el-table-column prop="NoticeStatus" label="通知结果">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.NoticeStatus == 0'>未发送</el-tag>
                    <el-tag v-if='scope.row.NoticeStatus == 1'>成功</el-tag>
                    <el-tag v-if='scope.row.NoticeStatus == 2'>失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="FailReason" label="失败原因">
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'>
        </el-pagination>
    </div>
</template>

<script>
import {getNoticeRecordDetailListPage} from '@/api/api'
export default {
    data(){
        return {
            buttonList:[],
            filter:{
                name:'',
                phoneNumber:'',
                socialUnitName:'',
                noticeStatus:null
            },
            options2:[
                {
                    label:'通知结果',
                    value:''
                },
                {
                    label:'未发送',
                    value:0
                },
                {
                    label:'成功',
                    value:1
                },
                {
                    label:'失败',
                    value:2
                }
            ],
            tableData:[],
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            id:null
        }
    },
    methods:{
        // 查询
        search(){
            this.getData()
        },
        // 返回
        back(){
            this.$router.go(-1)
        },
        // 筛选框清空重新请求数据
        clearContent(){
            this.getData()
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        getData(){
            var params = {
                noticeRecordID:this.$route.query.id,
                phoneNumber:this.filter.phoneNumber,
                socialUnitName:this.filter.socialUnitName,
                noticeStatus:this.filter.noticeStatus,
                name:this.filter.name,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getNoticeRecordDetailListPage(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                }else{
                    return
                }
            })
        },
        // 时间格式化
        dateFormat(format,date){
            let ret = ''
            date = new Date(date)
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        }
    },
    created(){
        this.getData()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>