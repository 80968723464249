<template>
    <div class="wrapper">
        <ul class="bg-bubbles">
            <li v-for="n in 10" :key="n+'n'"></li>
            <ol v-for="m in 5"  :key="m+'m'"></ol>
        </ul>
        <div style="height: 15%;"></div>
        <el-form v-if="pageLoading" v-loading="pageLoading" element-loading-text="登录中" label-position="left" label-width="0px" class="demo-ruleForm login-container">
            <div style="height: 350px;"></div>
        </el-form>
        <el-form v-if="!pageLoading" v-loading="pageLoading" :element-loading-text="pageTitle" label-position="left" label-width="0px" class="demo-ruleForm login-container">
            <div style="height: 350px;line-height: 300px;text-align: center;color: #444;">{{pageTitle}}</div>
        </el-form>
    </div>
</template>
<script>
import { ticketLogin,getUserByToken,getNavigationBar } from '../api/api';
import router from '@/router'
import {filterAsyncRouter} from '@/router/index'
export default {
    data() {
		return {
            pageLoading: true,
            ticket: "",
            clientid: "",
            pageTitle: "当前账号无权限，联系系统管理员分配权限",
        }
    },
    methods: {
        getTicketToken() {
            var _this = this;
            var params = {
                ticket: this.ticket,
                clientid: this.clientid,
            }
            ticketLogin(params).then((res) => {
                var result = res
                if(result.Success) {
                    var token = result.Response.Token;
                    _this.$store.commit("saveToken", token);
                    var curTime = new Date();
                    var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + result.Response.ExpiresIn));
                    _this.$store.commit("saveTokenExpire", expiredate);
                    window.localStorage.refreshtime = expiredate;
                    window.localStorage.expiresIn = result.Response.ExpiresIn;
                    _this.getUserInfoByToken(token)
                } else {
                    _this.pageLoading = false
                    _this.pageTitle = "系统登录失败，稍后重试！"
                }
            })
        },
        // 获取用户数据
        getUserInfoByToken(token) {
            var _this = this;
            var loginParams = {token: token};
            getUserByToken(loginParams).then(data => {
                if (!data.Success) {
                    _this.pageLoading = false
                    _this.pageTitle = "系统登录失败，稍后重试！"
                    _this.$message({
                        message: data.Message,
                        type: 'error'
                    });
                } else {
                    // if(data.Response.IsInitialization){
                    //     router.replace({
                    //         path: "/callBackLogin",
                    //         query: {redirect: router.currentRoute.fullPath,isRemove:"true",}
                    //     });
                    // }else{
                        if(data.Response.IsInit) {
                            window.localStorage.user = JSON.stringify(data.Response)
                            if (data.Response.ID > 0) {
                                _this.GetNavigationBar(data.Response.ID)
                            }
                        } else {
                            _this.pageLoading = false
                            _this.pageTitle = "当前账号无权限，联系系统管理员分配权限"
                            _this.$notify({
                                type: "error",
                                message: "当前账号无权限，联系系统管理员分配权限",
                                duration: 4000
                            });
                        }
                    // }
                }
            });
        },
        // 获取路由树
        GetNavigationBar(userID) {
            var _this = this;
            var loginParams = {userID: userID, t: new Date()};
            getNavigationBar(loginParams).then(data => {
                if (!data.Success) {
                    _this.$message({
                        message: data.Message,
                        type: 'error'
                    });
                    _this.pageLoading = false
                    _this.pageTitle = data.Message
                } else {
                    let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null);
                    _this.$notify({
                        type: "success",
                        message: `登录成功 \n 欢迎：${userinfo.Name} ！Token 将在 ${window.localStorage.expiresIn / 60} 分钟后过期！`,
                        duration: 6000
                    });
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Children/g,"children"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Path/g,"path"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Meta/g,"meta"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Title/g,"title"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/RequireAuth/g,"requireAuth"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/IconCls/g,"iconCls"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Id/g,"id"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Name/g,"name"))
                    window.localStorage.router = (JSON.stringify(data.Response.Children));
                    let getRouter = data.Response.Children//后台拿到路由
                    getRouter = filterAsyncRouter(getRouter) //过滤路由
                    router.$addRoutes(getRouter) //动态添加路由
                    _this.$router.replace(_this.$route.query.redirect ? _this.$route.query.redirect : this.getPresentRouter(getRouter));
                }
            });
        },
        getPresentRouter(getRouter) {
            if(getRouter[0].path == '/') {
                return getRouter[0].path
            } else {
                for(var i = 0; i < getRouter.length; i++) {
                    if(getRouter[i].children.length > 0) {
                        for(var a = 0; a < getRouter[i].children.length; a++) {
                            if(getRouter[i].children[a].path) {
                                return getRouter[i].children[a].path
                            }
                        }
                    }
                }
            }
        },
    },
    created() {
        var ticket = this.$route.query.ticket
        var clientid = this.$route.query.clientId
        this.ticket = ticket
        this.clientid = clientid
        this.getTicketToken();
    },
}
</script>
<style>
.bg {
    margin: 0px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(../assets/loginbck.png) no-repeat top left;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}
.login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: auto;
    width: 350px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    z-index: 9999;
    position: relative;
}
.login-container .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
}
.login-container .remember {
    margin: 0px 0px 25px 0px;
}
.wrapper {
    background: #50a3a2;
    background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
    background: linear-gradient(to bottom right, #127c7b 0, #50a3a2);
    opacity: 0.8;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.wrapper.form-success .containerLogin h1 {
    -webkit-transform: translateY(85px);
    -ms-transform: translateY(85px);
    transform: translateY(85px);
}
.containerLogin {
    max-width: 600px;
    margin: 0 auto;
    padding: 80px 0;
    height: 400px;
    text-align: center;
}
.containerLogin h1 {
    font-size: 40px;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: ease-in-put;
    transition-timing-function: ease-in-put;
    font-weight: 200;
}
.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.bg-bubbles li, .bg-bubbles ol {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -160px;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
}
ol {
    padding: 0 !important;
}
.bg-bubbles ol:nth-child(11) {
    left: 10%;
    top: 10%;
    width: 20px;
    height: 20px;
}
.bg-bubbles ol:nth-child(12) {
    left: 20%;
    top: 40%;

    width: 60px;
    height: 60px;
}
.bg-bubbles ol:nth-child(13) {
    left: 65%;
    top: 30%;

    width: 100px;
    height: 60px;
}
.bg-bubbles ol:nth-child(14) {
    left: 70%;
    top: 30%;
    width: 100px;
    height: 150px;
}
.bg-bubbles ol:nth-child(15) {
    left: 50%;
    top: 70%;

    width: 40px;
    height: 60px;
}
.bg-bubbles li:nth-child(1) {
    left: 10%;
}
.bg-bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 17s;
    animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
    animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
    left: 70%;
}
.bg-bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
    animation-delay: 15s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
    animation-delay: 11s;
}
@-webkit-keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-700px) rotate(600deg);
        transform: translateY(-700px) rotate(600deg);
    }
}
@keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-700px) rotate(600deg);
        transform: translateY(-700px) rotate(600deg);
    }
}
.content-az {
    padding: 0 !important;
    border: none !important;
}
</style>