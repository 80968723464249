<template>
    <div class="wrapper">
        <ul class="bg-bubbles">
            <li v-for="n in 10" :key="n+'n'"></li>
            <ol v-for="m in 5"  :key="m+'m'"></ol>
        </ul>
        <div style="height: 15%;"></div>
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px" class="demo-ruleForm login-container">
            <h3 class="title">系统登录</h3>
            <el-form-item prop="account">
                <el-input type="text" v-model="ruleForm2.account" @keyup.enter.native="enterKey" auto-complete="off" placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
                <el-input v-model="ruleForm2.checkPass" @keyup.enter.native="enterKey" auto-complete="off" show-password placeholder="密码"></el-input>
            </el-form-item>
            <div style="display: flex;">
                <el-form-item prop="QRcode">
                    <el-input v-model="ruleForm2.QRcode" style="width: 175px;" @keyup.enter.native="enterKey" auto-complete="off" placeholder="验证码"></el-input>
                </el-form-item>
                <div v-loading="imageLoading">
                    <img :src="codeImage" @click="refreshCode" style="width: 150px;height: 40px;margin-left:20px;" alt="验证码">
                </div>
            </div>
            <el-checkbox v-model="checked" class="remember">记住密码</el-checkbox>
            <el-form-item style="width:100%;">
                <el-button type="primary" style="width:100%;" @click.native.prevent="handleSubmit2" :loading="logining">
                    {{loginStr}}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {jwttokenCrypto,requestLogin,getUserByToken,getNavigationBar,captchaImage} from '../api/api';
import { JSEncrypt } from 'jsencrypt'
import router from '@/router'
import util from '../../util/date'
import {filterAsyncRouter} from '@/router/index'
import Cookies from 'js-cookie'
export default {
    data() {
        return {
            loginStr: '登录',
            logining: false,
            ruleForm2: {
                account: '',
                checkPass: '',
                QRcode: '',
            },
            codeImage: '',
            Uuid: '',
            rules2: {
                account: [
                    {required: true, message: '请输入账号', trigger: 'blur'},
                ],
                checkPass: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ],
                QRcode: [
                    {required: true, message: '请输入验证码', trigger: 'blur'},
                ]
            },
            checked: false,
            imageLoading: false,
        };
    },
    methods:{
        // 回车
        enterKey() {
            if(!this.logining){
                this.handleSubmit2();
            }
        },
        // 获取 Token
        handleSubmit2(ev) {
            var _this = this;
            this.$refs.ruleForm2.validate((valid) => {
                if (valid) {
                    this.logining = true;
                    var loginParams = {account: this.ruleForm2.account, password: this.ruleForm2.checkPass, code: this.ruleForm2.QRcode, uuid: this.Uuid};
                    _this.loginStr = "登录中...";
                    jwttokenCrypto(this.encryptedData(JSON.stringify(loginParams))).then(data => {
                        var data = data.data
                        if (!data.Success) {
                            _this.$message({
                                message: data.Message,
                                // message: '用户名或密码错误！',
                                type: 'error'
                            });
                            _this.logining = false;
                            _this.loginStr = "重新登录";
                            // _this.closeAlert()
                        } else {
                            var token = data.Response.Token;
                            _this.$store.commit("saveToken", token);
                            var curTime = new Date();
                            var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + data.Response.ExpiresIn));
                            _this.$store.commit("saveTokenExpire", expiredate);
                            window.localStorage.refreshtime = expiredate;
                            window.localStorage.expiresIn = data.Response.ExpiresIn;
                            // _this.closeAlert()
                            // _this.openAlert("成功获取Token，等待服务器返回用户信息...")
                            _this.loginStr = "成功获取Token，等待服务器返回用户信息...";
                            _this.getUserInfoByToken(token)
                        }
                    })
                    .catch(
                        setTimeout(function(){
                            _this.logining = false,
                            _this.loginStr = "重新登录"
                        },5000)
                    )
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取用户数据
        getUserInfoByToken(token) {
            var _this = this;
            var loginParams = {token: token};
            getUserByToken(loginParams).then(data => {
                if (!data.Success) {
                    _this.$message({
                        message: data.Message,
                        type: 'error'
                    });
                } else {
                    // _this.closeAlert()
                    // _this.openAlert("接收到用户数据，开始初始化路由树...")
                    if(data.Response.IsInitialization){
                        router.replace({
                            path: "/callBackLogin",
                            query: {redirect: router.currentRoute.fullPath,isRemove:"true",}
                        });
                    }else{
                        // 记住密码
                        if(this.checked){
                            var strMi = this.ruleForm2.account+'|'+this.ruleForm2.checkPass
                            var MiStr = util.compileStr(strMi)
                            Cookies.set('ASP.pass',MiStr,{expires: 7,});
                        }else{
                            Cookies.remove('ASP.pass');
                        }
                        _this.loginStr = "接收到用户数据，开始初始化路由树...";
                        window.localStorage.user = JSON.stringify(data.Response)
                        if (data.Response.Id > 0) {
                            _this.GetNavigationBar(data.Response.Id)
                        }
                    }
                }
            });
        },
        // 获取路由树
        GetNavigationBar(userID) {
            var _this = this;
            var loginParams = {userId: userID, t: new Date(),type:164};
            getNavigationBar(loginParams).then(data => {
                _this.logining = false;
                if (!data.Success) {
                    _this.$message({
                        message: data.Message,
                        type: 'error'
                    });
                } else {
                    // _this.closeAlert()
                    let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null);
                    _this.$notify({
                        type: "success",
                        message: `登录成功 \n 欢迎：${userinfo.Name} ！Token 将在 ${window.localStorage.expiresIn / 60} 分钟后过期！`,
                        duration: 6000
                    });
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Children/g,"children"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Path/g,"path"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Meta/g,"meta"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Title/g,"title"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/RequireAuth/g,"requireAuth"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/IconCls/g,"iconCls"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Id/g,"id"))
                    data.Response.Children = JSON.parse(JSON.stringify(data.Response.Children).replace(/Name/g,"name"))
                    window.localStorage.router = (JSON.stringify(data.Response.Children));
										
                    let getRouter = data.Response.Children//后台拿到路由
                    getRouter = filterAsyncRouter(getRouter) //过滤路由
                    router.$addRoutes(getRouter) //动态添加路由
                    // console.log("路由",_this.$route.query)
                    _this.$router.replace(window.localStorage.getItem('projectId')?'/':(_this.$route.query.redirect ? _this.$route.query.redirect : this.getPresentRouter(getRouter)));
                    // window.location.reload()
	                console.log(userinfo,'userinfokksjaljdksajskla')
	                localStorage.setItem("projectId", userinfo.ProjectId);
                }
            });
        },
        // 获取图形验证码
        getQRcodeFun() {
            var params = {}
            this.imageLoading = true
            captchaImage(params).then(res => {
                var result = res
                if(result.Success) {
                    this.codeImage = "data:image/jpg;base64," + result.Response.Img
                    this.Uuid = result.Response.Uuid
                    this.imageLoading = false
                }
            })
        },
        // 刷新验证码
        refreshCode() {
            this.getQRcodeFun();
        },
        // 密码加密
        encryptedData (password) {
            let encrypt = new JSEncrypt()
            let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCYihWppVdQSCzZ1YMds4GlTZeJXN2+Zo3rKtIm6vc680BmBerXr/I0UJb+fKy2W+GqEysiDZWPqJOV9P04bKYffZVq/PfBr5CKFbo8KFgZgdI8SsOH69CYKOdmTyWlFJguKcpVsbiWl/2sQh1o2tgXGiRrWAOHwtwR46jEt7s01QIDAQAB"
            encrypt.setPublicKey(publicKey)
            let result = encrypt.encrypt(password)
            return result
        },
        getPresentRouter(getRouter) {
            if(getRouter[0].path == '/') {
                return getRouter[0].path
            } else {
                for(var i = 0; i < getRouter.length; i++) {
                    if(getRouter[i].children.length > 0) {
                        for(var a = 0; a < getRouter[i].children.length; a++) {
                            if(getRouter[i].children[a].path) {
                                return getRouter[i].children[a].path
                            }
                        }
                    }
                }
            }
        },
    },
    created() {
        var ASP_Pass = Cookies.get("ASP.pass");
        var OpenStr = util.uncompileStr(ASP_Pass)
        if(ASP_Pass){
            this.ruleForm2.account = util.getCaption(OpenStr,0)
            this.ruleForm2.checkPass = util.getCaption(OpenStr,1)
            this.checked = true
        }else{
            this.checked = false
        }
        this.getQRcodeFun()
    }
}
</script>
<style>
.bg {
    margin: 0px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(../assets/loginbck.png) no-repeat top left;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}
.login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: auto;
    width: 350px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    z-index: 9999;
    position: relative;
}
.login-container .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
}
.login-container .remember {
    margin: 0px 0px 25px 0px;
}
.wrapper {
    background: #50a3a2;
    background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
    background: linear-gradient(to bottom right, #127c7b 0, #50a3a2);
    opacity: 0.8;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.wrapper.form-success .containerLogin h1 {
    -webkit-transform: translateY(85px);
    -ms-transform: translateY(85px);
    transform: translateY(85px);
}
.containerLogin {
    max-width: 600px;
    margin: 0 auto;
    padding: 80px 0;
    height: 400px;
    text-align: center;
}
.containerLogin h1 {
    font-size: 40px;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: ease-in-put;
    transition-timing-function: ease-in-put;
    font-weight: 200;
}
.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.bg-bubbles li, .bg-bubbles ol {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -160px;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
}
ol {
    padding: 0 !important;
}
.bg-bubbles ol:nth-child(11) {
    left: 10%;
    top: 10%;
    width: 20px;
    height: 20px;
}
.bg-bubbles ol:nth-child(12) {
    left: 20%;
    top: 40%;

    width: 60px;
    height: 60px;
}
.bg-bubbles ol:nth-child(13) {
    left: 65%;
    top: 30%;

    width: 100px;
    height: 60px;
}
.bg-bubbles ol:nth-child(14) {
    left: 70%;
    top: 30%;
    width: 100px;
    height: 150px;
}
.bg-bubbles ol:nth-child(15) {
    left: 50%;
    top: 70%;

    width: 40px;
    height: 60px;
}
.bg-bubbles li:nth-child(1) {
    left: 10%;
}
.bg-bubbles li:nth-child(2) {
    left: 20%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 17s;
    animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
    left: 25%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-duration: 22s;
    animation-duration: 22s;
    background-color: rgba(255, 255, 255, 0.25);
}
.bg-bubbles li:nth-child(5) {
    left: 70%;
}
.bg-bubbles li:nth-child(6) {
    left: 80%;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    background-color: rgba(255, 255, 255, 0.2);
}
.bg-bubbles li:nth-child(7) {
    left: 32%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
    left: 55%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 15s;
    animation-delay: 15s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
    left: 25%;
    width: 10px;
    height: 10px;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    background-color: rgba(255, 255, 255, 0.3);
}
.bg-bubbles li:nth-child(10) {
    left: 90%;
    width: 160px;
    height: 160px;
    -webkit-animation-delay: 11s;
    animation-delay: 11s;
}
@-webkit-keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-700px) rotate(600deg);
        transform: translateY(-700px) rotate(600deg);
    }
}
@keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-700px) rotate(600deg);
        transform: translateY(-700px) rotate(600deg);
    }
}
.content-az {
    padding: 0 !important;
    border: none !important;
}
</style>
