import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import 'font-awesome/css/font-awesome.min.css'

import './promissionRouter'//这里进行路由后台获取的模拟

Vue.use(ElementUI);

// 定义一个全局过滤器
Vue.filter('capitalizeFirstLetter', function(value) {
  if (!value) return '';
  // 将字符串的第一个字符转换为大写
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
