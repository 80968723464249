<template>
    <div class="wrapper">
        <div class="weixin-tip">
            <p>
                <img src="../assets/位图@2x.png" alt="微信打开"/>
            </p>
        </div>
        <div class="box">
            <div class="box_zt">
                <div class="box_yun" id="box_yun">
                    <!-- <a href="http://kaoshi.wanghuolou.cn/wanghuolou.apk"> -->
                    <!-- <a href="http://kaoshi.wanghuolou.cn/wanghuolou.apk"> -->
                        <button class="box_btn_open" id="box_btn_open">打开APP</button>
                        <button class="box_btn" id="box_btn">下载APP</button>
                    <!-- </a> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script src="../util/js/rem.js"></script>
<!-- <script src="../util/js/jquery.min.js"></script>
<script src="../baseUrl.js"></script> -->
<script>
import $ from 'jquery'
import { getNewAPPVersionInfo } from '../api/api';
export default {
    methods: {
        setSizeFun() {
            var b = document.documentElement
            var c = null
            var a = b.getBoundingClientRect().width;
            b.style.fontSize = (100 / 750) * (a >= 750 ? 750 : a) + "px"
            // b.style.fontSize = (100/750) * a + "px";
            window.addEventListener("resize", function() {
                clearTimeout(c);
                c = setTimeout(a, 300)
            });
        }
    },
    created() {
        var winHeight = $(window).height();
        function is_weixin() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        }
        var isWeixin = is_weixin();
        if(isWeixin){
            setTimeout(() => {
                $(".weixin-tip").css("height",winHeight);
                $(".weixin-tip").show();
            })
        }
    },
    mounted() {
        this.setSizeFun();
        $(function() {
            var u = navigator.userAgent;
            var box_btn_open_height = document.getElementById("box_btn_open").offsetHeight
            var box_btn_height = document.getElementById("box_btn").offsetHeight
            if (u.indexOf('iPhone') > -1) {
                $(".box_yun").css("height",box_btn_open_height + box_btn_height + 110 + "px")
            } else {
                $(".box_yun").css("height",box_btn_open_height + box_btn_height + 50 + "px")
            }
            $('.box_btn').click(function() {
                var u = navigator.userAgent;
                if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
                    var ua = navigator.userAgent.toLowerCase();
                    // if(ua.match(/huawei/i) || ua.match(/honor/i)){
                    if(ua.match(/huawei/i)){
                        window.location.href="hiapplink://com.huawei.appmarket?appId=C104226215&channelId=h5&referrer=mobile";
                        return;
                    }
                    if(ua.match(/mi/i) || ua.match(/mix/i) || ua.match(/redmi/i)){
                        window.location.href="market://details?id=com.wanghuolou.exam";
                        return;
                    }
                    if(ua.match(/oppo/i)){
                        window.location.href="oppomarket://details?packagename=com.wanghuolou.exam";
                        return;
                    }
                    if(ua.match(/vivo/i)){
                        window.location.href="vivomarket://details?id=com.wanghuolou.exam";
                        return;
                    }

                    // window.location.href="market://details?id=com.wanghuolou.exam";
                    //安卓手机
                    // window.location.href = baseUrl + "upload/app/juyuan-v2.0.apk"
                    // window.location.href = "http://kaoshi.wanghuolou.cn/wanghuolou.apk"
                    var params =  {
                        appType: 0
                    }
                    getNewAPPVersionInfo(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            window.location.href = result.Response.DownloadUrl
                        }
                    })
                    // window.location.href = "https://gd.119.gov.cn/kaoshi/api/obs/public/package/gd-train.apk"
                } else if (u.indexOf('iPhone') > -1) {
                    //苹果手机
                    //如果是苹果手机，则显示弹框
                    // window.location.href = "https://itunes.apple.com/cn/app/id1479178044?mt=8&at=1l3vntR&ct=qm"
                    window.location.href = "https://apps.apple.com/cn/app/培训考试/id1564627386"
                    // alert("苹果版本暂未开放")
                }
            })
            $('.box_btn_open').click(function() {
                window.location.href = "wanghuolou://", '_system'
            })
        })
    }
}
</script>
<style lang="stylus" scoped>
    html,
    body,
    ul,
    li,
    ol,
    dl,
    dd,
    dt,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    form,
    fieldset,
    legend,
    img {
        margin: 0;
        padding: 0;
        font-family: "PingFangSC-Regular";
    }
    img {
        border: 0;
    }

    .box {
        position: relative;
        top: 0;
        left: 0;
        /* width: 7.5rem; */
        height: 100vh;
        background: url("../assets/background.png") no-repeat;
        background-size: 100% auto  ;
    }
    .box_zt {
        position: absolute;
        top: 0.3rem;
        left: 0;
        right:0;
        /* width: 7.5rem; */
        height: 100vh;
        background: url("../assets/phone.png") no-repeat;
        background-size: 100% auto ;
    }
    .box_yun {
        position: absolute;
        bottom: 0;
        /* padding-bottom: 50px; */
        left: 0;
        width: 100%;
        /* width: 7.5rem; */
        /* height:calc(100vh - 10.5rem); */
        height: 150px;
        background: url("../assets/bg2.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
    }
    .box_btn,.box_btn_open {
        background-color: #C9151E;
        width: 6.8rem;
        height: 1rem;
        font-size: 0.4rem;
        color: #FFFFFF;
        border: 0;
        outline: none;
        border-radius: 1rem;
        /* margin-top:calc((100vh - 10.5rem - 1rem) / 2); */
    }
    .weixin-tip{
        display: none;
        position: fixed; 
        left:0; 
        top:0; 
        bottom:0;
        background: rgba(0,0,0,0.5); 
        // filter:alpha(opacity=80); 
        height: 100%; 
        width: 100%; 
        z-index: 100;
    }
    .weixin-tip p{
        text-align: center; 
        margin-top: 10%; 
        padding:0 5%;
    }
    .weixin-tip img {
        position: absolute;
        width: 4rem;
        height: 2.5rem;
        right: 0.5rem;
        top: 0.1rem;
    }
</style>